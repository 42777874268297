.am-radio-list {
  padding: 0;
  font-size: 13px;
  font-family: Helvetica;
  margin: 0;
  
  .option {
      display: block;
      cursor: pointer;
      padding: 3px 0;
      position: relative;
      
      &.selected {
        color: white;
        .tick {
          display: block;
        }
      }
      
      .tick {
        position: absolute;
        right: 0;
        top: 0; 
        margin-top: 4px; 
        display: none;
      }
   } 
}


#program-settings-overlay {
  #program-settings-modal {
    #program-interests-container {
      height: 351px;
      padding-top: 10px;

      #program-interests {
        position: relative;

        .quick-table-wrap {
          height: 93%;
        }

        .row {
          margin-bottom: 0;

          input {
            margin-top: 0;
            border-color: $cancel-text-color;
          }
        }

        .sensitive-content-box {
          margin-top: 10px;
        }

        .empty-bl {
          font-size: 16px;
          font-weight: bold;
          padding-left: 20px;
          margin-top: 10px;
          display: inline-block;
        }

        .thead .column.scroll-td {
          max-width: 14px;
        }

        .tbody .column.scroll-td {
          max-width: 0;
          padding: 0;
        }

        .column.actions {
          flex: 0.4;
          cursor: default;

          .delete-btn {
            margin-left: 5px;
            padding: 0 5px;
            color: $modal-text-color;

            &:hover {
              color: $highlight-color;
            }
          }
        }

        .column.creationdate {
          flex: 1.8;
        }

        .column.creator {
          flex: 1.5;
        }

        .column.interestname {
          flex: 1.6;
        }

        .edit-btn {
          color: $modal-text-color;
          cursor: default;
          padding: 0 5px;

          &:hover {
            color: $highlight-color;
          }
        }
      }
    }
  }
}
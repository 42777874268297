$animation-time: 0.2s;
$animation-method: cubic-bezier(0.4, 0.5, 0.38, 0.82);

am-container[type=accordion] {
  display: block;
  overflow: hidden;
  margin-bottom: 12px;

  @extend .base_bold_font;

  i {
    transition: transform $animation-time $animation-method;
  }

  .top {
    width: 100%;
    cursor: pointer;
    text-transform: capitalize;
    background-color: #202427;
    line-height: 30px;
    font-size: 14px;

    padding: 0 8px;
    color: #81878c;

    .custom-icon {
      float: left;
      margin-right: 8px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      line-height: 30px !important;
      font-size: .9em;
    }

    .triangle {
      float: right;
    }
  }

  .container {
    padding: 0 6px 0 10px;
    display: none;
  }

  .icon-info {
    display: none;
    margin-left: 8px;
    margin-top: -1px;
    margin-bottom: -2px;
  }

  &.show-info .icon-info {
    display: inline-block
  }

  .summary {
    clear: left;
    padding: 0 6px;
    color: #cacaca;
    font-size: 12px;
    line-height: 1em;
    text-align: left;
    margin-top: .5em;
    opacity: 1;
    text-transform: capitalize;
    font-family: helvetica;

    .default-text {
      display: none;
    }

    &.empty-summary {
      .default-text {
        display: inline;
      }
      .text {
        display: none;
      }
    }
  }

  &.open {
    i.icon-Triangle_Down {
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      transform-origin: center center;
    }
    .container {
      display: block;
    }
    .summary {
      opacity: 0;
    }
  }
}

@import "src/infra/style/base";
@import "src/infra/style/style_guide";
@import "src/common/style/flexbox";
@import "../base_widget";

// Overrides ... //
audience-widget {
  .nvd3 .nv-discretebar .nv-groups text {
    fill: rgba(white, 1);
  }
}

audience-widget {
  .charts-container {
    height: calc(100% - 25px);
    width: 100%;
    padding: 0 10px 15px 10px;
    .insufficient {
       display: inline-block;
       position: absolute;
       top: calc(50% - 5px);
       left: calc(50% - 137px);
    }
  }

  .gender-chart {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: auto;
    margin-right: auto;
    height: 50%;
    margin-bottom: 0;

    svg {
      height: 100%;
    }

    path.slice {
      stroke: #1e2325;
      stroke-width: 3px;
    }

    polyline {
      //opacity: .3;
      stroke: rgb(129, 135, 140);
      stroke-width: 2px;
      fill: none;
    }
  }

  .age-chart {

    rect {
      fill: #81878c;
    }

    height: 50%;

    .nv-axis .tick line {
      display: none;
    }

    /* text of ages */
    .nv-axis {
      fill: white;
      font-family: arial;
      font-size: 14px;
    }

    /* x axis of ages chart */
    .nvd3 .nv-axis line {
      stroke: $graph-axis !important;
    }

    .age-svg {
      padding-bottom: 5px; // because we move the age labels down
    }
  }
}

@import "./common/map-component/countries/base-map-component";
$top-bar-height: 60px;
insights-geo-widget {
  .container {
    height: 100%;
    width: 100%;
    position: relative;

    .top-container {
      position: absolute;
      z-index: 2;
      width: 100%;
      padding: 15px 0 15px 15px;
      height: $top-bar-height;
      background-color: #202427;
      border-bottom: 1px solid #121416;

      .title {
        float: left;
        color:$selected-color;
        font-size: 16px;
        margin-top: 5px;
      }

      .buttons-container {
        display: inline-flex;
        float:right;

        .control-button {
          height: 36px;
          border-radius: 0;
          background-color: #202427;
          border-style: solid;
          border-color: black;
          border-width: 1px 0 1px 1px;
          padding: 5px 10px;
          cursor: pointer;
        }

        .control-button.last {
          margin-right: 15px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-width: 1px 1px 1px 1px;

        }
        .control-button.first {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        .selected-control {
          background-color: $selection-background;
          padding: 5px 10px 5px 10px;
          color:$selected-color;
          @extend .base_bold_font;
        }
      }

      .scale-dropdown {
        width: 150px;
        margin-right: 15px;
      }
    }
  }

  .map-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
  }

  .heat-map-container {
    margin: 10px;
    padding: 10px;
    position: absolute;
    bottom: 0;
    background: $background-color;
    opacity: .8;
    border-radius: 3px;
  }

  .chart-wrapper {
    top: calc(#{$top-bar-height} + 10px);
    height: calc(100% - #{$top-bar-height} - 10px);
    position: relative;
    overflow-y: auto;
  }
}

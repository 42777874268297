@import "./modals/lifestyles-modal";
@import "./modals/demographics-modal";
@import "./modals/interests-modal";
@import "./modals/load-audience/load-audience-modal";
@import "./audience-confirmation";
@import "../../widgets/audience-preview-widget/audience-preview-widget";

$error-color: #de5d64;
$disabled-color: #495055;
$regular-color: #81878c;

.audience-builder {
  $top-button-width: 210px;
  $top-button-space: 30px;

  .audience-builder-wrapper {
    padding: 30px 20px 20px 20px;
    min-width: 970px;
    margin: 0 auto;

    .segments-mgmt {
      @include user-select(none);
      cursor: default;
      width: 930px;
      margin: 0 auto 30px;
      position: relative;

      .errors-container {
        position: absolute;
        bottom: -50%;
        color: $error-color;
        font-size: 13px;
        cursor: default;
      }

      .segment-action {
        color: $regular-color;
        background: none;
        border: none;
        padding: 2px 15px;

        &.right-border {
          border-right: solid 1px #494e52;
        }

        &:disabled {
          color: $disabled-color;
        }

        &:hover:not(:disabled) {
          color: #ffffff;
          cursor: pointer;
        }

        i {
          margin-right: 5px;

          &.icon-new-segment {
            font-size: 11px;
          }
        }
      }

      .icon-asterisk {
        font-size: 7px;
        position: absolute;
        top: 33%;
        color: #cacaca;
      }

      .fade-in {
        visibility: visible;
        transition: visibility 0s step-start 700ms;
      }

      input.segment-name:focus + .icon-asterisk {
        visibility: hidden;
        transition: visibility 0s step-start 0ms;
      }

      input.segment-name {
        background: transparent;
        height: 40px;
        width: 375px;
        padding-left: 10px;
        margin-right: 15px;
        color: #cacaca;
        border: 1px solid $regular-color;

        &:hover, &:focus {
          color: #ffffff;
          border-color: #ffffff;
        }

        &:hover:not(:focus) {
          cursor: pointer;
        }

        &.ng-invalid {
          border: 1px solid $error-color;
        }
      }

      ::-webkit-input-placeholder {
        color: $disabled-color;
      }

      // override autofill css
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px #2c2f38 inset;
        -webkit-text-fill-color: #ffffff;

        &:focus {
          -webkit-box-shadow: 0 0 0 50px #22252b inset;
        }
      }

      .load-audience-wrapper{
        display: inline-block; 
        position: relative;

        .disabled-tooltip{
          position: absolute; 
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
        }
      }
    }

    am-image-toggle-button {
      i {
        display: block !important;
        bottom: 43px;
      }
      span {
        top: 42px;
      }

      .selected {
        z-index: 200;
        pointer-events: none;
      }
    }

    am-empty-screen-message {
      .empty-screen-msg {
        top: calc(40% + 100px);
      }

      .empty-screen-msg-text {
        white-space: pre-line;
        text-align: center;
        font-size: 24px;
        line-height: 34px;
      }
    }

    .advanced-segmentation {
      margin: 35px 0 15px 0;
      height: 20px;

      .advanced-title {
        float: left;
        color: #cacaca;
      }

      .advanced-button {
        float: right;
      }
    }

    .segments {
      clear: both;
      height: calc(100vh - 410px);
      overflow-y: auto;

      .segment {
        position: relative;
        background: #24292B;
        width: 100%;
        height: 40px;
        margin-top: 20px;
        padding: 10px 20px;
        border: 1px solid black;

        &:first-of-type {
          margin-top: 0 !important;
        }

        .segment-type-icon {
          padding-right: 20px;
          color: $audience-highlight-color;
          font-size: 16px;
        }

        .segment-type {
          width: 178px;
          display: inline-block;
          font: 16px "Helvetica Bold";
          color: white;
        }

        .segment-summary {
          color: #cacaca;
          width: calc(100% - 282px);
          display: inline-block;

          &.advanced-segmentation-on {
            width: calc(100% - 500px);
          }

          ii {
            color: $text-color;
          }
        }

        .segment-buttons {
          position: absolute;
          right: 0;
          top: 12px;

          .logic-operand {
            line-height: 18px;
            border: solid 1px black;
            float: left;
            display: block;
            margin-top: -4px;

            .option {
              line-height: 18px;
              padding: 0 10px;
            }
          }

          .segment-button {
            padding: 12px 20px 10px 5px;

            &:hover {
              color: white;
              cursor: pointer;
            }

            &.icon-edit_icon {
              padding: 12px 5px 10px 13px;
              margin-left: 7px;
            }
          }
        }
      }
    }

    .bottom-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #1a1d1f;
      width: 100%;
    }

  }
}

.audience-builder-modal .modal {
  margin-top: 300px !important;
  max-height: calc(100vh - 235px);
  margin-left: calc(50% - 800px / 2 + 30px) !important;

  .control-buttons button.submit-btn:disabled {
    background: $disabled-color !important;
    border-color: $disabled-color !important;
    color: #2a2d33 !important;
  }
}

@media (min-width: 1600px) {
  .audience-builder-wrapper {
    width: 1203px !important;

    am-image-toggle-button {
      zoom: 125%;

      i, span {
        font-size: 14px !important;
      }
    }

    .segments-mgmt {
      width: 100% !important;
    }
  }

  .audience-builder-modal .modal {
    margin-top: 340px !important;
  }
}

@media (max-width: 1400px) {
  .audience-builder-wrapper {
    width: 100% !important;
  }
}

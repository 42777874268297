
.unknown-term {
  background-color: $regular-color;
  stroke: $regular-color;
  fill: $regular-color;
}

vertical-bar-chart {

  .canvas {
    min-width: 50px;
  } // .svg

  .domain {
    stroke: none;
  }

  .tick {
    line {
      stroke: none;
      fill: none;
    }
    text {
      text-anchor: middle !important;
      stroke: none;
      fill: $click-color;
    }
  }

  .vertical-bar-term-label {
    stroke: none;
    alignment-baseline: central;
    pointer-events: none;
    //font-family: helvetica, sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    fill: $click-color;
  }

  .vertical-bar-label {
    stroke: none;
    alignment-baseline: central;
    pointer-events: none;
    //font-family: helvetica, sans-serif;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    fill: $title-color;
  }
}
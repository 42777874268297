@import "../style/base";
@import "../menu/menu";
@import "./timeframe/timeframe";

//timeframe override

$topbar-color: #343841;
$topbar-height: 3em;
$title-color: $highlight-color;

topbar {
  display: block;
  position: absolute;
  top: 0;
  left: $menu-closed-width;
  height: $topbar-height;
  width: calc(100% - #{$menu-closed-width});
  line-height: $topbar-height;
  background-color: $topbar-color;
  border-bottom: 2px solid #010101;

  .toggle-menu {
    float: left;
    background-color: $amobee-blue;
    color: white;
    width: 3em;
    height: 2.9em;
    position: relative;
    cursor: pointer;

    svg-inline {
      width: 1em;
      height: 1em;
      margin: auto;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      g {
        path {
          fill: white;
        }
      }
    }
  }


  .warroom.input-selected {
    font-size: 13px;
    margin-right: 5px;
    line-height:19px;
  }

  #warroom-time-zone {
    background-color: $topbar-color;
    margin-right: 10px;
  }

  #warroom-apply {
    font-size: 13px;
    margin-right: 10px;
    color: #cacaca;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  program-selector {
    position: absolute;
    right: 50%;
    top: 12px;
  }

  .brand-title {
    @extend .base_bold_font;
    float: left;
    color: $title-color;
    font-size: 20px;
    padding-left: 13px;
    text-align: left;
    vertical-align: middle;
  }

  .interaction-container {
    @extend .base_bold_font;
    float: right;
    position: relative;
    height: $topbar-height;
    line-height: $topbar-height;

    .export {
      cursor: pointer;
      float: right;
      margin-right: 6px;
      color: $text-color;

      &.audience {
        margin-right: 16px;
      }

      a, a:active {
        color: $text-color;
      }
    }

    .help {
      float: right;
      margin-right: 10px;
      &:hover {
        .icon-Help {
          color: white;
        }
      }
    }

    #reset-filters {
      font-size: 14px;
      font-family: Halvetica, sans-serif;
    }

    .icon-Export, #reset-filters {
      &:hover {
        color: white;
      }
    }

    timeframe-filter {
      height: inherit;
      word-spacing: 5px;
      display: inline-block;
      vertical-align: middle;
      color: $text-color;

      .chosen {
        color: $highlight-color;
      }
    }

    #toggleFilterMenu {
      height: $topbar-height + 0.3em;
      line-height: $topbar-height + 0.3em;
      display: inline-block;
      position: relative;
      cursor: pointer;
      float: right;
      padding: 0 1em;
      font-size: 14px;
      background-color: $widget-light;
      border-left: 1px solid #151719;

      &.close {
        background-color: transparent;
      }

      i:before {
        font-size: 13px;
        color: #fff;
      }

      i.icon-Circle_on {
        position: absolute;
        top: 8px;
        right: 10px;
        line-height: normal;

        &:before {
          font-size: 6px;
          color: $amobee-blue;
          border: 2px solid $topbar-color;
          border-radius: 6px;
        }
      }
    }
  }
}

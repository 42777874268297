audience-filter {
  am-input[type=checkbox-group] .option {
    margin-right: 2px;
  }

  am-input[type=checkbox-text] {
    span {
      font-family: Helvetica;
    }
    &.selected {
      color: white;
    }
  }

  am-input[type=radio-group] {
    margin-bottom: 7px;
  }

  am-input[type=checkbox-list] ul {
    margin-top: 7px;
    margin-bottom: 21px;
  }

  am-container .triangle{
    float: right;
    margin-right: 2px;
  }

  .ordinal {vertical-align:super; font-size: 10px}
}
am-input[type=checked] {

  cursor: default;

  &:not([disabled]) {
    cursor: pointer;
  }

  .checked, .unchecked, .indeterminate {
    display: none !important;
  }
  &:not([checked]) .unchecked {
    display: unset !important;
  }

  &[checked] .checked {
    display: unset !important;
  }

  &[indeterminate] .indeterminate {
    display: unset !important;
  }

  &.radio-skin{

    .icon-circle-o{color: $click-color;}

    .icon-tick_in_circle{
      color: #{$amobee-blue};
      margin-right:1px;
      &:before{content: "\e91b"}
    }

    &[disabled] *{color: #{$disabled-row}}
  }

}

@mixin display_flex() {
  display: -webkit-box; // iOS 6-, Safari 3.1-6
  display: -moz-box; // Firefox 19-
  display: -ms-flexbox; // IE
  display: -webkit-flex; // All browsers embedding webkit
  display: flex // NEW SPEC; Chrome, Firefox 20+, Opera 12.1 etc
}

@mixin flex_default_layout() {
  @include display_flex();
  -webkit-box-flex: 1; // iOS 6-, Safari 3.1-6
  -moz-box-flex: 1; // Firefox 19-
  -webkit-flex: 1; // All browsers embedding webkit
  -ms-flex: 1; // IE
  flex: 1; // NEW SPEC; Chrome, Firefox 20+, Opera 12.1 etc
  position: relative;
}

@mixin flex_fit_and_fill() {
  @include display_flex();
  @include flex_item_align(stretch);
  flex: 1 1 auto;
  max-height: 100%;
  overflow: hidden;
  align-self: stretch;
  position: relative;
}

// flex-start | flex-end | center | baseline | stretch
@mixin flex_item_align($type) {
  -webkit-box-align: $type; // iOS 6-, Safari 3.1-6
  -moz-box-align: $type; // Firefox 19-
  -ms-flex-align: $type; // IE
  -webkit-align-items: $type; // All browsers embedding webkit
  -moz-align-items: $type; // Firefox 19-
  -ms-align-items: $type; // IE
  align-items: $type; // NEW SPEC; Chrome, Firefox 20+, Opera 12.1 etc
}

@mixin display_inline_flex() {
  display: -webkit-inline-box; // iOS 6-, Safari 3.1-6
  display: -moz-inline-box; // Firefox 19-
  display: -ms-inline-flexbox; // IE
  display: -webkit-inline-flex; // All browsers embedding webkit
  display: inline-flex;
}

// row | row-reverse | column | column-reverse
@mixin flex_direction($type){
  -moz-flex-direction: $type; // Firefox 19-
  -ms-flex-direction: $type; // IE
  -webkit-flex-direction: $type; // All browsers embedding webkit
  flex-direction: $type;
}

@mixin flex_row(){
  -webkit-box-orient: horizontal; // iOS 6-, Safari 3.1-6
  -moz-box-orient: horizontal; // Firefox 19-
  -webkit-box-direction: normal; // iOS 6-, Safari 3.1-6
  -moz-box-direction: normal; // Firefox 19-
  -webkit-flex-direction: row; // All browsers embedding webkit
  -ms-flex-direction: row; // IE
  flex-direction: row; // NEW SPEC; Chrome, Firefox 20+, Opera 12.1 etc
}

@mixin flex_column(){
  -webkit-box-orient: vertical; // iOS 6-, Safari 3.1-6
  -moz-box-orient: vertical; // Firefox 19-
  -webkit-box-direction: normal; // iOS 6-, Safari 3.1-6
  -moz-box-direction: normal; // Firefox 19-
  -webkit-flex-direction: column; // All browsers embedding webkit
  -ms-flex-direction: column; // IE
  flex-direction: column; // NEW SPEC; Chrome, Firefox 20+, Opera 12.1 etc
}

// TODO remove from brand-index-widget.scss
@mixin flex($amount) {
  -moz-flex: $amount; // Firefox 19-
  -webkit-flex: $amount; // All browsers embedding webkit
  -ms-flex: $amount; // IE
  flex: $amount;
}

@mixin flex-flow($type) {
	-webkit-flex-flow: $type; // All browsers embedding webkit
	flex-flow: $type;
}

.a-flex-it {
    display: -webkit-box; // iOS 6-, Safari 3.1-6
    display: -webkit-flex; // All browsers embedding webkit
    display: -ms-flexbox; // IE
    display: flex;
    -moz-box-wrap: nowrap; // Firefox 19-
    -webkit-box-wrap: nowrap; // iOS 6-, Safari 3.1-6
    -webkit-flex-wrap: nowrap; // All browsers embedding webkit
    -ms-flexbox-wrap: nowrap; // IE
    -ms-flex-wrap: nowrap; // IE
    flex-wrap: nowrap;
}

.a-flex-row {
    -moz-box-direction: row; // Firefox 19-
    -webkit-box-direction: row; // iOS 6-, Safari 3.1-6
    -webkit-box-orient: horizontal; // iOS 6-, Safari 3.1-6
    -webkit-flex-direction: row; // All browsers embedding webkit
    -ms-flexbox-direction: row; // IE
    -ms-flex-direction: row; // IE
    flex-direction: row;
}

.a-flex-col {
    -moz-box-direction: column; // Firefox 19-
    -webkit-box-direction: column; // iOS 6-, Safari 3.1-6
    -webkit-box-orient: vertical; // iOS 6-, Safari 3.1-6
    -webkit-flex-direction: column; // All browsers embedding webkit
    -ms-flexbox-direction: column; // IE
    -ms-flex-direction: column; // IE
    flex-direction: column;
}

.a-flex-align-between {
    -webkit-box-align-content: space-between; // iOS 6-, Safari 3.1-6
    -webkit-align-content: space-between; // All browsers embedding webkit
    -ms-flex-align-content: space-between; // IE
    align-content: space-between;
}

.a-flex-align-center {
    -webkit-box-align-content: center; // iOS 6-, Safari 3.1-6
    -webkit-align-content: center; // All browsers embedding webkit
    -ms-flex-align-content: center; // IE
    align-content: center;
}

.a-flex-align-start {
    -webkit-box-align-content: flex-start; // iOS 6-, Safari 3.1-6
    -webkit-align-content: flex-start; // All browsers embedding webkit
    -ms-flex-align-content: flex-start; // IE
    align-content: flex-start;
}

.a-flex-align-item-start {
    -webkit-box-align: flex-start; // iOS 6-, Safari 3.1-6
    -webkit-align-items: flex-start; // All browsers embedding webkit
    -moz-box-align: flex-start; // Firefox 19-
    -ms-flex-align: flex-start; // IE
    align-items: flex-start;
}

.a-flex-align-item-center {
    -webkit-box-align: center; // iOS 6-, Safari 3.1-6
    -webkit-align-items: center; // All browsers embedding webkit
    -moz-box-align: center; // Firefox 19-
    -ms-flex-align: center; // IE
    align-items: center;
}

.a-flex-start-all {
    -webkit-box-pack: justify; // iOS 6-, Safari 3.1-6
    -webkit-justify-content: flex-start; // All browsers embedding webkit
    -ms-flex-pack: justify; // IE
    -moz-box-pack: justify; // Firefox 19-
    justify-content: flex-start;
    -webkit-box-align: flex-start; // iOS 6-, Safari 3.1-6
    -webkit-align-items: flex-start; // All browsers embedding webkit
    -moz-box-align: flex-start; // Firefox 19-
    -ms-flex-align: flex-start; // IE
    align-items: flex-start;
    -webkit-box-align-content: flex-start; // iOS 6-, Safari 3.1-6
    -webkit-align-content: flex-start; // All browsers embedding webkit
    -ms-flex-align-content: flex-start; // IE
    align-content: flex-start;
}

.a-flex-align-item-stretch {
    -webkit-box-align: stretch; // iOS 6-, Safari 3.1-6
    -webkit-align-items: stretch; // All browsers embedding webkit
    -moz-box-align: stretch; // Firefox 19-
    -ms-flex-align: stretch; // IE
    align-items: stretch;
}

.a-flex-justify-between {
    -webkit-box-pack: justify; // iOS 6-, Safari 3.1-6
    -webkit-justify-content: space-between; // All browsers embedding webkit
    -ms-flex-pack: justify; // IE
    -moz-box-pack: justify; // Firefox 19-
    justify-content: space-between;
}

.a-flex-justify-center {
    -webkit-box-pack: justify; // iOS 6-, Safari 3.1-6
    -webkit-justify-content: center; // All browsers embedding webkit
    -ms-flex-pack: justify; // IE
    -moz-box-pack: justify; // Firefox 19-
    justify-content: center;
}

.a-flex-justify-start {
    -webkit-box-pack: justify; // iOS 6-, Safari 3.1-6
    -webkit-justify-content: flex-start; // All browsers embedding webkit
    -ms-flex-pack: justify; // IE
    -moz-box-pack: justify; // Firefox 19-
    justify-content: flex-start;
}

.a-flex-justify-end {
    -webkit-box-pack: justify; // iOS 6-, Safari 3.1-6
    -webkit-justify-content: flex-end; // All browsers embedding webkit
    -ms-flex-pack: justify; // IE
    -moz-box-pack: justify; // Firefox 19-
    justify-content: flex-end;
}

.a-flex-wrap {
    -moz-box-wrap: wrap; // Firefox 19-
    -webkit-box-wrap: wrap; // iOS 6-, Safari 3.1-6
    -webkit-flex-wrap: wrap; // All browsers embedding webkit
    -ms-flexbox-wrap: wrap; // IE
    -ms-flex-wrap: wrap; // IE
    flex-wrap: wrap;
}

.a-flex-item-auto {
    -webkit-box-basis: auto; // iOS 6-, Safari 3.1-6
    -webkit-flex-basis: auto; // All browsers embedding webkit
    -ms-flex-basis: auto; // IE
    flex-basis: auto;
    -webkit-box-flex: 1; // iOS 6-, Safari 3.1-6
    -moz-box-flex: 1; // Firefox 19-
    -webkit-flex: 1; // All browsers embedding webkit
    -ms-flex: 1 0 auto; // IE
    flex: 1;
}

@import "../../common/discoveryViewSwitcher.drv/discoveryViewSwitcher.drv";
@import "../../common/brick.drv/brick.drv";
@import '../../common/style/bordered-button';
@import "../base_widget";

$selection-background: #151719;

.sort-button {
  @include display_flex();
  @include flex_direction(row);
  align-items: flex-start;

  position: absolute;
  right: 1em;
  color: $click-color;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;

  .control-button {
    background-color: #202427;
    border: 1px solid black;
    padding: 5px 10px 6px 10px;
    cursor: pointer;
    width: 75px;
  }

  .control-button:hover {
    color:$selected-color;
  }

  .selected-control {
    background-color: $selection-background;
    padding: 5px 10px 6px 10px;
    cursor: default;
    color:$selected-color;
    @extend .base_bold_font;
  }
}

#new-items-btn {
  @extend .bordered-button;
  margin: auto;
  height: 30px;
  font-size: 14px !important;
  @extend .base_bold_font;
  padding: 6px 12px !important;

  .icon-Refresh {
    margin-left: 12px;
  }
}

#streams-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 60px
}

#streams-results {
  padding: 4px 0 0 10px; // top 4px for the separator
  overflow-y: scroll;
  height:  calc(100% - 60px);
  position: relative;
}

.h-align {
  margin: 0 auto
}

.loading-spinner-small{
  background: url(/images/widgets/small_spinner.gif) center;
  background-repeat: no-repeat;
  height: 32px;
  margin: auto;
}

.loading-spinner{
  background: url(/images/widgets/loader.svg);
  background-repeat: no-repeat;
  width: 10%;
  height: 32px;
  margin: auto;
}

#streams-panel {
  height: calc(100% - 54px)
}

#streams-separator{
  background: url(/images/widgets/streams-widget/stream-seprator.png);
  height: 4px;
  z-index: 10;
  width:  100%;
  position: absolute;
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

am-tabs {

  font: 16px Helvetica, sans-serif;
  $tabs-height: 31px;

  .am-tabs-drv {      
      -webkit-user-select: none;
      text-align: center;
      height: $tabs-height;
      min-height: $tabs-height;
      width:100%;

      tab {
        border: none;
        margin: 0px 3px;
        padding: 0px 10px;
        height: 100%;
        vertical-align: middle;
        color: $click-color;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        line-height: $tabs-height; 

        &:hover {
          color: $selected-color;
        }

        &.selected{
          color: $selected-color;
          background-color: $textbox-bg;
          border: 1px solid black;
          border-top: 2px solid black;
          border-radius: 4px;
          font-family: "Helvetica Bold";
          line-height: 26px;
        }
      }
      
  }
}

$theme-height: 140px;

.theme_container {

  /*SEE WARROOM.JS SETTHEME FUNCTION FOR ACTUAL EXCECUTION:*/
  background-image: linear-gradient(to top,
          rgb(52, 56, 65) 0%,
          rgba(52, 56, 65, 0.53) 47%,
          rgba(52, 56, 65, 0) 100%), url(https://s3.amazonaws.com/bi-warroom-themes/default_banner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  height: #{$theme-height};

  .theme_left_container {
    top: 20px;
    left: 20px;
    position: absolute;
  }

  .theme_logo {
    float: left;
    width: 50px;
    height: 50px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);

    img {
      width: 50px;
      height: 50px;
    }
  }

  .theme_txt {
    float: left;
    font: bold 18px Helvetica;
    color: #ffffff;
    font-family: inherit;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    margin-left: 10px;
    margin-top: 15px;
  }

}

.open-filter-menu .theme_container {
  &.realtime, &.trending {
    width: calc(100% - #{$warroom-filter-width})
  }
}

#edit_theme_dialog, #reset_theme_dialog {
  width: 495px;
  height: 400px;
  margin-left: -277px;
  top: 25%;
  .title {
    border-bottom: 0px;
    margin-bottom: -5px;
  }

  label {
    padding-top: 20px;
  }

  .reset-brand {
    color: #81878c !important;
    line-height: 30px;
    display: inline-block;

    .icon-Trash {
      margin-top: -3px;
    }

    span {
      cursor: pointer;
      padding-right: 3px;
    }
  }

  .placeholder {
    color: #81878c !important;
  }

  pre {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 14px;
  }

}

#reset_theme_dialog {
  height: 160px;
}

#input_logo_image, #input_cover_image {
  padding-left: 30px;
}

.control_buttons {
  padding-right: 3px;
}

input-bar {
  background: transparent;
  outline: 1px solid #545a66;
  border: 0px;

  &.input-mode {
    background-color: $modal-background;
    outline-color: #fff;
  }

  textarea {
    height: 140px !important;
    background-color: transparent !important;
    border: 0px;
  }

  .demi-icon-pipe {
    display: none
  }

}

.attachments {
  position: relative;
  top: -51px;
  height: 0px;
}

.file-upload-container {

  .file-upload-input {
    visibility: hidden;
    width: 442px;
    cursor: pointer;
  }

  svg-inline.add svg, svg-inline.remove svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-top: 3px;
    display: inline-block;
    fill: #81878c;
    vertical-align: bottom;
    width: 16px;
    heigth: 16px;
  }

}

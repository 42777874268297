@import "../../widgets/audience-interests-widget/audience-interests-widget";
@import "../../widgets/audience-demographics-widget/audience-demographics-widget";
@import "../../widgets/audience-tv-widget/audience-tv-widget";
@import "../../widgets/user-history-widget/user-history-widget";
@import "../../widgets/user-examples-dropdown-widget/user-examples-dropdown-widget";
@import "../../widgets/audience-table-view-widget/audience-table-view-widget";
@import "../../common/am-tabs.drv/am-tabs.drv";

.audience-explorer{
    height: 100%;
    overflow-y: hidden;
    $tabs-vertical-padding: 18px;
    $tabs-height: 42px;

    am-tabs {
        display: block;
        padding-top: $tabs-vertical-padding;
    }

    user-examples-dropdown-widget {
        position: fixed;
        right: 34px;
        width: 250px;
        z-index: 1;
        top: 64px;
    }

    .back-to-builder {
        position: absolute;
        top: 26px;
        left: 30px;
        color: $click-color;
        cursor: pointer;

        a:hover {
            color: white;
        }

        .icon-Arrow {
            padding-right: 5px;
            font-size: 10px
        }
    }

    .left-div {
        float: left;
        width: 100%;
        height: 100%;
       // padding: 0 15px;
        min-width: 963px;
    }
    .right-div {
        float: left;
    }

    .tab-content {
        height: calc(100% - 95px);
        position: relative; // for the spinner
        margin: 10px 20px;
    }
}

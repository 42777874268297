[sly-horizontal],
[sly-vertical] {
    position: relative;
}

.scrollbar {
    position: absolute;
    line-height: 0;
}

[sly-vertical] {
    &+.scrollbar,
    .scrollbar {
        right: 0;
        top: 0;
        bottom: 0;
        width: 5px;

        .handle {
            width: 100%;
        }
    }
}

.scrollbar .handle {
    background: rgba(66, 66, 66, 0.95);
    cursor: pointer;
    border-radius: 2px;
}

[sly-horizontal="bottom"] {
    & > :first-child {
        width: 10000px;
        /*for help element not breaking to new line*/
    }

    &+.scrollbar,
    .scrollbar {
        left: 0;
        right: 0;
        bottom: 0;
        height: 5px;

        .handle {
            height: 100%;
        }
    }
}

.sly-nextPage,
.sly-prevPage {
    position: absolute;
    background-color: #151719;
    width: 26px;
    height: 32px;
    line-height: 32px;
    bottom: 30px;
    cursor: pointer;
    color: whitesmoke;
    font-size: 20px;
    text-align: center;
    border: none;
    z-index: 1;
}

.sly-nextPage {
    right: 0;
}

.sly-prevPage {
    left: 0;
}

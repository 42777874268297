@import "src/infra/style/base";
@import "src/infra/style/style_guide";

rt-associations-widget {
	height:100%;
	width: 100%;
	display: block;
	position: relative;

	.items {
		padding: 5px 15px 0px 15px;
		display:block;
		height: calc(100% - 25px);

		.item {
			position: relative;
			height: calc(18%);
			border-bottom: 1px solid $border-color;

			&:last-child {
				border-bottom: none;
			}
		}

		.text {
			position:absolute;
			top: calc(50% - 8px);
			font-size: 14px;
			color: $regular-color;
			text-transform: capitalize;
		}
		.score {
			position:absolute;
			top: calc(50% - 8px);
			left: calc(100% - 80px);
			font-size: 15px;
			//font-weight: 700;
		}

		.positive {
			color: #4cd43e;
		}
		.negative {
			color: #ea2626;
		}

	}
}

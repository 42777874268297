@import "infra/style/style_guide";
@import "infra/style/base_modal";
@import "dashboard-interests";
@import "program-interests";
@import "program-sources";

#program-settings-overlay {
  #program-settings-modal {
    height: 550px;
    width: 680px;
    margin-top: 10vh;

    .navigation {
      display: flex;
      justify-content: center;
      font-size: 16px;
      padding-bottom: 5px;
      line-height: 31px;
      margin-bottom: 10px;

      .selected {
        @extend .base_bold_font;
        color: $highlight-color;
        background-color: $input-focus-bg-color;
        border: 1px solid #000;
        border-radius: 4px;
        outline: 0;
        cursor: default;
      }

      nav-button {
        padding: 0 10px;
        margin: 0 5px;
        cursor: pointer;
        -webkit-user-select: none;
        z-index: 10;
        &:hover {
          color: $highlight-color;
        }
      }

      &.hidden {
        visibility: hidden;
      }
    }

    .control-buttons {
      .main-button.ng-hide {
        display: none !important;
      }

      .sensitive-content-box {
        .checked, .unchecked {
          font-size: 12px;
        }
      }
    }
  }
}

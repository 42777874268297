@import "infra/style/base_modal";
@import "common/radiogroup-slider/radiogroup-slider";

.interests-modal, .websites-modal {

  &.websites-modal .scroll-container {
    height: 280px;
  }

  &.interests-modal {
    .scroll-container {
      height: 335px;
    }
  }


  .modal {
    width: 800px;

    .title {
      font-size: 16px !important;
    }

    .input-bar-title {
      margin-bottom: 14px;
      font-size: 14px;
    }
    .title-and-search {
      margin-bottom: 20px;
    }

    .scroll-container {
      overflow-y: auto;
      padding: 0 1px;
      max-height: calc(100vh - 460px);
    }

    .search-div {
      position: absolute;
      width: 746px;
      margin-top: 30px;
    }

    input-bar.tags-mode {
      label {
        overflow: auto;
      }
    }

    input-bar {
      ::-webkit-scrollbar-thumb {
        height: 42px;
      }

      results {
        border-bottom: 1px solid white;
      }

      .resize-corner {
      cursor: s-resize!important;
    }

      padding: 10px 0 0;
      min-height: 42px;
      height: 42px;
      background-color: $modal-background;
      outline-color: $separator-color;

      .input.out-of-view {
        font-size: 14px;
      }

      label {
        padding-top: 11px!important;
        padding-left: 2px!important;
      }

      .input-bar-right-buttons, .icon-Search, .demi-icon-pipe {
        display: none;
      }

      &.input-mode {
        background-color: $input-focus-bg-color;
        border-color: #ffffff;
        outline: none;
      }

      tag {
        margin-bottom: 10px;

        .text {
          font-size: 13px;
        }
      }

      .tags-input {
        padding-left: 10px;
      }

      label {
        padding: 10px 0 0;
      }

      &.ng-invalid {
        border: 1px solid $invalid-color !important;
      }

      &:focus {
        background-color: $input-focus-bg-color;
        border-color: $modal-border;
      }
    }

    .control-buttons {
      border-top: 0 !important;
    }

    .level-of-intent {
      padding-top: 12px;
      position: relative;
      top: 3px;

      .level-of-intent-title {
        float: left;
        margin-right: 30px;
        font-size: 14px;
      }
        radiogroup-slider {
          margin-top: -4px;
          float: left;
          width: 400px;
        }
      }
  }
}

$odd-color: #1B1F21;
$even-color: $tabs-background;
$header-height:36px;

.quick-table-wrap{
	width:97%;height:86%;
	margin:auto;
}

.quick-table{

	position:relative;
	width:100%;height:100%;
	border:1px solid #08090A;
	font-size:14px;

	&:not(.fixed-size) .tbody .column{height:27px;}

	.row{
		display:flex;
		.column{
			flex:1;
			padding:7px;
		    white-space: nowrap;
		    text-overflow: ellipsis;
		    overflow:hidden;
		    color: $regular-color;
		    border:1px solid #08090A;border-width: 0px 0px 0px 1px;

		    &:first-child{border-left-width: 0px; border-left-color: transparent}
		}

		&:nth-child(even){background-color:$even-color;}
		&:nth-child(odd){background-color:$odd-color;}

	}

	.thead{
		&.row{background:$main-btn-color-hover;}
		.column{
			color:$click-color;border-bottom-width: 2px;
			text-transform:capitalize;font-family:helvetica;cursor:pointer;
			line-height:20px;
			font-family:helvetica bold;
			padding-top: 8px;
    		padding-bottom: 4px;

			&.selected{color:$click-color;font-weight:bold;}
			&.scroll-td{width:45px;max-width:45px;padding-left:0px;padding-right:0px;
						border-left-color:transparent;text-align:center;cursor:auto}
		
			i.sort{margin-left:5px;}
		}

	}

	.tbody{
		height: calc(100% - #{$header-height});
		overflow:scroll;overflow-x:hidden;background:$odd-color;
		.column{
			&.scroll-td{width:32px;max-width:32px;border-left-color:transparent;}
		}

		&.loading{opacity:0.5;}

		.row:hover{background:$table-content-hover;}
		.row.disabled *{color: #{$disabled-row} !important}

		.row.open{
	    	background: #16181a;
	    	border-top: 1px solid #000;
	    }
	}

	.no-data{background-image:none !important;font-size:16px;width:auto;}

	.search{
		input{
			width: calc(100% - 114px);
          	height:19px;
			outline:none;
          	border-width:0px 0px 1px 0px !important;
		    background-color: transparent !important;
		    border-color: $click-color;
	    }
	    i{font-size:16px; margin-left:3px; margin-right: 5px;}
	    .icon-X_CLEAR{font-size:8px;margin-right:2px;}

	    &.selected, &.selected *{color:$selected-color}
	}

	.crud{
		flex:0.2 !important; min-width:72px; text-align:right;
		padding-right:0px;margin-right:-10px;
		i{font-size:16px;color: $click-color}
	}

	/*icons*/
	.thead{
		i{color:$click-color;
			&:hover{color:$selected-color;}
		}
		.excel{
			display:inline-block;
			a{padding:0px;}
		}
		.icon-Export{font-size:16px;margin-top:-4px;}
		.icon-maximize{font-size:11px;margin-left:5px;margin-right:5px}
		.icon-minimize{font-size:13px;margin-left:3px;margin-right:5px}
		.icon-edit_icon{font-size:12px; margin-right: 2px;color:$click-color;}
	}

	am-input[type='checked']{
    	user-select: none;-webkit-user-select: none;
  	}
	
	/*flex-widths. not dynamic, for performance*/
	.flex0-3{flex:0.3 !important}
	.flex0-4{flex:0.4 !important}
	.flex0-5{flex:0.5 !important}
	.flex0-7{flex:0.7 !important}
	.flex0-8{flex:0.8 !important}
	.flex1{flex:1 !important}
	.flex1-2{flex:1.2 !important}
	.flex1-5{flex:1.5 !important}
	.flex2{flex:2 !important}
	.flex3{flex:3 !important}
}



/*table options dropdown - can also be used without table*/

.table-options{
	position:absolute;right:-11px;top:36px;
	z-index:9;
	text-align:left;
	font:13px helvetica;
	color: $regular-color;

	padding:8px;
	border: 1px solid $regular-color;
    border-bottom: 3px solid $regular-color;
    border-radius: 5px;
    border-top-right-radius:0px;
    background-color: #282D33;

    @media (max-height:800px) {
		max-height:230px;
		overflow:auto;
	}


	&:after, &:before {
		bottom: 100%;
		right: 10px;
		border: solid transparent;
		content: " ";
		height:0px;
		width:0px;
		position: absolute;
		pointer-events: none;
	}

	&:before {
		border-color: transparent;
    	border-bottom-color: $regular-color;
    	border-width: 12px;
    	right: 8px;
	}

	&:after {
		border-color: transparent;
		border-bottom-color: #282D33;
		border-width: 10px;
	}

	.table-options-link{
		margin:8px;
		display:block;
		i{margin-right:6px;vertical-align:top;}
	}

   .table-options-col{
	   	padding: 0 .5em 0 .5em;
	    border-bottom: 1px solid #3A3D42;
	    color: #81878C;
	    cursor:pointer;
	    font-family: "Helvetica Bold";
	    white-space: nowrap;
	    line-height: 40px;
	    &.selected{
	    	color:$selected-color;
	    	i{opacity:1; color:$selected-color !important;}
	    }
	    &:hover:not(.selected){
	    	color: $regular-color;
	    }
    	&:last-child{border-bottom-width:0px}
    	i{float:right;opacity:0;margin-left:20px;}
   	}
}


/*different tables*/

quick-table.checkboxes{
	.unchecked{color: #{$click-color}}
    .checked{color: #{$selected-color}}
    .icon-tick_in_circle{color: #{$amobee-blue};}
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";
@import "../../common/tooltip/tooltip";

  .donut-chart-tooltip-percents {
    font-family: "Helvetica Bold"
  }

donut-chart {
  height: 100%;

  .donut-chart-drv {
    height: 100%;
    position: relative;

    svg {
      position: absolute;
      height: 100%;
      width: 100%;
    }

  }
}

@import "infra/style/base_modal";

#clone-program-overlay {
  #clone-program-modal {
    width: 300px;

    #clone-program {
      margin-bottom: 20px;

      input {
        width: 100%;
      }
    }
  }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";


am-autocomplete{
   $border-separator-color: #545a66;

   input {
     background: transparent;
     line-height: 27px!important;
     border: 1px solid $border-separator-color;
     width: 247px;
     padding: 0px 20px 0px 0.5em;
     text-overflow: ellipsis;
   }

   .input-disabled {
     .autocomplete-input {
       border-color: $disabled !important;
     }
     .autocomplete-input[disabled]::-webkit-input-placeholder {
       color: $disabled;
     }
     .icon-Search, .icon-X {
       color: $disabled;
     }
   }

   .error input{
      border: 1px solid $textbox-text-error!important;
   }

   input.error{
      border: 1px solid $textbox-text-error!important;
   }

.autocomplete-input-wrapper {
      position: relative;
      display: inline-block;

     .icon-Search, .icon-X{
       top: 30%;
       right: 10px;
       position: absolute;
       cursor: pointer;
     }
   }


   .autocomplete-err-msg{
      color: $textbox-text-error;
      @extend .base_bold_font;
    }

    .input-disable {
      .autocomplete-input {
         border-color: $disabled !important;
       }
       .autocomplete-input[disabled]::-webkit-input-placeholder {
         color: $disabled;
       }
       .icon-Search, .icon-X {
         color: $disabled;
       }
    }

   .autocomplete-options-container {
      position: relative;
      color: $gray-font-color;
      width: 247px;


        .autocomplete-options-list {
          list-style-type: none;
          margin: 0;
          padding: 0;
          width: 100%;
          position: relative;


          .autocomplete-option {

               span {

               }

               .markLetters{

               }
          }
        }


    }



    .autocomplete-options-dropdown {
      position: absolute;
      cursor: pointer;
      z-index: 1001;

      line-height: 40px;
      border: 1px solid #fff;
      background: #1c2023;
      padding: 0px 10px;
      overflow-y: auto;
      overflow-x: hidden;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }



    .autocomplete-option {
      padding: 4px 10px;
      line-height: 22px;
      overflow: hidden;
      font: normal normal normal 13.3333330154419px/normal Arial;
    }

    .autocomplete-option:hover {

    }



    .autocomplete-option.selected {
      border-radius: 4px;
      border: 0px;
      background-color: #161719;
      color: #fff!important;
    }

}

@import "infra/style/base_modal";

#contact-us-overlay {
  #contact-us-modal {
    width: 480px;
    margin-top: 10vh;

    #contact-us {

      input, textarea {
        width: 100%;
      }

      .msg-area {
        outline: 0;
        padding: 8px 5px;
        resize: none;
      }

      .row.message {
        margin-bottom: 0
      }

      .row.attachments {
        height: 100%;
      }

      .file-upload-container {
        position: relative;
        background-color: transparent;

        label {
          display: inline-block;
        }

        .add-attachments-label {
          position: absolute;
          z-index: 1;

          svg-inline.add svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            display: inline-block;
            fill: #cacaca;
            vertical-align: bottom;
          }

          &:hover {
            color: $highlight-color;
            fill: $highlight-color;
          }
        }

        .file-upload-label {
          cursor: pointer;

          .file-upload-img {
            padding: 5px 8px;
          }

          .file-upload-input {
            display: none;
          }
        }
      }

      ul {
        width: calc(100% - 32px);
        height: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        margin: 10px 0 0 -5px;

        &.with-attachments {
          padding-top: 22px;
        }

        li {
          padding: 0 7px 5px 0;
          float: left;
          margin-top: 3px;

          svg-inline.remove svg {
            height: 10px;
            width: 10px;
            display: inline-block;
            fill: #cacaca;
            cursor: pointer;
            margin-bottom: -1px;

            &:hover {
              fill: #fff;
            }
          }

          &:hover {
            background-color: transparent;
          }

          span {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

audience-preview-widget {
  display: block;
  position: relative;
  color: #cacaca;
  font-size: 16px;
  width: 100%;
  background: rgba(36, 36, 39, 0.4);
  height: 87px;
  line-height: 87px;
  padding: 0 30px;
  overflow-y: hidden;
  min-width: 970px;

  .loader-overlay {
    display: none;
  }

  .loader {
    background: url(/images/widgets/small_spinner.gif) center;
    background-repeat: no-repeat;
    margin: 0;
    left: 430px;
    top: 32px;
    padding-bottom: 20px;
  }

  .summary {
    font-family: "Helvetica Bold";

    .highlighted {
      color: white;
    }

    i {
      line-height: 16px;
      font-size: 18px;
      padding: 0 5px 2px 0;
    }

    .icon-Female {
      padding-left: 15px;
    }

    .audience-summary-title {
      padding-left: 5px;
    }

    .age-chart {
      position: absolute;
      bottom: 10px;
      padding-left: 30px;
      height: 80px;
      width: 336px;
    }

    .icon-male {
      padding-left: 25px;
    }

    separator.women-to-age-chart {
      padding-left: 25px;
    }

    .in-geo-value {
      padding-left: 45px;
      padding-right: 3px;
    }

    .in-geo-title {
      font-family: "Helvetica";
      font-size: 14px;
      padding-right: 19px
    }

    .error-message {
      color: #DE5D64;

      .icon-exclamation_in_circle {
        margin-right: 5px;
      }
    }

    separator {
      height: 100%;
      border-right: 1px solid #4d5255;
    }

    @media (max-width: 1599px) {

      separator.women-to-age-chart {
        padding-left: 10px;
      }

      .icon-male {
        padding-left: 15px;
      }

      .icon-Female {
        padding-left: 5px;
      }

      .in-geo-value {
        padding-left: 5px;
      }

      .audience-summary-title {
        padding-left: 0px;
        margin-left: -10px;
      }

      .age-chart {
        padding-left: 15px;
      }

      .in-geo-title {
        padding-right: 10px;
      }

      .error-message {
        font-size: 15px;
      }

    }

  }

  .explore-button-container {
    $item-height: 42px;

    position: absolute;
    top: calc(50% - #{$item-height} / 2);
    right: 30px;
    line-height: 16px;
    padding: 3px 12px;

    .explore-segment-with-affinity-container {
      button, input {
        font-size: 14px;
        padding: 4px;
      }
      input {
        width: 60px;
        border: 1px solid #ccc;
        border-radius: 2px;
        background: inherit;
      }
      button {
        color: #eee;
        background: #0E88B1;
        border-color: #0E88B1;
        border-radius: 4px;
      }
    }

    button, input {
      height: $item-height;
      line-height: 16px;
      font-size: 16px;

      &:disabled {
        background: #495055;
        color: #2a2d33;
        border: solid #2a2d33;
      }
    }
  }
}
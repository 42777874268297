@import "infra/style/emojis";
@import "./modal/modalEditTarget";
@import "../targets/targets-modal";
@import "../../common/examples-popup/sentiments-popup";


.discovery-grid-view {
  padding-left: 1em;

  am-discovery-view-switcher {
    margin-top: 16px;
  }

  .lifestyles-dropdown-container{
    border-left: none;
    position: absolute;
    top: 75px;
    right: 355px;
    width: 262px;
    
    select-title{width:231px;}
    summary{max-width:214px !important}
    .lifestyles-dropdown{width:300px}
  }

  .main-content-page {
    display: block;
    position: absolute;
    bottom: 4em;
    top: 112px;
    left: 0;
    right: 0;
    padding-left: 1em;

    &.content-drivers-open .quick-table-wrap {
      width:calc(100% - #{$content-driven} - 10px)}
  }

  .quick-table-wrap {
    width: calc(100% - 39px);
    height: 100%;
    margin: inherit;
    
    input[type=search] {
      width: 8vw;
      max-width: 150px;
    }

    .phrase-select{width:calc(100% - 25px);}

    .row .column:first-child{
      cursor:pointer;

      .verified-icon , .retweeter-icon{margin-left: 0.3em;}
      
      .button-open-driver {visibility: hidden;}  
      &:hover , .focus{
        .button-open-driver{visibility: visible;} 
      }
    }
  }
  
  #key-trends-button {
    margin-top: 17px;
    margin-right: 38px;
  }

  .unchecked{color:$click-color;}


  .e-desc {
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
  }

 
  .bottom-bar {
    position: absolute;
    bottom: 13px;
    width: calc(100% - 22px);
    text-align:right;

    .grid-buttons, .summary{margin-top:6px;}

    .summary{
      float:left;
      color: $title-color;font-weight:bold;
    }

    .alert-link i{font-size:18px}

    .delimiter{margin-top:-1px;font-size:16px;}

  }
}

.sentiments-bar {
  background-color: #111416;
  height: 10px;
  position: relative;
  width: 60%;
  display: inline-block;
  .separator {
    height: 14px;
    position: absolute;
    width: 1px;
    top: -2px;
    left: 50%;
    background-color: #81878c;
  }
  .sentiments {
    height: 100%;
    min-width: 3px !important;
    position: absolute;
  }
  .sentiments.positive {
    background-color: #54D08B;
    left: 50%;
    top: 0%;
  }
  .sentiments.negative {
    background-color: #D15A5A;
    right: 50%;
    top: 0%;
  }
}

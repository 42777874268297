@import "../../infra/style/base";
@import "../../infra/style/style_guide";

.tags-viewer-drv {
  
  $space-between-tags: 9px;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: white;
  margin-left: -$space-between-tags / 2;
  margin-right: -$space-between-tags / 2;
  
  tag {

    display: inline-block;
    margin-right: $space-between-tags / 2;
    margin-left: $space-between-tags / 2;
    margin-bottom: 4px;
    max-width: 100%;
    white-space: nowrap;
    cursor: pointer;
    
    &:hover {
      .plus {
        background: white;
        color: #2c3038;
      }
    }

    .text {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      max-width: calc(100% - 18px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left;
    }

    .box {
      display: inline-block;
      border: 1px solid white;
      padding: 1px 5px;
      line-height: 14px;
      margin-bottom: 5px;
    }

    .plus {
      border-left: 0;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      font-family: Arial;
    }

  }

}

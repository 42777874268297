@import "../../infra/style/base";
@import "../../infra/style/style_guide";
@import "../../common/am-gauge-bar.drv/am-gauge-bar.drv";

segment-size {

  display: block;
  height: 100%;

  .segment-size-drv {

    position: relative;
    height: 100%;
    width: 100%;

    .data { 
      height: 100%;
      width: 100%;
      color: $regular-color;

      .segment-size {
        .title {
          font-size: 14px;
          text-align: center;
        }

        .value {
          font-size: 50px;
          font-family: "Helvetica Bold", Helvetica, sans-serif;
          font-weight: bold;
          text-align: center;
          color: white;
        }

        position: absolute;
        top: calc(50% - 18px);
        left: 50%;
        transform: translate(-50%, -50%); 
        font-size: 70px;
      }

      .gauge {

        .center-container {
          height: 100%;
          display: flex;
          justify-content: center;
        }

        position: absolute;
        top: calc(50% + 40px);
        height: 65px;
        width: 100%;
        
        am-gauge-bar {
          float: left;
          width: calc(100% - 90px);
          max-width: 360px;
          min-width: 182px;
        }

        .label {
          float: left;
          line-height: 43px;

          &.left {
            padding-right: 5px;
          }

          &.right {
            padding-left: 5px;
          }
        }
      }
    }
  }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";
@import "../../common/topic-selector.drv/topic-selector.drv";

topic-selector-widget {

    .loader {
        position: relative;
        padding-bottom: 159px;
        display: block;
        margin-bottom: -23px;
    }

    .loader-overlay {
        height: 239px;
        display: initial;
    }

    topic-selector {
      display: block;
    }

}

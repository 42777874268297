@import "../../common/tooltip/tooltip";
@import "../../infra/style/style_guide";

#map-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px; // because the map is drawn with 4 pixels space at the bottom
}

.most-consumed {
  color: $regular-color;
  width: calc(100% - 20px);
  display: table;
  margin-left: 10px;
}

.most-consumed-title {
  display: table-cell;
  vertical-align: bottom;
  text-align: center;
  height: 35px;
  width: 100%;
  font-size: 14px;
}


#widget-container {
  height: calc(100% - 70px);
//  width: calc(100% - 40px);;
  padding-left: 20px;
  padding-right: 20px;
}

#table-container {
  width: 100%;
  height: 100%;
}


.most-consumed {
  position: absolute;
  bottom: 10px;
}

#widget-container {
  padding-top: 0;
  padding-bottom: 0;
}

.geo-tootlip {
  @extend .common-tooltip;
  border: 1px solid #4f5961;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}

@import "../map-component";

.base-map-container {
  width: 100%;
  height: 100%;

  am-map-component {
    width: 100%;
    height: 100%;
  }
}

am-consumption-legend {

  rect.heat-map {
    transition: fill 0.5s ease;
  }

  text {
    font-family: Helvetica , Arial, sans-serif;
    font-weight: normal;
    fill: #cacaca;
  }
}

@import "infra/style/base_modal";

#user-settings-overlay {
  #user-settings-modal {
    height: 85%;
    width: 1134px;
    margin-top: 9vh;

    form[name=editUserForm] {
      height: 95%;
    }

    #user-settings {
      height: calc(100% - 60px);
      overflow-y: scroll;
      position: relative;

      .row.text-input {
        width: 913px;
      }

      .row.text-input.programs {
        width: 852px;
        margin-bottom: 0px;
        margin-top: 20px;
      }

      .row.program-search-results > ul {
        padding: 0 5px 0 5px;

        button {
          color: $click-color;
          border: none;
        }

        button:hover {
          color: #fff;
          background-color: initial;
        }

      }

      .row {


        input {
          width: 100%;
        }

        &.geo-channel-row {
          margin-bottom: 35px;
          background-color: $modal-background;


          .geo-filter {
            position: absolute;
            width: 228px;
            min-width: 194px;
            height: 40px;
            padding-left: 20px;
            overflow: visible;
            z-index:100;

            am-select.open {
              z-index: 100;
              overflow: visible;
            }

            am-select {
              height: 100%;
              width: 228px;

              select-title {

                border: 1px solid $separator-color;
                background-color: inherit;
                i.icon-Triangle_Down {
                  color: $separator-color !important;
                }
              }
              &.open {
                ul {
                  visibility: visible;
                }
              }
              ul {
                margin-right: 0;
                margin-top: 11px;
                visibility: hidden;
                li {
                  padding: 0;
                  div {
                    padding-left: 15px;
                  }
                }
              }
            }
          }

          .geo-label {
            position: relative;
            color: #fff;
            left: 1px;
          }


        }



        &.double-row {
          height: 110px;
        }

        &.permissions-row {
          margin-bottom: 5px;
          margin-top: 25px;
          height: auto;
          overflow: auto;
          padding-right: 10px;
        }

        .select-style {
          margin-top: 5px;
          overflow: hidden;
          background-color: $modal-background;
          color: $highlight-color;
          border: 1px solid $separator-color;

          select {
            padding: 8px;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            background: transparent none;
            -webkit-appearance: none;

            &:focus {
              outline: none;
              background-color: $input-focus-bg-color;
              border-color: $modal-border;
              option {
                &:hover {
                  background-color: black;
                }
              }
            }
          }
        }

        &.permissions {
          height: 45px;

          .switch-button {
            margin-top: 5px;
            float: right;
          }
        }
      }
    }

    .control-buttons {
      .save-loader {
        margin-left: 15px;
        display: inline-block;
        width: 60px;
        height: 30px;
        cursor: default;
        float: right;
      }

      .change-password-btn {
        float: left;
        padding: 5px 10px;
        margin-top: 2px;
      }
    }
  }
}

@import '../style/bordered-button';

.example-popup {
  max-width: 430px;

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .loader-overlay {
    border-radius: 4px;
  }

  .example-div {
    float:left;
    padding-top: 15px;
    width: 100%;
    a {
      text-decoration: none;
    }
  }

  .example-popup-main {
    margin: 15px;
    padding-right: 15px;
    width: 410px;
    max-height: 250px;
  }
  .example-popup-title {
    @extend .base_bold_font;
    font-size:16px;

    .tag-wrapper{
      @extend .base_bold_font;
      z-index: -1;
      border-radius: 2px;
      color: #090909;
      font-size: 13px;
      display: inline-block;
      float: right;
      padding-left: .2em;
      padding-right: .2em;
      vertical-align: middle;
      position: relative;
      white-space: nowrap;
      height: 18px;
      margin-top: 1px;

      .seed-name {
        vertical-align: sub;
      }
    }
  }

  .example-popup-seed {
    color: $title-color;
    padding-right: 5px;
    height: 20px;
    float:left;
    padding-top: 1px;
  }
  .seed-wrapper {
    display: inline-block;
    padding-top: 3px;
  }

   .positive {
     margin-bottom: 10px;
     color:#4cd434;
     position: relative;
     float:left;
  }

  .negative {
    margin-bottom: 10px;
    color:red;
    position: relative;
    float:left;
  }

  .audience-seed-max-width {
    max-width: 183px;
  }

  .example-popup-lifestyle {
    padding-left: 10px;
    padding-top: 1px;
    .icon-Audience {
      color: white;
      font-size: 13px;
      padding-right: 5px;
      padding-left: 11px;
      float: left;
      border-left: $click-color 1px solid;

    }
  }

  // .example-popup-consumption-minus {
  //   color:#ea2727;
  // }

  .example-popup-consumption {
    padding-top: 1px;
  }

  .example-popup-img {
    float: left;
    padding-right: 15px;
    img {
      border-radius: 4px;
    }
  }

  .example-popup-bottom-padding {
    padding-bottom:20px;
  }

  .example-popup-desc {
    font-size:13px;
    line-height: 16px;

    .link-icon {
      margin-right: 5px;
    }

    .icon-facebook {
      font-size: 11px;
      vertical-align: baseline;
    }

  }
  .clickable-div {
    cursor: pointer;
    &:hover {
      .example-popup-link-color {
        color:$highlight-color;
      }
    }
  }

  .example-popup-desc-color {
    min-height: 14px;
    max-height: 32px;
    color: $regular-color;
  }
  .example-popup-link-color {
    color: $click-color;
  }
  .refine-button {
    @extend .base_bold_font;
    float: left;
    font-size: 14px;
    padding-right: 15px;
    cursor: pointer;
    color: #81878C;
    margin-top: 9px;
    &:hover {
        color:white;
    }
  }

  .trash {
    float: left;
    padding-right: 15px;
    margin-top: 5px;
    cursor: pointer;
  }

  .example-popup-btn {
    float: right;
    clear: left;
    padding-bottom: 15px;
    position: relative;
  }
  .select-button {
    padding: 7px 14px 7px 14px;
    font-size: 14px;
    float: right;
    @extend .base_bold_font;
  }

  .sign {
    float:left;
    margin-right: 2px;
  }

  .positive-sign {
    font-size: 18px;
    margin-top:-2px;
  }

  .negative-sign {
    font-size: 25px;
    margin-top:-3px;
  }

  .positive-sign:before {
    content: "+";
  }
  .negative-sign:before {
    content: "-";
  }

  .bottom-space {
    height: 10px;
    width: 1px;
    float:left;
    clear: left;
  }
}

#program-settings-overlay {
  #program-settings-modal {
    #connect-sources {
      height: 351px;
      padding-top: 10px;
      position: relative;

      .row {
        margin-bottom: 0;

        input {
          margin-top: 0;
          border-color: $cancel-text-color;
        }
      }

      .no-sources {
        text-align: center;
        transform: translateY(170%);

        .no-sources-title {
          display: block;
          margin-bottom: 15px;
          font-size: 13px;
          font-weight: bold;
          color: #dddee1;
        }
      }

      .sources-wrapper {
        .quick-table-wrap {
          height: 93%;
        }

        .thead .column.scroll-td {
          max-width: 14px;
        }

        .tbody .column.scroll-td {
          max-width: 0;
          padding: 0;
        }

        .thead {
          .column.numberofconnections, .column.actions {
            cursor: default;
          }
        }

        .column.dateadded {
          flex: 0.7;
        }

        .column.actions {
          flex: 0.3;

          i {
            padding: 0 5px;
            color: $cancel-text-color;

            &:hover {
              cursor: pointer;
              color: $highlight-color;
            }
          }
        }
      }
    }
  }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

am-image-toggle-button {
  $button-space: 30px;

  .image-toggle-button-wrapper {
    -webkit-user-select: none;
    font: 16px "Helvetica Bold";
    color: white;
    display: table;
    margin: 0 auto;

    &.disabled {
      pointer-events: none;
      toggle-button {
        color: $disabled !important;
      }
    }

    toggle-button {
      float: left;
      position: relative;
      margin-right: $button-space;
      cursor: pointer;
      .dark-button {
        right: 0;
        margin-top: 10px;
        background-color: #6b707d;
        border-color: #6b707d !important;
        position: absolute;
      }

      .triangle-down {
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid #fff;
      }

      .img-dark-overlay {
        height: 128px;
        width: 208px;
        position: absolute;
        left: 1px;
        top: 1px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.35);
      }

      i {
        position: absolute;
        z-index: 1;
        text-align: center;
        width: 100%;
        left: 0;
        bottom: 23px;
        display: none;
        font-size: 17px;
      }

      &:not(.disabled):hover, &.selected {
        i {
          display: block;
        }
        .img-dark-overlay {
          background: none;
        }

        img {
          border: solid 2px white;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.disabled {
        cursor: default;
      }

      img {
        border: solid 2px #6b707d;
        border-radius: 4px;
      }

      span {
        position: absolute;
        z-index: 1;
        text-align: center;
        width: 100%;
        left: 0;
        top: 56px;
      }
    }
  }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

.am-gauge-chart {
  width: 100%;
  height: 100%;

  .bar {
    background: $textbox-bg;
    width: 100%;
    height: 10px;
  }

  .bar-cell {
    width:100%;
    height:100%;
    position: relative;
  }

  table {
    height: 30px;
    width: 100%;
  }
  
  .indicator-bar {
    width: 20px;
    &.bar-type {
      height: 10px; 
      top: 10px; 
    }
    height: 12px;
    position: absolute;
    top: 9px;
    background-color: $audience-highlight-color;
  }

  .label {
    font-weight: bold;
    font-size: 15px;
  }

  .right-label {
    padding-left: 10px;
  }

  .left-label {
    padding-right: 10px;
  }

}



@import "common/modals/password/forgot-password.scss";
@import "common/modals/notification/notification-modal.scss";

$border-color: #2e3138;
$regular-color: #cacaca;
$selected-color: white;
$click-color: #81878C;
$bg-color-hover: white;
$btn-text-size: 16px;
$dark-bg: #151719;

.login-background {
  height: 100vh;
  background-size: 100% 100%;
  background: black none no-repeat;
  min-width: 1080px;
  min-height: 680px;

  #login-video {
    video {
      position: fixed;
      top: 0;
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
      opacity: 0.7;
      transition: 1s opacity;

      &.shaded-video {
        opacity: 0.5;
      }
    }
  }
}

.amobee-red-flag {
  margin: 0 20px 0 100px;
  position: relative;
  opacity: 1 !important;
  @extend .base_bold_font;
  z-index: 1;
}

.amobee-text-box {
  position: relative;
  top: calc(50% - 200px);

  .amobee-text2 {
    text-align: center;
    font-size: 50px;
    font-weight: 300;
    color: white;
    font-family: "Helvetica Bold"
  }
  .amobee-text3 {
    font-size: 18px;
    color: white;
    text-align: center;
    margin-top: 20px;
  }
  .open-sign-in {
    margin: 0 auto;
    margin-top: 29px;
    width: 90px;
    font-weight: bold;
    padding: 8px 0 8px 0;
    font-size: 14px;
    text-align: center;
  }
}

.overlay {
  position: relative;
  margin: 0 auto;
  padding: 27px 30px 30px 30px;
  top: calc(50% - 300px);

  border: 1px solid white;
  border-bottom: 2px solid white;
  border-radius: 8px;

  z-index: 100;
  width: 320px;
  height: 370px;

  color: $text-color;
  background-color: #2c2f38;
  opacity: 0.95;
  font: 14px Helvetica;

  .login-container {
    .login-title {
      padding: 7px 0 0 0;
      font-size: 18px;
      @extend .base_bold_font;
      color: $selected-color;
      .close-modal {
        float: right;
        font-size: 14px;
        margin-top: 4px;
        cursor: pointer;
      }
    }
    .form-container {
      padding: 30px 0 10px 0;
      .row {
        padding: 0 0 25px 0;
        label {
          color: $regular-color;
          font-size: 14px;
          @extend .base_bold_font;
          display: block;
          padding: 0 0 5px 0;
        }
        input[type='text'], input[type='password'], textarea {
          @extend .base_bold_font;
          padding-left: 10px;
          height: 40px;
          background-color: #2c2f38;
          color: $regular-color;
          width: 100%;
          display: block;
          border: 1px solid grey;

          &:focus {
            background-color: #22252b;
            border-color: #ffffff;
          }
        }

        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 50px #2c2f38 inset;
          -webkit-text-fill-color: white;
        }

        input.ng-invalid.ng-dirty {
          border: 1px solid $border-color-error;
        }
      }

      .error-messages {
        position: absolute;
        top: 231px;
        left: 30px;
        .error {
          font-family: "Helvetica";
          font-size: 13px;
        }
      }
      .controls {
        padding-top: 20px;

        label {
          padding-right: 20px;
          margin-top: 7px;
          color: white;
          font-size: 13px;
          @extend .base_bold_font;
          cursor: pointer;
          float: right;
        }
        am-input[type=checkbox] {
          cursor: pointer;
          position: relative;
          top: 9px;
          left: 64px;
        }
        .branding-bordered-button {
          float: right;
          font-size: 13px;
          font-weight: 300;
          padding: 6px 8px 6px 8px;
        }
      }
    }
    .user-links {
      font-family: "Helvetica", sans-serif;;
      font-size: 13px;
      cursor: default;
      margin-left: 3px;
      padding: 0 0 20px 0;
      border-bottom: 1px solid #545a66;

      a {
        &:hover {
          color: white;
        }
      }
    }
  }
}

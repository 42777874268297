@import "../../infra/style/base";
@import "../../infra/style/style_guide";

selectable-bar-chart {

  display: block;
  $title-width: 200px;
  $values-width: 65px;
  $row-height: 45px;
  $title-and-bar-spacing: 30px;

  .selectable-bar-chart-drv {
    -webkit-user-select: none;
    height: 100%;
    background: #202427;
    padding: 20px 20px 0 10px;
    font-family: Helvetica;
    font-size: 16px;
    position: relative;

    .icon-tick_in_circle, .icon-circle-o{
      font-size:14px;
    }

    .no-data-msg {
      padding-left: 10px;
      padding-top: 10px;
      font-size: 14px;
      color: $regular-color;
    }

    .scroll-container {
      overflow-y: auto;
      height: calc(100% - 34px);
    }

    &.selection-disabled .row {
      cursor: default;
      &.selected, &:hover {
        background: transparent;
      }
    }

    .chart-title {
      color: white;
      font-family: Helvetica Bold;
      padding-bottom: 15px;
      display: inline-block;
      padding-left: 10px;
    }

    .sbc-title, .bar-container {
      float: left;
      height: 100%;
      line-height: $row-height;
      font-size: 14px;
    }

    .sbc-title {
      //text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: calc(#{$title-width} - 16px);;
      padding-right: $title-and-bar-spacing;
      padding-left: 20px;
      color: $click-color;
      user-select: initial;
    }

    .bar-container {
      width: calc(100% - #{$title-width} - 20px);
      display: flex;
      align-items: center;
      position: relative;
      .sbc-bar {
        position: absolute;
        background: $regular-color;
        height: 10px;
      }
    }

    .tooltip-container {
      height: 40px;
      position: absolute;
      z-index: 10;
    }

    .values {
       position: absolute;
       font-size: 12px;
       color: $regular-color;
       width: 50px;
       padding-left: 10px;
       white-space: nowrap;
       line-height: $row-height;
    }

    .row {
      height: $row-height;
      cursor: pointer;
      margin: 5px 0;
      border-radius: 4px;

      &.selected, &:hover {
        background: #191c1e;
      }

      &.selected {

        .sbc-bar {
          background: $audience-highlight-color;
        }

        .sbc-title, .values {
          font-family: Helvetica Bold;
          color: white;
        }
      }
    }

    am-input[type=checked]:not(#select-all-checkbox){
      float: left;
      margin-top: 13px;
      margin-left: 10px;
    }

    #select-all-checkbox{
      font-size: 14px;
      margin-top: 2px;
      margin-right: 4px;
      color: #{$click-color}
      &:hover, &:hover *:not(.icon-tick_in_circle){color: #{$selected-color}}
    }


    /*different bar style*/
    &.checkboxes{
        
        .sbc-title{padding-left:10px;}

        .icon-tick_in_circle{color: #{$amobee-blue};}

        .checked .sbc-title, .checked#select-all-checkbox{
          color: #{$selected-color} !important}

        .row.disabled{
          cursor: auto; 
          i.checked, i.unchecked{color: #{$disabled-row};}
          .sbc-title, .values{color: #{$selected-color} !important;}
          .sbc-bar{background: #{$selected-color}}
        }
    }

  }
}

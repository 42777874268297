.phrases-manager table tr td.misc,
.phrases-manager table tr td.action {
  text-align: left;
  padding-left: 0.5em;
}

.phrases-manager {
  input[type=text], input:not([type]) {
    width: 10em;
  }

  .p-noun-detect {
    am-input[type=switch] span.Off {
      color: #888;
    }
  }
}

.misc {
  i {
    color: white;
  }
}

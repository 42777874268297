
@import url("http://fastfonts.net/lt/1.css?apiType=css&c=371fd9b7-a23c-4ee7-9889-99e6d08bf1fe&fontids=675295,675298,675301");
@font-face{
  font-family:"Helvetica";
  src:url("../fonts/Helvetica/Helvetica.eot?#iefix");
  src:url("../fonts/Helvetica/Helvetica.eot?#iefix") format("eot"),
    url("../fonts/Helvetica/Helvetica.woff2") format("woff2"),
    url("../fonts/Helvetica/Helvetica.woff") format("woff"),
    url("../fonts/Helvetica/Helvetica.ttf") format("truetype"),
    url("../fonts/Helvetica/Helvetica.svg#d17bc040-9e8b-4397-8356-8153f4a64edf") format("svg")
;
}
@font-face{
font-family:"Helvetica Bold";
src:url("../fonts/HelveticaBold/HelveticaBold.eot?#iefix");
src:url("../fonts/HelveticaBold/HelveticaBold.eot?#iefix") format("eot"),
url("../fonts/HelveticaBold/HelveticaBold.woff2") format("woff2"),
url("../fonts/HelveticaBold/HelveticaBold.woff") format("woff"),
url("../fonts/HelveticaBold/HelveticaBold.ttf") format("truetype"),
url("../fonts/HelveticaBold/HelveticaBold.svg#874bbc4a-0091-49f0-93ef-ea4e69c3cc7a") format("svg")
;
}
@font-face{
font-family:"Helvetica BoldItalic";
src:url("../fonts/HelveticaBoldItalic/HelveticaBoldItalic.eot?#iefix");
src:url("../fonts/HelveticaBoldItalic/HelveticaBoldItalic.eot?#iefix") format("eot"),
url("../fonts/HelveticaBoldItalic/HelveticaBoldItalic.woff2") format("woff2"),
url("../fonts/HelveticaBoldItalic/HelveticaBoldItalic.woff") format("woff"),
url("../fonts/HelveticaBoldItalic/HelveticaBoldItalic.ttf") format("truetype"),
url("../fonts/HelveticaBoldItalic/HelveticaBoldItalic.svg#2a3f33c9-8d6e-440c-9632-83e5d9419616") format("svg")
;
}



.icon-table:before {
  content: "\e962";
}
.icon-bar-chart:before {
  content: "\e960";
}
.icon-world:before {
  content: "\e961";
}
.icon-audience-female-2:before {
  content: "\e95c";
}
.icon-audience-male-2:before {
  content: "\e95d";
}
.icon-audience-female:before {
  content: "\e95e";
}
.icon-audience-male:before {
  content: "\e95f";
}
.icon-asterisk:before {
  content: "\e95b";
}
.icon-new-segment:before {
  content: "\e957";
}
.icon-load-segment:before {
  content: "\e958";
}
.icon-save-segment:before {
  content: "\e959";
}
.icon-dropdown-select-arrow:before {
  content: "\e95a";
}
.icon-pinterest-board:before {
  content: "\e954";
}
.icon-pinterest-logo:before {
  content: "\e955";
}
.icon-repin:before {
  content: "\e956";
}
.icon-link:before {
  content: "\e953";
}
.icon-custom-content-bookmark:before {
  content: "\e948";
}
.icon-sources-rss-tiny:before {
  content: "\e949";
}
.icon-sources-pinterest-tiny:before {
  content: "\e94a";
}
.icon-sources-twitter-tiny:before {
  content: "\e94b";
}
.icon-sources-youtube-tiny:before {
  content: "\e94c";
}
.icon-sources-facebook-tiny:before {
  content: "\e94d";
}
.icon-sources-rss:before {
  content: "\e94e";
}
.icon-sources-pinterest:before {
  content: "\e94f";
}
.icon-sources-twitter:before {
  content: "\e950";
}
.icon-sources-youtube:before {
  content: "\e951";
}
.icon-sources-facebook:before {
  content: "\e952";
}
.icon-plus-in-circle:before {
  content: "\e947";
}
.icon-activate:before {
  content: "\e946";
}
.icon-instagram:before {
  content: "\e945";
}
.icon-camera:before {
  content: "\e944";
}
.icon-Retweet_new:before {
  content: "\e943";
}
.icon-arrow_down:before {
  content: "\e941";
}
.icon-arrow_up:before {
  content: "\e942";
}
.icon-interests:before {
  content: "\e93f";
}
.icon-lifestyle:before {
  content: "\e940";
}
.icon-navigate:before {
  content: "\e93e";
}
.icon-default-favicon .path1:before {
  content: "\e93c";
  color: rgb(56, 60, 63);
}
.icon-default-favicon .path2:before {
  content: "\e93d";
  margin-left: -0.7998046875em;
  color: rgb(202, 202, 202);
}
.icon-film:before {
  content: "\e93b";
}
.icon-read:before {
  content: "\e93a";
}
.icon-finance:before {
  content: "\e939";
}
.icon-shopping-cart:before {
  content: "\e938";
}
.icon-arts-and-humanities:before {
  content: "\e91d";
}
.icon-automotive:before {
  content: "\e91e";
}
.icon-beauty-skin-and-fashion:before {
  content: "\e91f";
}
.icon-business:before {
  content: "\e920";
}
.icon-consumer-electronics:before {
  content: "\e921";
}
.icon-education:before {
  content: "\e922";
}
.icon-employment:before {
  content: "\e927";
}
.icon-entertainment:before {
  content: "\e928";
}
.icon-family-lifestyle:before {
  content: "\e929";
}
.icon-food-drink-and-dining:before {
  content: "\e92a";
}
.icon-government-and-politics:before {
  content: "\e92b";
}
.icon-health:before {
  content: "\e92c";
}
.icon-holidays-and-special-occasions:before {
  content: "\e92d";
}
.icon-home-and-garden:before {
  content: "\e92e";
}
.icon-personal-finance:before {
  content: "\e92f";
}
.icon-real-estate:before {
  content: "\e930";
}
.icon-science:before {
  content: "\e931";
}
.icon-society-and-culture:before {
  content: "\e932";
}
.icon-sports:before {
  content: "\e933";
}
.icon-tech-and-computing:before {
  content: "\e934";
}
.icon-telecom:before {
  content: "\e935";
}
.icon-toys-and-hobbies:before {
  content: "\e936";
}
.icon-travel:before {
  content: "\e937";
}
.icon-Arrow_left:before {
  content: "\e923";
}
.icon-Arrow_right:before {
  content: "\e924";
}
.icon-Circle_normal:before {
  content: "\e925";
}
.icon-Circle_on:before {
  content: "\e926";
}
.icon-threedots:before {
  content: "\e91c";
}
.icon-drag_box:before {
  content: "\e91a";
}
.icon-radio_on:before {
  content: "\e91b";
}
.icon-All_Population:before {
  content: "\e918";
}
.icon-Arrow:before {
  content: "\e919";
}
.icon-bell:before {
  content: "\e917";
}
.icon-refresh_new:before {
  content: "\e915";
}
.icon-X_CLEAR:before {
  content: "\e916";
}
.icon-minus:before {
  content: "\e912";
}
.icon-plus-2:before {
  content: "\e913";
}
.icon-Advanced_new:before {
  content: "\e914";
}
.icon-Advanced:before {
  content: "\e910";
}
.icon-channel:before {
  content: "\e911";
}
.icon-minimize:before {
  content: "\e90d";
}
.icon-maximize:before {
  content: "\e90e";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-manage-program:before {
  content: "\e90c";
}
.icon-contact:before {
  content: "\e90b";
}
.icon-Help:before {
  content: "\e909";
}
.icon-info:before {
  content: "\e90a";
}
.icon-BehavioralSegment:before {
  content: "\e907";
}
.icon-Demographics:before {
  content: "\e908";
}
.icon-clone:before {
  content: "\e905";
}
.icon-Add_Phrase:before {
  content: "\e906";
}
.icon-remove-program:before {
  content: "\e904";
}
.icon-Trending_war_room:before {
  content: "\e902";
}
.icon-Top_war_room:before {
  content: "\e903";
}
.icon-share-program:before {
  content: "\e901";
}
.icon-twitter_account:before {
  content: "\e900";
}
.icon-Content_drivers:before {
  content: "\e800";
}
.icon-circle-o:before {
  content: "\e63b";
}
.icon-hamburger:before {
  content: "\e638";
}
.icon-radar:before {
  content: "\e61a";
}
.icon-lock_open:before {
  content: "\e627";
}
.icon-male_female:before {
  content: "\e637";
}
.icon-remove_from_cart:before {
  content: "\e622";
}
.icon-add_to_cart:before {
  content: "\e626";
}
.icon-unwatch:before {
  content: "\e628";
}
.icon-watch:before {
  content: "\e629";
}
.icon-pause:before {
  content: "\e62b";
}
.icon-play:before {
  content: "\e62c";
}
.icon-Spikes:before {
  content: "\e62f";
}
.icon-plus:before {
  content: "\e631";
}
.icon-Real_Time:before {
  content: "\e605";
}
.icon-Star:before {
  content: "\e613";
}
.icon-Video_icon:before {
  content: "\e612";
}
.icon-Article:before {
  content: "\e603";
}
.icon-comment:before {
  content: "\e604";
}
.icon-Date:before {
  content: "\e607";
}
.icon-Like:before {
  content: "\e60f";
}
.icon-Refresh:before {
  content: "\e61c";
}
.icon-share3:before {
  content: "\e61e";
}
.icon-Twitter:before {
  content: "\e620";
}
.icon-Views:before {
  content: "\e624";
}
.icon-share2:before {
  content: "\e609";
}
.icon-stream:before {
  content: "\e60d";
}
.icon-youtube-play-button .path1:before {
  content: "\e616";
  color: rgb(255, 255, 255);
}
.icon-youtube-play-button .path2:before {
  content: "\e618";
  margin-left: -1.4091796875em;
  color: rgb(0, 0, 0);
}
.icon-geo:before {
  content: "\e60e";
}
.icon-Box_Off:before {
  content: "\e602";
}
.icon-Triangle_Down:before {
  content: "\e606";
}
.icon-Triangle_Up:before {
  content: "\e608";
}
.icon-Triangle_Right:before {
  content: "\e60a";
}
.icon-X:before {
  content: "\e60b";
}
.icon-Roll_On:before {
  content: "\e60c";
}
.icon-Box_Roll_On:before {
  content: "\e610";
}
.icon-lock:before {
  content: "\e611";
}
.icon-Program:before {
  content: "\e614";
}
.icon-Trash:before {
  content: "\e615";
}
.icon-Bubbels:before {
  content: "\e617";
}
.icon-Audience:before {
  content: "\e619";
}
.icon-Export:before {
  content: "\e61b";
}
.icon-Female:before {
  content: "\e61d";
}
.icon-male:before {
  content: "\e61f";
}
.icon-Optimize:before {
  content: "\e621";
}
.icon-Topic:before {
  content: "\e623";
}
.icon-Search:before {
  content: "\e625";
}
.icon-Left_Arrow:before {
  content: "\e62a";
}
.icon-Right_Arrow:before {
  content: "\e62d";
}
.icon-dashboard:before {
  content: "\e62e";
}
.icon-discovery:before {
  content: "\e630";
}
.icon-insights:before {
  content: "\e632";
}
.icon-settings:before {
  content: "\e633";
}
.icon-profile:before {
  content: "\e635";
}
.icon-tv-monitor:before {
  content: "\e636";
}
.icon-war-room:before {
  content: "\e639";
}
.icon-industry:before {
  content: "\e63a";
}
.icon-Calender:before {
  content: "\e63d";
}
.icon-equalizer:before {
  content: "\e1dd";
}
.icon-share:before {
  content: "\e63e";
}
.icon-edit_icon:before {
  content: "\e640";
}
.icon-Targeting:before {
  content: "\e642";
}
.icon-preview:before {
  content: "\e644";
}
.icon-Edit:before {
  content: "\e646";
}
.icon-tick_in_circle:before {
  content: "\e600";
}
.icon-exclamation_in_circle:before {
  content: "\e601";
}

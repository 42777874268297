@import "infra/style/base_modal";

#connect-source-overlay {
  #connect-source-modal {
    width: 520px;
    margin-top: 11vh;

    .connect-source {
      margin-bottom: 5px;

      .main-title {
        font-size: 14px;
        font-weight: bold;
        color: $highlight-color;
        margin: 25px 0 10px;
        -webkit-user-select: none;
      }

      .sources {
        margin-bottom: 15px;
        -webkit-user-select: none;

        .mini-title {
          margin-bottom: 10px;
        }

        .type-wrapper {
          font-size: 14px;
          color: $cancel-text-color;
          margin-right: 20px;

          .type-icon {
            font-size: 16px;
          }

          &:hover, &.selected {
            color: $highlight-color;
            cursor: pointer;
          }

          &.disabled {
            color: $separator-color;
            cursor: default;
          }
        }
      }

      .row {
        #name-input.input {
          width: 100%;
          color: $modal-text-color;
        }

        &.source-url-input {
          margin-bottom: 15px;

          .input-label {
            display: block;
          }

          .input {
            width: 85%;
            margin-right: 15px;
          }

          ::-webkit-input-placeholder {
            color: $submit-color-disabled;
          }

          .main-button:disabled {
            cursor: default;
          }
        }

        .urls-title {
          font-size: 14px;
          color: $highlight-color;
          margin-bottom: 10px;
        }

        .sources-list {
          margin-right: 10px;
          padding: 0;
          max-height: 112px;
          overflow-y: auto;

          .source-line {
            padding: 6px 6px 6px 0;
            color: $cancel-text-color;

            .icon-X {
              font-size: 8px;
              cursor: pointer;
              padding: 0 5px;
              margin-right: 5px;

              &:hover {
                color: $highlight-color;
              }
            }

            .source-url {
              font-size: 13px;
              margin-right: 10px;
            }

            .source-icon {
              vertical-align: middle;
            }

            .source-status {
              display: inline-block;

              .status-indicator {
                margin-left: 10px;
                font-size: 12px;

                &.Connected {
                  color: #66cf82;
                }

                &.Pending {
                  color: #f6f765;
                }
              }

              .status-text {
                font-size: 12px;
                color: #495055;
              }
            }
          }
        }

      }

    }
  }
}

@import "../../infra/style/base";
@import '../style/bordered-button';

.example-popup-refine {
    width: 170px;
    height: 130px;
    z-index: 99999 !important;

    .item {
        cursor: pointer;
        margin: 10px;

        span {
            width: 150px;
            padding-left: 5px;
            padding-right: 5px;

            height: 30px;
            line-height: 30px;
            font-weight:normal;
            display: inline-block;
            font-size:13px;
            text-decoration:none;
            color: #81878C;
        }
        &:hover {
            span {
                background-color: #151719;
                color: $main-btn-bg-hover;
            }
        }
    }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

am-gauge-bar {
  width: 100%;
  height: 100%;
  display: block;

  .ticks-container {
    background: #1b2022;
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    padding: 10px;

    tick {
        background: #2f373b;
        height: 100%;
        width: 3.72%;
        position: relative;

        &.highlighted {
          background: $audience-highlight-color;
        }

        i {
          bottom: - 30px;
          position: absolute;
          font-size: 13px;
          color: $regular-color;
          line-height: initial;
          margin-left: calc(50% - 6px);
        }

    }
  }

}



@import "infra/style/base_modal";

$animation-time: 0.2s;
$triangle-width: 20px;
$input-unfocused-color: #81878c;

#custom-axis-dialog-overlay {
  #custom-axis-dialog-modal {
    width: 490px;
    top: calc(100% / 2 - 310px);
    margin-top: 0;
    padding: 30px 30px 20px 30px;

    .icon-X.close-modal {
      top: 30px;
      right: 30px;
    }

    .title {
      display: inline-block;
      width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    #custom-axis-dialog {
      position: relative;
      padding-bottom: 10px;
      padding-top: 5px;
      font-size: 14px;

      ::-webkit-input-placeholder {
        color: $input-unfocused-color;
      }

      am-dropdown-select {
        padding-top: 5px;
        width: 100%;

        .options {
          overflow-y: auto;
          max-height: 210px;
        }
      }

      .dropdown-tree {
        position: relative;
        padding-top: 5px;
        width: 100%;
        line-height: 40px;
        height: 40px;
        display: inline-block;
        &.open {
          .summary {
            border-color: #ffffff;

            .icon-Triangle_Down:before {
              transform: rotate(-180deg);
              transition: all $animation-time linear;
              color: #ffffff;
              transform-origin: center;
              margin-right: 2px;
              top: -1px;
            }
          }
          .menu-triangle:before, .menu-triangle:after {
            visibility: visible;
          }
          .data-tree {
            display: block;

            .select-all {
              line-height: 20px;
              padding-top: 5px;
              margin-left: 5px;
            }
          }
        }

        .summary {
          border: 1px solid #81878c;
          background-color: inherit;
          width: inherit;
          height: inherit;
          cursor: pointer;
          color: #81878c;
          text-transform: capitalize;
          line-height: 36px;
          height: 36px;

          .summary-text {
            padding-left: 10px;
            white-space: nowrap;
            display: inline-block;
            width: 93%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .icon-Triangle_Down {
            font-size: 11px;
            float: right;
            padding-right: 5px;

            &:before {
              position: relative;
              display: inline-block;
              transform: rotate(0deg);
              transition: all $animation-time linear;
              transform-origin: center;
            }
          }
        }

        .summary.note {
          display: none;
        }

        .menu-triangle:after {
          content: '';
          display: block;
          position: absolute;
          top: 43px;
          left: calc((100% - #{$triangle-width}) / 2);
          width: 0;
          height: 0;
          border-color: transparent transparent #202427 transparent;
          border-style: solid;
          border-width: 10px;
          z-index: 5;
          opacity: 0.99;
          visibility: hidden;

        }

        .menu-triangle:before {
          content: '';
          display: block;
          position: absolute;
          top: 40px;
          left: calc((100% - #{$triangle-width}) / 2 - 1px);
          width: 0;
          height: 0;
          border-color: transparent transparent white transparent;
          border-style: solid;
          border-width: 11px;
          z-index: 5;
          visibility: hidden;
        }

        .data-tree {
          position: absolute;
          width: inherit;
          border: 1px solid #ffffff;
          border-radius: 4px;
          font-size: 14px;
          list-style-type: none;
          margin: 20px 0 0 0 !important;
          background: $input-focus-bg-color;
          opacity: .95;
          text-transform: capitalize;
          z-index: 4;
          display: none;
          overflow-y: auto;
          padding: 5px;
          min-height: 140px;
          max-height: calc(100vh / 2 - 170px);
        }
      }

      .row {
        margin-bottom: 15px;

        input {
          width: 100%;
          color: $input-unfocused-color;
          border-color: $input-unfocused-color;

          &:focus {
            color: #ffffff;
            border-color: #ffffff;
            background-color: $input-focus-bg-color;
          }

          &.ng-invalid {
            border-color: $invalid-color;
          }
        }
      }

      .row-association {
        margin-bottom: 15px;
        width: 100%;

        .association-row-wrapper {
          @include display_flex();
          @include flex_direction(row);
          justify-content: space-between;
          margin-top: 5px;

          input-bar.search {
            color: $input-unfocused-color;
            border-color: $input-unfocused-color;
            margin-right: 10px;

            &.input-mode {
              color: #ffffff;
              border-color: #ffffff;
              background-color: $input-focus-bg-color;
            }

            results {
              .results-scrollable {
                max-height: 26vh;

                .suggestions-wrapper {
                  min-width: 50%;
                }
              }
            }

            label {
              padding-top: 6px !important;
            }
          }

          .association-measure-buttons {
            @include display_flex();
            @include flex_direction(row);
            align-items: center;
            color: $click-color;

            .control-button {
              background-color: #202427;
              border: 1px solid black;
              padding: 5px 10px 5px 10px;
            }

            .control-button:not([disabled]):hover {
              color: $selected-color;
              cursor: pointer;
            }

            .control-button[disabled] {
              background-color: $disabled-background;
              color: $disabled;
            }

            .left-button {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            .right-button {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }

            .selected-control {
              background-color: $selection-background;
              padding: 5px 10px 5px 10px;
              cursor: default;
              color: $selected-color;
            }
          }
        }
      }

      .row-geos {
        margin-bottom: 15px;

        .geos-dropdown .options {
          min-height: 85px;
          max-height: calc(100vh / 2 - 245px);
        }

        .data-tree {
          min-height: 85px;
          max-height: calc(100vh / 2 - 245px);
          font-size: 14px;

          .search-tree {
            margin-top: 0;
            margin-bottom: 0;

            input {
              width: 85%;
            }
          }
        }

      }

    }
  }

  .control-buttons {
    @include display_flex();
    @include flex_item_align(center);
    justify-content: flex-end;
    padding-top: 20px;

    .cancel-btn {
      margin-top: 0;
    }

    .submit-btn {
      &:disabled {
        color: $disabled-font;
        border: 1px solid $disabled !important;
        background-color: $disabled;
      }
    }

    .delimiter {
      width: 8px;
      height: 20px;
      vertical-align:middle;
      margin-right: 8px;
      display: inline-block;
      border-right: 1px solid #444;
    }

    .edit-controls {
      margin-right: 15px;

      i {
        margin-left: .2em;
        font-size:16px;
      }
    }
  }
}

am-nav-button {
  height: 3em;
  display: block;
  cursor: pointer;
  margin-top: 10px;
  white-space: nowrap;
  color: #6b707d;
  position: relative;

  &:hover .main > a {
    color: white;
  }

  &:hover {
    am-sub-nav {
      display: block;
    }
  }

  a {
    color: inherit;
    text-decoration: inherit;
    display: block;
    overflow: hidden;
    cursor: pointer;
  }

  .highlight-bar {
    float: left;
    height: 3em;
    width: 5px;
    visibility: hidden;
    display: inline-block;
    background-color: $amobee-blue;
  }

  i[class^="icon-"] {
    display: inline-block;
    margin-left: 15px;
    line-height: 48px;
    vertical-align: middle;
    float: left;
  }

  i[class="icon-radar"] {
    margin-left: 13px;
  }
  i[class="icon-Audience"] {
    margin-left: 13px;
  }
  i[class="icon-bell"] {
    font-size: 22px;
  }
  i[class="icon-Help"] {
    font-size: 16px;
  }
  i[class="icon-settings"] {
    padding-left: 1px;
  }
  i[class="icon-lock"]:before {
    padding-left: 3px;
    font-size: 1.1em;
  }

  .text {
    display: block;
    line-height: 3em;
    margin-left: 60px;
    @extend .base_bold_font;
  }

  > .chosen {
    svg-inline > * {
      fill: $highlight-color;
    }

    & > * > .text {
      color: $highlight-color;
    }

    & > * > .highlight-bar {
      visibility: visible;
    }

    & > * > i {
      color: white;
    }
  }

  > .disabled {
    svg-inline > * {
      fill: $fade-color;
    }

    & > * > .text {
      color: $fade-color;
    }

    & > * > .highlight-bar {
      visibility: hidden;
    }

    & > * > i {
      color: $fade-color;
    }
  }

  > .drilldown {
    &:hover {
      background-color: #111111;
    }
  }
}

menubar.open am-nav-button {
  & > .text {
    opacity: 1;
  }

  & > .chosen {
    background-color: #000000;
  }
}

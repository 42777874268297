$animation-time: 0.09s;
$animation-method: cubic-bezier(0.4, 0.5, 0.38, 0.82);
$options-border-color: #2e3138;
$url-higlighted-color: #44b0c7;
$gray-font-color: #81878c;
$blue-button-hover-color-2: #202427;
am-input[type=dropdown]{
  min-width: 10em;
}


am-input[type=dropdown], am-container[type=drop] {
  //display: block;
  display: inline-block;
  cursor: pointer;
  position: relative;

  vertical-align: middle;
  clip: rect(0px, 10000px, 10000px, -10000px);
  @include user-select(none);

  i.icon-indicator {
    transition: transform $animation-time $animation-method;
    vert-align: sub;
  }

  &:focus {
    border: 1px solid #595f6a;
  }

  .selected-label {
    min-width: calc(100% - 3.6em);
    display: inline-block;
    background-color: transparent;
    border: none;
    @include user-select(text);
    padding-right : 5px;

    span {
      max-width: 26em;
      //max-width: 9em;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
    }
  }

  .input-selected {
    border: 1px solid #2e3138;
    overflow: hidden;
    text-align: left;
    background-color: #151719;
    z-index: 1001; /*above options when it open*/
    font-weight: normal;
  }

  .input-selected, .option {
    position: relative;
    padding: 0 0.5em;
    line-height: 40px;

    i {
      font-size: 15px;
      vertical-align: middle;
    }
  }

  i.icon, i.selected-icon {
    display: none;
  }

  i.edit-icon {
    float: right;
    margin: 1px 7px;
    &:hover {
      color: white;
    }
  }

  &.open {
    i.icon-indicator {
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }

  &:not(.open) {
    overflow: hidden;
    .input-selected {
      z-index: inherit;
    }
    .options {
      opacity: 0;
      z-index: -1;
      transform: translate(-50%, -5%);
    }
  }

  .options {
    transition: opacity, transform $animation-time $animation-method;
    display: block;
    position: absolute;
    top: 100%;
    margin-top: 10px;
    border: 1px solid $options-border-color;
    background: rgba(28, 32, 34, 0.95);

    padding-bottom: 0.3em;

    z-index: 1000;
    //max-height: calc( 40px * 10 ) ;
    min-width: 100%;
    /*center absolute horizonal*/
    left: 50%;
    transform: translate(-50%, 0);
    transform-origin: top center;

    /*triangles*/
    &:before, &:after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border: solid transparent;
      position: absolute;
    }
    &:before {
      border-width: 0 11px 11px 11px;
      border-bottom-color: #2e3138;
      top: -12px;
      left: calc(50% - 11px);
    }
    &:after {
      border-width: 0 10px 10px 10px;
      border-bottom-color: rgba(28, 32, 34, 0.95);
      top: -10px;
      left: calc(50% - 10px);
    }
  }

  &.open-up, &[open-up] {
    clip: rect(-10000px, 10000px, 42px, -10000px);

    .options {
      top: auto;
      bottom: 100%;
      margin-top: auto;
      margin-bottom: 11px;
      transform-origin: bottom center;

      &:before {
        border-width: 11px 11px 0 11px;
        border-top-color: #2e3138;
        bottom: -12px;
        left: calc(50% - 11px);
        top: auto;
      }
      &:after {
        border-width: 10px 10px 0 10px;
        border-top-color: rgba(28, 32, 34, 0.95);
        bottom: -10px;
        left: calc(50% - 10px);
        top: auto;
      }
    }

    &:not(.open) {
      .options {
        transform: translate(-50%, 50%);
      }
    }
  }



  /*white border*/
  &.white{
    *{font-size:14px;font-weight:700;}
  
    &.open-up, &.white[open-up] {
      
      .options {
        margin-bottom:13px;
        border-color:#fff;
        border-radius:6px;
        border-bottom-width:3px;
        background-color:rgba(28,32,34,.95);
        
        &:before{
          background-color: #282D33;
          border-top-color: #fff;
          border-width: 9px 11px 0 11px;
        }

        &:after{
          border-top-color:rgb(28,32,34);
        }
      }
    }
  }

  &.open-left-down, &[open-left-down] {
    &:not(.open) {
      .options {
        transform: translate(0, -5%);
      }
    }
    .options {
      left: 0;
      transform: translate(0, 0);

      &:before, &:after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border: solid transparent;
        position: absolute;
      }
      &:before {
        border-width: 11px 0 0 12px;
        border-left-color: #2e3138;
        top: -12px;
        left: -1px;
      }
      &:after {
        border-width: 10px 0 0 10px;
        border-left-color: rgba(28, 32, 34, 0.95);
        top: -10px;
        left: 0;
      }
    }
  }

  .option-overflow-warpper {
    position: relative;
    overflow-y: overlay;
    padding: 0.4em;
    max-height: calc(40px * 10);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .option {
    cursor: pointer;
    padding: 0 0 0 0.5em;
    white-space: nowrap;
    margin-right: 0.2em;

    &:hover {
      background-color: black;
    }

    &.selected {
      color: white;
      .selected-icon {
        display: inline;
        position: absolute;
        right: 3px;
        font-size: 15px;
      }
    }
  }

  .bottom {
    text-align: center;
    padding: 0 0.2em;
    button {
      border: 1px solid white;
      background: transparent;
      border-radius: 4px;
      color: white;
      line-height: 1.4em;
      cursor: pointer;
    }
    input {
      width: 100%;
    }
  }
}
@import "./pages/discovery/modal/modalEditTarget";

#refresh-link{position:absolute;right:29px;top:20px;}

.targets {

    margin-top: 14px;
    
    #targetlist{

    .icon-Export, .icon-maximize{
    display:none;
    }

    .crud i{font-size:13px;}


    .row .thead{
    line-height: 45px;

     .column{
       line-height: 40px;
     }
    }


    .search, .search.selected{
      font-size: 14px;
      font-family: Helvetica;
      font-weight: normal;
      color: $text-color !important;
    }

    .search input{
        border: 1px solid $text-color;
    }

     .column-title{
        color: #ffffff;
     }

     .table-options-col{
        font-size: 14px !important;
        width: 100%;
        display: inline-block;
        font-weight: normal !important;
     }

    .tbody{
         height: calc(100vh - 200px)!important;
    }

   

    }

    .crud{
        width:100px;
    }
}

#delete_target_dialog{
    margin-left: -150px;
    min-width: 300px;
    &.show{z-index:999 !important;}
}

.target-buttons, .grid-buttons{
  margin-right: 2px;
  .icon-new-segment{font-size:11px}

  .target-button{
    margin-right:16px;
    i{margin-left:4px;}
  }
} 

@import "infra/style/base_modal";

.modal-overlay.load-audience-modal {
  .modal {
    width: 560px;
    height: 527px;
    margin-top: 50vh;
    transform: translateY(-50%);

    .empty-audiences {
      font-size: 16px;
      font-weight: bold;
      margin: 40% auto 0;
      display: block;
      text-align: center;
    }

    .table-wrapper {
      .quick-table-wrap {
        height: 100%;
      }

      .thead {
        .scroll-td {
          flex: 0.155;
        }
      }

      .tbody {
        .row {
          .scroll-td {
            display: none;
          }

          .column {
            padding: 12px 6px;

            &.actions {
              padding: 9px 6px;
              text-align: center;

              .delete-btn {
                font-size: 15px;
                display: inline-block;
                padding: 1px 6px 5px 6px;
              }
            }
          }
        }
      }

      .row {
        margin-bottom: 0;
        cursor: pointer;

        input {
          margin-top: 0;
        }

        .column {
          &.audiencename {
            flex: 2.3;
          }
        }
      }
    }
  }
}

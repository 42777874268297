/**********/
/*  table */
/**********/
table.am-style {
  width: 100%;
  border: 1px solid #08090A;
  line-height: 2em;
  font-size: 13px;
  table-layout: fixed;
  height: 100%;

  thead {
    text-align: left;
    text-transform: capitalize;
    tr.disappear-row {
      border: none;
      padding: 0;
      margin: 0;
      line-height: 0;
      height: 0;
      font-size: 0;

    }
    tr {
      background-color: #1C2022;
      border-bottom: 2px solid #08090A;
      color: #81878c;
      font-weight: 700;
    }
    * {
      @include user-select(none);
      white-space: nowrap;
    }
  }

  .sort-indicator {
    visibility: hidden;
  }

  .sortable {
    cursor: pointer;
    @include user-select(none);
    display: table-row;
    .text {
      white-space: normal;
      display: table-cell;
    }
    .sort-indicator {
      display: table-cell;
      //position: absolute;
      //right: 0;
      //top: 50%;
      //transform: translateY(-50%);
      background: 0 0;
      /* width: 1em; */
      vertical-align: middle;
      border: none;
      padding-left: 5px;
    }
  }
  .selected-column.selected-column {
    width: 1em;
    border-right: none;
    cursor: pointer;
  }

  [selected-column][selected-column],
  [removed-column][removed-column] {
    color: #81878c;
    width: 1em;
    overflow: visible;
    @include user-select(none);

    border-right: none;
    cursor: pointer;

    .custom-icon {
      color: #81878c;
    }
  }

  .removed-column {
    i {
      font-size: 9px;
    }
  }

  td.scroller {
    padding: 0;
    overflow: hidden;
    & > .content {
      //min-height: 100%;
      //max-height: 40em;
      overflow: overlay;
      margin-left: -1px; /*fix some strange border in upper td*/
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      & ::-webkit-scrollbar-thumb {
        height: 30px;
      }
      & > .heightCatch {
        position: relative;
      }
      table {
        width: 100%;
        table-layout: fixed;
        position: absolute;
      }
    }
  }
  td, th {
    padding: 0 0.5em;
    position: relative;
    &:not(:last-child) {
      border-right: 1px solid #08090A;
    }
  }
  td.show-white-space {
    white-space: pre;
  }
  th {
    vertical-align: middle;
    line-height: 14px;
    font-size: 13px;
    padding-top: .1em;
    padding-bottom: 0.3em;
    .sorted {
      color: white;
      .sort-indicator {
        visibility: visible;
      }
    }
    .sortable {
      cursor: pointer;
    }
    i {
      text-shadow: 0 0 2px #080000;
    }
  }

  tbody {
    color: #cacaca;
    tr {
      //border-bottom:1px solid #08090A;
      &:nth-child(odd) {
        background: #202427;
      }

      &:nth-child(even) {
        background: #1B1F21;
      }
    }

    table.tableContent {
      tr:hover{
        background-color: $table-content-hover;
        }
    }
  }
  tfoot {
    td {
      background-color: black;
      height: 4em;
    }
  }

  /*.setting{
    position: absolute;
    text-align: left;
    z-index: 1;
    background-color: #202427;
    border: 1px solid;
    padding: 0.3em;
    .selected{
      color: white;
    }
  }*/

  &.hide-col-2 td:nth-child(2) {
    display: none;
  }
  &.hide-col-3 td:nth-child(3) {
    display: none;
  }
  &.hide-col-4 td:nth-child(4) {
    display: none;
  }
  &.hide-col-5 td:nth-child(5) {
    display: none;
  }
  &.hide-col-6 td:nth-child(6) {
    display: none;
  }
  &.hide-col-7 td:nth-child(7) {
    display: none;
  }
  &.hide-col-8 td:nth-child(8) {
    display: none;
  }
  &.hide-col-9 td:nth-child(9) {
    display: none;
  }
  &.hide-col-10 td:nth-child(10) {
    display: none;
  }
  &.hide-col-2 th:nth-child(2) {
    display: none;
  }
  &.hide-col-3 th:nth-child(3) {
    display: none;
  }
  &.hide-col-4 th:nth-child(4) {
    display: none;
  }
  &.hide-col-5 th:nth-child(5) {
    display: none;
  }
  &.hide-col-6 th:nth-child(6) {
    display: none;
  }
  &.hide-col-7 th:nth-child(7) {
    display: none;
  }
  &.hide-col-8 th:nth-child(8) {
    display: none;
  }
  &.hide-col-9 th:nth-child(9) {
    display: none;
  }
  &.hide-col-10 th:nth-child(10) {
    display: none;
  }

  /*setting*/
  /*setting*/
  am-container[type=drop] {
    * {
      @include user-select(none);
    }

    .options {
      padding: 0 22px;
      margin-top: 4px;
      margin-left: 7px;
      min-width: 14em;
    }
    .option {
      display: block;
      text-align: left;

    }
    am-input[type=checked] {
      i {
        position: absolute;
        right: -15px;
      }
    }
  }
}

@import "infra/style/base";

section.insights-page {
  .channels-panel {
    height: 100%;

    &.outer-scroll {
      background-image: $application-background;
      overflow-y: auto;
      overflow-x: hidden;

      .referrals-widget-panel {
        height: initial;
        position: relative;
        overflow: hidden;
        min-height: calc(100% - 270px);
      }
    }

    .widget-panel {
      border: 1px solid #000;
      width: 100%;
      box-shadow: inset 0 2px 1px -2px #DDDFD0;
    }

    .channels-widget-panel {
      position: relative;
      height: 260px;
      margin-bottom: 10px;
    }
  }
}

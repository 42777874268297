am-bar-chart {

  display: inline-block;
  margin: 0 20px 0 0;
  width: 200px;

  svg {
    display: block;
    height: auto;
  }

  .label-panel {
    .caption {
      margin: auto;
      display: block;
      text-align: center;
      //font-family: Helvetica, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-variant: normal;
      //font-weight: 400;
      line-height: 20px;
      white-space: normal;
      color: #ffffff;
    }
  }

  .nv-noData {
    display: none;
  }

  .nvd3 .nv-discretebar .nv-groups text {
    fill: $click-color; // $regular-color;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    //font-weight: normal;

  }
  .nvd3 .nv-discretebar .nv-groups .hover text {
    fill: #ffffff;
  }

  .nvd3 .nv-discretebar .nv-groups rect {
    fill-opacity: 1;
  }

  .nv-y.nv-axis > .nv-zeroLine > line {
    fill: none;
    stroke: #2e3236;
    shape-rendering: crispEdges;
    stroke-width: 1px;
  }
    .nvtooltip {
      top: 20px !important;
      padding: 5px;
      margin-left: 225px;
      border: 1px solid #151719;
      transition: opacity 500ms linear;
      transition-delay: 500ms;
      box-shadow: 4px 4px 12px rgba(0,0,0,.5);
      background-color: $regular-color;
      color: #202427;
      opacity:0.9;
      font-size: 14px;
      border-radius: 4px;
      span {
        white-space: normal;
        max-width: 300px;
      }
    }
}

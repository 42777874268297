@import "infra/style/base_modal";

#bulk-user-settings-overlay {
  #bulk-user-settings-modal {
    height: 86%;
    width: 1125px;
    margin-top: 9vh;

    form[name=editBulkUserForm] {
      height: 95%;
    }

    #bulk-user-settings {
      height: calc(100% - 60px);
      overflow-y: scroll;
      position: relative;

      .row {

        input {
          width: 100%;
        }

        .select-style {
          margin-left: 101px;
          am-dropdown-select {
            display: inline;
          }
        }

        &.geo-channel-row {
          margin-bottom: 40px;
          background-color: $modal-background;

          .geo-label {
            position: relative;
            color: #fff;
            left: 1px;
          }


        }

        &.permissions-row {
          margin-bottom: 5px;
          margin-top: 25px;
          height: auto;
          overflow: auto;
          padding-right: 10px;
        }

        &.permissions {
          height: 45px;

          .switch-button {
            margin-top: 5px;
            float: right;
          }
        }
      }
    }

    .control-buttons {
      .save-loader {
        margin-left: 15px;
        display: inline-block;
        width: 60px;
        height: 30px;
        cursor: default;
        float: right;
      }

      .buttons-wrap {
        float: right;
      }
    }
  }
}


@import "infra/style/base_modal";

#new-version-overlay{
  #new-version-modal{
    width: 490px;
    #new-version-body{
      .title-wrapper {
        margin-bottom: 20px;
        #new-version-title {
          display: inline-block;
          margin-bottom: 0;
        }
        i {
          vertical-align: baseline;
          color: $title-color;
          font-size: 18px;
          margin-right: 5px;
        }
      }

      .row {
        margin-bottom: 15px;
      }
    }
  }
}

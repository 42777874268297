$selected-input-background-color: #22242a;
$selected-input-border-color: #21242a;

am-date-picker{
  display: inline-block;
  line-height: initial;
  &.selected{
    .input-date{
      color: #ffffff;
      background-color: $selected-input-background-color;
      box-shadow: inset 0 2px 0 0 #000000;
      border: 1px solid $selected-input-border-color;
      border-radius: 2px;
    }
  }

  .input-date{
    border-radius: 4px;
    color: rgb(107, 112, 125);
    @extend .base_bold_font;
    font-size: 12.5px;
    background-color: transparent;
    border:1px solid #000;
    line-height: initial;
    display: inline-block;
    padding: 0.3em 0.5em;
    cursor: pointer;
    width:100%;
    &.time-picker-mode{
      width: 12em;
    }
    &.date-picker-open {
      font-weight: bold;
      color: #ffffff;
      background-color: $selected-input-background-color;
      box-shadow: inset 0 2px 0 0 #000000;
      border: 1px solid $selected-input-border-color;
      border-radius: 2px;
    }
    &:hover{
      color: #ffffff;
    }
    i{
      float: left;
      margin-right: 0.4em;
    }
    input{
//      background: transparent;
//      border: none;
//      margin: 0;
//      padding: 0;
//      float: left;
//      width: 6em;
//      &:focus {
//        border: none;
//        color: white;
//        outline: 0;
//    }
      position: absolute;
      top:-10000px;
    }
  }

  .calender{
    display: block;
    border: 1px solid #ffffff;
    border-bottom-width: 3px;
    border-radius: 4px;
    background-color: #202527;
    padding: 19px 11px 13px 18px;
    margin-top: 12px;
    margin-left: -3em;
    position: absolute;
    z-index: 201;
    &:before,&:after{
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      position: absolute;
    }
    &:before{
      border-width: 0 11px 11px 11px;
      border-bottom-color: #ffffff;
      top:-12px;
      left: 6em;
    }
    &:after{
      border-width: 0 10px 10px 10px;
      border-bottom-color:rgb(28, 32, 34);
      top: -10px;
      left:calc(6em + 1px);
    }

    .row-1{
      color: #fff;
      font-size: 13px;
      top:-11px
    }
    .button-prev,.button-next{
      cursor: pointer;
    }
    .button-prev{
      float: left;
    }
    .button-next{
      float: right;
    }

    hr{
      margin: .5em -0.4em;
      clear: left;
    }
    .day-range{
      font-size: 14px;
      width: 14em;/*two digits every column*/
//      overflow: hidden;
      &:after,&:before{
        content: "";
        display: block;
        clear: both;
      }
    }

    .currentYear{
      text-align: center;
      text-transform: capitalize;
      //font-family: Helvetica, sans-serif;
      font-size: 13px;
     @extend .base_bold_font;
      //font-weight: bold;
      color: white;
    }
    [class^=row]{
      line-height: 1em;
    }
    [class^=button]{
      background-color: transparent;
      outline: none;
      border: none;
    }
    .day{
      display: inline-block;
      font-size: 14px;
      width: 28px;
      padding: 0.2em 0.2em;
      //font-weight: normal;
      @extend .base_bold_font;
      float: left;
      line-height: 1.5;
      line-height: 1.5;

      &.overflow{
        color: #363a3d; /*disable color*/
      }
      &.inRange{
        color:white;
      }
      &.today{
        text-decoration: underline;
      }
    }
//    .day:nth-child(7n+1){
//      clear: left;
//    }
    .day-title{
      text-transform: capitalize;
      font-weight: normal;
      font-size: 13px;
      color: #cacaca;
      margin-bottom: 4px;
    }
    .day-month-before{
      color:#81878c;
      cursor: pointer;
    }
    .day-month{
      color: #cacaca;
      cursor: pointer;
    }
    .day-month-after{
      color:#81878c;
      cursor: pointer;
    }

    .time-picker-input{
      width: auto;
      min-width : auto;
    }

    &.time-picker-mode {
      padding-bottom: 20px;
    }

  }

  /*default mode is not datePicker*/
  .time-picker-area,
  .input-time-text  {
    display: none;
  }

}

.time-picker-mode{
  .input-time-text{
    display: initial;
  }
   .time-picker-area{
     display: initial;
     .time-label{
       color: white;
     }

     .am-style{
       &.ng-dirty{
         border: inherit;
       }
     }

   }

}
am-timeframe-filter{

    margin-right: 6px;

  &:hover~ #warroom-apply{color:white !important;}

  .buttons-row{
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #000;
    line-height: initial;
    border-radius: 4px;

    .narrow-mode-timeframe {
      height: 24px;
      // width: 80px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      span {
        line-height: 24px;
        padding-left: 6px;
        padding-right: 6px
      }
    }
    .options {
        border: 1px solid $border-color;
        border-bottom: 3px solid $border-color;
        border-radius: $border-radius;
        background-color: #292c33;
        position: absolute;
        top: 90%;
        right: 23%;
        z-index: 3;
        min-width: 50px;
        margin-top: 10px;

        &.ng-hide {
          transform: translate(-50%, -5%);
          opacity: 0;
        }

        &:before, &:after {
          display: block;
          content: '';
          border: solid transparent;
          position: absolute;
        }
        &:before {
          border-width: 0 11px 11px 11px;
          border-bottom-color: $border-color;
          top: -12px;
          left: calc(50% - 11px);
        }
        &:after {
          border-width: 0 10px 10px 10px;
          border-bottom-color: $header-footer-bg-color;
          top: -10px;
          left: calc(50% - 10px);
        }

        ::-webkit-input-placeholder {
          color: $text-color;
        }

        .options-wrapper {
          padding: 0 5px 1px;

          .option {
            cursor: pointer;
            padding-right: 15px;
            padding-left: 5px;
            color: $text-color;
            white-space: nowrap;
            line-height: 40px;
            font-family: "Helvetica Bold";
            font-size: 14px;
            position: relative;

            &:last-of-type {
              border-bottom: none;
            }

            .selected-icon {
              display: none;
              position: absolute;
              right: 0;
              font-size: 14px;
            }

            &:hover {
              color: $highlight-color;
            }

            &.chosenTime {
              color: $highlight-color;

              .selected-icon {
                display: inline;
              }
            }
          }
        }
      }

    .tab-button {
      display: inline-block;
      cursor: pointer;
      padding: 4px 10px;
      border-right: 1px solid #000;
      border-left: 0;
      line-height: initial;
      font-style: normal;
      @extend .base_bold_font;
      font-size: 13px;
      &.tab-button:last-child{
        border-right: none;
      }
    }
  }

  .buttons-row.has-times {
    margin-left: 10px;
  }
  .buttons-row-element.chosenTime, .buttons-row-element > .chosenTime {
    color: #ffffff;
    box-shadow: inset 0 2px 0 0 #000000;
    border-width: 1px 0 1px 0;
    border-color: #21242a;
    background-color: #21242a;
  }
  .buttons-row-element {
    .first {
      border-radius: 4px 0 0 4px;
    }
  }
  .last {
    border-radius: 0 4px 4px 0;
  }
  .chosenFirst{
    border-left: 0 solid;
  }
  input {
    top: 22%;
    position: relative;
  }
  .semi-icon-dash{
    border-top: 2px solid;
    width: 10px;
    display: inline-block;
    margin-bottom: 3px;
  }
  am-date-picker.selected{
    label{
      color: #ffffff;
    }
  }

}

alert-dsp-component {
  am-autocomplete {
    .autocomplete-input-wrapper {
      display: block;
      width: 50%;

      .autocomplete-options-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;

        .autocomplete-option {
          height: 35px;
          line-height: 35px;
          padding: 0px 15px !important;

          span {
           display: inline-block;
           text-overflow:ellipsis;
           overflow: hidden;
           white-space: nowrap;
          }

          .markLetters{
             font-weight: 600!important;
             display:inline;
             color:#fff!important;
          }
        }
      }

      .autocomplete-options-container {
        width: 100%;
      }

      .autocomplete-options-dropdown {
        max-height: calc(35px * 10);
        width: 100%;
      }

      .icon-Search, .icon-X {
        top: 40%;
      }

      div[id$='noMatchMsg'] {
        color: $textbox-text-error;
      }
    }
  }

  .line-items-input {
    width: 50%;
    display: block;
    outline: 0;
    padding: 10px;
    resize: none;
  }

  .row:focus-within {
    .required-error {
      display: none !important;
    }
  }

  .line-items-input[disabled]::-webkit-input-placeholder {
    color: $disabled;
  }
}

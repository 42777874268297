@import "/bower_components/nvd3/nv.d3.css";
@import "/bower_components/qtip2/jquery.qtip.css";
@import "../../common/style/flexbox";
@import "infra/style/base";
@import "widgets/base_widget";
@import "common/charts/trend-chart";
@import "common/bar-chart.drv/bar-chart";
@import "common/charts/vertical-bar-chart";

topic-trends-widget {

  @include flex_default_layout();
  @include flex_column();
  @include flex_item_align(stretch);

  .message-frame {
    width: 100%;
    height: 50%;
    margin: 15% auto auto auto;
    .message-line {
      width: 100%;
      height: 14px;
      line-height: 20px;
      text-align: center;
      vertical-align: middle;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      color: $regular-color;
    }
  }

  .filter-selector {
    @include display_flex();
    @include flex_direction(row);
    position: relative;
    align-items: flex-start;
    margin: 5px 6px 6px 0;
    font-family: Helvetica, sans-serif;
    color: $click-color;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    .control-button {
      background-color: #202427;
      border: 1px solid black;
      padding: 5px 10px 5px 10px;
      cursor: pointer;
      &:not(.first) {
        border-left: 0;
      }
      &.first {
        border-radius: 4px 0 0 4px;
        -webkit-border-radius: 4px 0 0 4px;
      }
      &.last {
        border-radius: 0 4px 4px 0;
        -webkit-border-radius: 0 4px 4px 0;
      }
    }
    .control-button:hover {
      color: $selected-color;
    }
    .selected-control {
      background-color: $selection-background;
      padding: 5px 10px 5px 10px;
      cursor: default;
      color: $selected-color;
      @extend .base_bold_font;
    }
    &.extended {
      margin-top: 15px;
    }
    &.extra-extended {
      margin-top: 25px;
    }
  }

  .topics-graph-container {
    overflow-y: scroll;
    margin-top: 20px;
    border-top: 1px solid black;

    &.graph-shown{
      width: 350px;

      .cluster-content {
        display: none;
      }
    }

    .chart-container {
      margin-left: 0;
    }
  }

  .topics-trend-legend {

    margin: 7px 21px 12px 13px;
    @include display_flex();
    @include flex_direction(row-reverse);
    justify-content: space-between;

    &.trends-row-2 {
      margin-top: -5px;
    }
    &.trends-row-3 {
      margin-top: 5px;
    }

    .legend-trends {
      @include display_flex();
      justify-content: flex-end;

      .legend-trend {

        @include display_flex();

        .legend-icon {
          margin: 8px 0 0 10px;
          float: left;
          height: 10px;
          width: 10px;
          vertical-align: middle;
        }

        .legend-title {
          margin: 7px 8px 0 5px;
          float: left;
          font-family: Helvetica, sans-serif;
          font-weight: 300;
          color: $click-color;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          font-variant: normal;
          line-height: 14px;
          vertical-align: middle;
        }
      }
    }

    .chart-header {
      position: absolute;
      left: 384px;
      top: 1.5em;
      color: white;
      font-family: "Helvetica Bold", sans-serif;
      font-size: 14px;

      .sub-topic-triangle {
        font-size: 11px;
      }

      .sub-topic-triangle, .sub-topic-text {
        color: $click-color;
      }
    }
  }

  .consumption-chart-container{
    position: absolute;
    top: 1px;
    left: 352px;
    right: 0;
    width: calc(100% - 352px);
    border-left: .12em solid black;
    height: calc(100% - 2px);
    z-index: 1;
    display: flex;
    align-items: stretch;

    .close{
      position: absolute;
      right: 10px;
      top: 15px;
      cursor: pointer;
      font-size: 10px;
      font-weight: bold;
      z-index: 5;

      &:hover i{
        color:#ffffff;
      }
    }

    .charts-frame {

      @include display_flex();
      @include flex_direction(row);
      flex: 1 1 auto;
      position: relative;
      height: 100%;
      width: calc(100% - 26px);
      margin: 0;

      vertical-bar-chart {
        height: calc(100% - 60px);
        margin: 85px 16px -24px 0px;
      }

      trend-chart {
        @include display_flex();
        @include flex_direction(column);
        flex: 1 1 auto;
        position: relative;
        align-items: stretch;
        height: calc(100% - 60px);
        margin: 40px 0 0 10px;
      }

      am-bar-chart {

        height: calc(100% - 20px);
        margin: 10px 0;

        svg {
          margin-top: 27px;
        }

        .caption {
          font-size: 13px;
          font-style: normal;
          font-variant: normal;
          margin-top: -7px;
        }
      }
    }
  }
}

@import "infra/style/base_modal";


#domain-dialog-overlay {
  #domain-dialog-modal {
    width: 520px;

    #reason{
      width: 100%;
    }

    #domain-dialog {

      .error-messages {
        margin-top: 4px;
        margin-left: 5px;
      }

      input-bar {
        padding: 10px;
        background: $textbox-bg;
        width: 100%;
        margin-top: 15px;
        min-height: 44px;

        .button-remove-tag {
          font-family: "Helvetica Bold", sans-serif;
        }

        .clear-all {
          display: none;
        }

        .icon-Search, .demi-icon-pipe {
          display: none;
        }

        label {
          padding: 6px 10px 10px 2px;

          .tags-input {
            padding-left: 10px;
            height: 100%;
            padding-right: 10px;

            .input {
              height: 100%;
              font-size: 14px;
              border: none;
              background: 0 0;
              padding-left: 0;
              margin-top: 0;
            }
          }

          tags {
            tag {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

am-heatmap-chart {

  width: 100%;
  display: inline-block;

  rect.bordered:hover {
    stroke: #FFFFFF;
    stroke-width: 1px;
  }

  rect.heat-map {
    transition: fill 0.5s ease;
  }

  text.mono {
    font-size: 14px;
    font-family: Helvetica;
    font-weight: normal;
    fill: #cacaca;
  }

  text.boldLabel {
    font-weight: bold;
    fill: #fff;
  }
}
@import "../../base_widget";
@import "infra/style/base_modal";

#exclusions-dialog-overlay {
  #exclusions-dialog-modal {
    width: 400px;
    height: 423px;

    #search_div {
      width: 100%;
      text-align: right;
      position: relative;
      padding-bottom: 5px;

      #search_text {
        width: 100%;
      }
    }

    #deleted_concepts_box {
      width: 100%;
      overflow: hidden;
      height: 212px;

      #deleted_concepts_header {
        font-size: 14px;
        font-weight: bold;
        border-bottom: 1px solid $separator-color;
        color: $highlight-color;
        padding-left: 15px;
        padding-bottom: 8px;
        margin-bottom: 5px;

        #sort_seed, #sort_concept {
          display: inline-block;
          cursor: pointer;

          .sortorder {
            font-size: 13px;
            margin-left: 5px;

            &.reverse:after {
              content: '\25b2';
            }

            &:after {
              content: '\25bc';
            }
          }
        }

        #sort_seed {
          width: 75px;
          margin-right: 60px;
        }
      }

      #deleted_concepts_rows {
        padding: 5px 10px;
        max-height: calc(100% - 30px);
        overflow-y: scroll;

        .deleted_concepts_row {
          display: block;
          width: 100%;
          padding: 5px;
          cursor: pointer;

          &:hover {
            background-color: $list-item-bg-hover;
          }

          .seed, .concept {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
          }

          .seed {
            width: 130px;
          }

          .concept {
            width: 135px;
            padding-left: 5px;
          }
        }
      }

      .checkbox-wrapper {
        display: inline-block;

        .checkbox {
          width: 30px;
        }
      }
    }
  }
}

// Style guide constants

// Text //
// Selected and titles
$selected-color: white;
$title-color: white;
// Main text
$regular-color: #cacaca;
// Click color
$click-color: #81878C;
// Disabled color
$disabled: #495055;/*#363a3d;*/
$disabled-font: #2a2d33;
$disabled-background: #343841;
$disabled-row: #4C5155;

// Text Box //
$border-color: #2e3138;
$textbox-bg: #151719;
$border-color-focus: #595f6a;
$border-color-error: #d73939;
$textbox-text-error: #d73939;
$tabs-background: #202427;

// Btn's //
$main-btn-color: white;
$main-btn-bg: transparent;
$main-btn-color-hover: #1c2022;
$main-btn-bg-hover: white;

$second-btn: #81878c;
$second-btn-hover: white;
$second-btn-selected: white;

// Graph
$graph-axis: #2e3236;

// Modal
$modal-background: #22252b;
$modal-border: white;

// table
$table-content-hover: #2e3337;

.base_bold_font {
  font-family: "Helvetica Bold", sans-serif;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.3px;
}

.error-messages {
  .error {
    color: $textbox-text-error;
    @extend .base_bold_font;
  }
}

input.ng-invalid.ng-dirty {
  border: 1px solid $border-color-error;
}

input.ng-invalid.ng-dirty:focus {
  border: 1px solid $border-color-error;
}

.left-round-border {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.right-round-border {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// transform long text into long tex...
.cut-text {
  // u should set width on your <div> ---
  width: 100px;
}

// remove autofill / webkit style
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px black inset;
  -webkit-text-fill-color: white;
}

input:-webkit-autofill:focus {
}

/*ui-view*/

/*input*/
input[type='search'] {
  border: 1px solid black;
  color: #888;
  height: 18px;
  width: 300px;
  padding: 10px;
  background-color: black;
}

input {
  outline: none;
  &:focus {
    color: white;
    border: 1px solid #595f6a;
  }
}

// End remove autofill / webkit style

/**
  INPUT
  SELECT
**/
input[type=text].am-style, input:not([type]).am-style, select.am-style {
  background-color: #000;
  color: #cacaca;
  border: none;
  padding: 5px;
  height: 35px;
  outline: 0;
  font-family: 'Helvetica';
}

/************
  SELECT
************/
select {
  background-color: black;
  font-family: 'Helvetica';
  cursor: pointer;
}

/**
  am-input
**/
input[type=text].am-style, input:not([type]).am-style, select.am-style, am-input.am-style {
  &:focus {
    border: 1px solid #595f6a;
  }
  &.ng-dirty {
    border: 1px solid red;
  }
  &:focus.ng-dirty {
    //border:1px solid yellow;
    border: 1px solid #595f6a;
  }
  &[disabled] {
    cursor: default;
    color: #363a3d;
  }
}

/************
  BUTTON
**********/
button {
  &:active, &:focus {
    outline: none;
  }
}

button.main-button {
  font-family: "helvetica";
  color: #fff;
  border-radius: 4px;
  background-color: transparent;
  height: 30px;
  border: 1px solid #fff;
  padding: 6px 10px;
  cursor: pointer;

  &:hover {
    color: #1c2022;
    background-color: #fff;
  }
  &:disabled {
    color: $disabled;
    border-color: $disabled;
    cursor:auto;
  }

  &.blue{
    font: 14px Helvetica bold;
    font-weight:400 !important;
    color: white;
    border-color: $amobee-blue;
    background-color: $amobee-blue;
    transition: .5s color;
    &:hover{
      color: black;
    }
    &:disabled {
      color: #2a2d33; /*#30343c;*/
      background-color: $disabled;
      border-color: $disabled;
    }
  }
}

/**************
  NAVIGATION
**************/
.navigation.horizonal {
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding: 12px 0 12px 0;
  line-height: 31px;
  text-decoration: none;
  color: inherit;

  nav-button, .nav-button {
    padding: 0 10px 0 10px;
    margin: 0 5px 0 5px;
    cursor: pointer;
    z-index: 10;
    text-decoration: none;
    color: inherit;
    &:hover {
      color: white;
    }

    & > a {
      display: block;
      width: 100%;
      overflow: hidden;
    }
  }

  .selected {
    color: white;
    background-color: #151719;
    border: 1px solid #000;
    border-top: 2px solid #000;
    border-radius: 4px;
    @extend .base_bold_font;
    outline: 0;
  }
}

/*defult custom scrollbar*/
/* Let's get this party started */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-color: rgba(66, 66, 66, .90);
  background-clip: padding-box;
  border-radius: 18px;
  min-height: 30px;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(90, 90, 90, .90);
}

.white-round-border {
  border: 1px solid $modal-border;
  border-bottom: 2px solid $modal-border;
  border-radius: 5px;
}

/**********************
  Modal Window
**********************/
$border-color: #2e3138;
$best-ratio: 1.6180339;
$modal-height: 300px;

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  .modal-close-button{cursor:pointer;}
}

.modal {
  position: relative;
  margin: 30vh auto 0;
  border: 1px solid $modal-border;
  border-bottom: 2px solid $modal-border;
  flex: 1;

  background-color: $modal-background;
  z-index: 102;

  width: calc(#{$best-ratio} * #{$modal-height});
  padding: 2em;
  color: $text-color;
  font: 14px Helvetica;
  text-align: left;

  .modal-close-button {
    position: absolute;
    top: 1em;
    right: 1em;
    color: #fff;
    font-size: 12px;
  }
  .modal-top-bar {
    margin-bottom: 1em;
    border-bottom: 1px solid $border-color;
  }

  .modal-body {
    height: calc(100% - 1em);
  }

  .modal-top-bar ~ .modal-body {
    height: calc(100% - 2em);
  }

  .modal-top-bar ~ .modal-body.width-modal-bottom-bar {
    height: calc(100% - 6em);
  }

  .modal-bottom-bar {
    &::after {
      display: block;
      content: '';
    }
  }
  .modal-title {
    font: 300 18px "Helvetica Bold";
    color: $title-color;
  }
}

.modal-fadein {
}

//vars
$content-driven : 360px;

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

age-gender-chart {
  color: $regular-color;

  .age-gender-chart-drv {
    height: 100%;
  }

  .container {
    height: calc(100% - 29px);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
  }

  .row {
    position: relative;
    width: 100%;
    height: calc(100% * 0.06);
    min-height: 20px;
    max-height: 30px;
  }

  .icons-header {
    position: relative;
    width: 100%;
    height: 31px;
    font-size: 23px;
  }

  i {
    position: absolute;

    &.icon-audience-male-2 {
      right: calc(50% + 49px);
    }

    &.icon-audience-female-2 {
      left: calc(50% + 49px);
    }
  }

  .value, .bar, .age {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .male-highlight {
    .male.value, .age {
      color: white
    }
    .male.value {
      font: 22px Helvetica Bold;
    }
    .male.bar {
      background: $amobee-blue;
    }
  }

  .female-highlight {
    .female.value, .age {
      color: white
    }
    .female.value {
      font: 22px Helvetica Bold;
    }
    .female.bar {
      background: $amobee-blue;
    }
  }

  .age {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    text-align: center;
  }

  .bar {
    background: $regular-color;
    max-height: 20px;
    height: 67%;
    min-height: 10px;
    min-width: 1px;
  }

  .male {
    right: calc(50% + 50px);
  }

  .female {
    left: calc(50% + 50px);
  }

  .index-line {
    height: 100%;
    position: absolute;

    &.male {
      border-left: 1px solid #444;
    }

    &.female {
      border-right: 1px solid #444;
    }
  }
}


$warroom-filter-width: 236px;

@import "infra/style/base";
@import "infra/style/style_guide";
@import "infra/style/components";
@import "./pages/warroom-groups/warroom-groups";
@import "./pages/programs/program-theme/program-theme";
@import "./pages/warroom-trending/warroom-trending";
@import "./pages/warroom-realtime/warroom-realtime";



warroom-widget {
	display:block;
	height:100%;
	background: #343841;

	div[ui-view]{overflow-x:hidden;}

	.title{font: 14px helvetica bold}

	.pause_play{
		position:absolute;
		top: calc(#{$theme-height} + 8px);
		left:10px;
	}

	.excel{
		display:inline-block;
		a{padding:0px;}
	}

	.img_16{width:16px;height:16px;}
	.img_12{width:12px;height:12px;}
	.img_8{width:8px;height:8px;}

	.widget-panel{
		background-color: #202427;
		font:14px Helvetica; color:#cacaca;
		border: 1px solid #000;
		box-shadow: inset 0 2px 1px -2px #DDDFD0;

		.title{padding:10px 5px 15px 10px; /*just above table*/
			color: #fff; text-transform: capitalize;font: 13px helvetica bold;
			overflow:hidden;text-overflow:ellipsis;white-space: nowrap;cursor:pointer;}

		.loading-img{margin-left: -45px;}
	}

	.tabs{
    	margin: auto;
    	height:45px;
    	padding-left:100px;
	}

	#geo_wrap {
		position: absolute;
		top: 18px;
		right: 20px;
		select{line-height:23px !important;}
	}

	input[type='text'], textarea, select{
  		color: #fff;
	    background-color: #000;
	    padding: 2px 12px 2px 2px;
	    font-size: 14px;
	}

	input[type='submit'] , button, input[type='button']{
	    background-color: transparent;
	    border-radius: 4px;
	    border: 1px solid white;
	    padding: 0 14px 0 14px;
	    cursor: pointer;
	    height:30px;
	    &:active,&:focus{
	      outline: none;
	    }

	    &:hover{
	      background-color: white;
	      color: #1c2022;
	    }
	    &:active {
	      color: $click-color;
	    }
	    &:disabled { 
	      color: #363a3d;
	      cursor: default;
	      border: 1px solid #363a3d;
	      &:hover {
	        background-color: transparent;
	    }
	  }
	}

	.cancel{color:$click-color;
	  &:hover { color: $selected-color; }
	}


	select{
		appearance: none; -moz-appearance: none; -webkit-appearance: none;
		background-image: url("../images/images/dropdown_arrow.png");
	    background-repeat: no-repeat;
	    background-position: right center;
	    background-color:black;cursor:pointer;
	    padding: 2px 12px 2px 2px;
	    font-size: 14px;

	    &.disabled{
			background: transparent; 
		}
	}


}

.warroom.input-selected{ /*just a dropdown without the directive*/
	display: inline-block;
	border-radius: 2px;
	border: 1px solid #21242A;
	font-family: helvetica bold;
	color: $click-color;
	background-color:#343841;
	cursor:pointer;
	padding: 2px 18px 2px 8px;
	text-transform: capitalize;

	&.disabled{color:$disabled}
}

quick-table{
	
	li{list-style-type:none;}
	.column li{
		&:not(.first){padding-top: 8px;height: 23px;}
	}

	.thead {box-shadow:inset 0 2px 1px -2px #DDDFD0;}

	.column.group li{
		padding-left:25px;
		&.first{display:inline-block;padding-left:3px;}
	}

	.plus, .minus{margin-left:8px;}
	.icon-plus{font-size:10px;}

	a.selected{
		padding:1px 3px 1px 3px;
	    font-weight: 700;
	    border-radius: 3px;
	    background-color: #44b0c7 !important;
	    color: #fff !important;
    }
}


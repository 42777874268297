@import "../../infra/style/base";
@import "../../infra/style/style_guide";

$brick-width: 215px;
$gradient-start-trans: 1;

tv-show-info {

    .bar-container {
        width: 130px;
        margin-left: 7px;
        background: #444;
        height: 15px;
        float: left;
        position: relative;

        .bar {
            background: #cacaca;
            height: 100%;
        }
    }

    height: 327px;
    .no-avail-image {
        z-index: 1; // to remove the gradient
    }

    .nowrap {
        white-space: nowrap;
    }

    .brick-bottom {
        background-color: $widget-light;
        cursor: default;
    }


    .score {
        color: #353c43;
    }

    &:hover {
        .score {
        color: #f8f673;
        }
    }

    .social-bar {
        &.smaller-font {
            font-size: 10px;
            line-height: 15px;
        }
        padding-top: 10px;
        background-color: #525c64;
        font-size: 12px;

        .align-right {
            float: right;
            padding-right: 5px;
        }
    }

    @mixin gradient($start-after) {
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop($start-after, rgba(0, 0, 0, $gradient-start-trans)), color-stop(100%, rgba(0, 0, 0, 0))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* W3C */
    }

    .gradient {
        @include gradient(50%);
    }

    border-radius: 4px;

    box-sizing: border-box;

    width: $brick-width;
    max-width: $brick-width;

    box-shadow: 0 0 0 2px #525c64;

    font-family: Helvetica;
    color: white;


    .on-image-info {
        position: absolute;
        bottom: 7px;
        left: 9px;
        z-index: 11;
        max-width: $brick-width - 15px;
        height: 20px;
        font-size: 16px;
        font-family: Helvetica Bold;
    }


    .left-icon {
        padding-left: 10px;
        float: left;
    }

    .align-left {
        float: left;
    }
    .separator {
        &::after {
            content: "|";
            padding-left: 3px;
            padding-right: 3px;
        }
    }

    .right-icon {
        float: right;
        padding-right: 5px;
    }

    .bottom-padding {
        padding-bottom: 8px;
        clear: both;
    }


    .rollover-container {
        position: relative;

        &:hover {
            .rollover {
                visibility: visible;
            }
        }

        .rollover {
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.85);
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            z-index: 10;
            word-break: break-word;


            .desc-and-terms {
                height: calc(100% - 30px);

                .board-name-container {
                    font-size: 12px;
                    color: $click-color;
                    padding: 5px 10px;
                }
            }

            .desc-container-no-terms {
                max-height: 100% !important;
            }

            .desc-container {
                max-height: calc(100% - 30px);

                .desc {
                    width: calc(100% - 8px);
                    height: 100%;
                    color: $regular-color;
                    text-shadow: 1px 1px black;
                    padding-left: 8px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 12px;

                    span {
                        vertical-align: middle;
                    }
                }

                &.no-desc {
                    .desc{
                        padding-bottom: 0px;
                    }
                }
            }
        }
    }

    .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .meta-data-container {
        padding-left: 8px;
        font-size: 12px;

        .meta {
          float: left;
          clear: left;
          padding-right: 5px;
          color: #cacaca ;
        }

        .meta-val {
            float: left;
            @extend .ellipsis;
            &.network {
                width: 150px;
            }
            &.genre {
                width: 160px;
            }
        }
    }

    img {
        width: $brick-width;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        position: relative;
        z-index: -1;
        display: block;
    }

    .image {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px
    }

    .rollover-container {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }
}

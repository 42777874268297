@import "infra/style/base_modal";

#reset-password-overlay {
  z-index: 201;

  #reset-password-modal {
    z-index: 202;
    width: 330px;
    height: 334px;
    margin-top: 21vh;

    #reset-password {
      height: 183px;

      input {
        width: 275px;
      }

      .error-messages {
        display: inline-block;
        margin-left: 5px;
        width: 190px;
      }
    }
  }
}

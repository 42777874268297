@import "../../infra/style/base";
@import '../style/bordered-button';

.sentiments-popup {
    padding: 5px;
    text-align: center;
    min-width: 170px;
    max-width: 170px;
    .qtip-content {
        text-align: center;
    }
    .pos, .neg {
        font-size: 14px;
        font-family: Helvetica;
        font-weight: bold;
        color: white;
        width: 65px;
        margin: 0 5px;
        display: inline-block;
        text-align: center;
        line-height: 15px;
    }
    .sentiment-overall {
        margin: 6px 0 3px 0;
        font-size: 12px;
        font-weight: bold;
        color: white;
    }
    .separator {
        display: inline-block;
        height: 14px;
        width: 1px;
        background-color: #A6A6A6;
        position: relative;
        top: 3px;
        margin: 0;

    }
    .upvote {
        -webkit-mask-image: url(/images/icons/thumbup.svg);
        background-color: #54D08B;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 12px;
        height: 14px;
        margin-bottom: -1px;
    }
    .downvote {
        -webkit-mask-image: url(/images/icons/thumbdown.svg);
        background-color: #D15A5A;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 12px;
        height: 14px;
        margin-bottom: -2px;
    }
}

@import "../../infra/style/style_guide";
@import "../../infra/style/base";

.linked-dropdown {
  display: inline-block;
  position: relative;

  &.open {
    margin-left: 5px;

    .selected-text {
      visibility: hidden;
    }

    .options {
      display: block;
    }
  }

  .selected-text {
    @include user-select(none);
    cursor: pointer;
    color: $selected-color;
    padding-left: 1px;
    border-bottom: dashed 1px $regular-color;

    i {
      font-size: 9px;
      color: $click-color;
    }
  }

  ul.options {
    position: absolute;
    top: -13px;
    padding: 5px 0;
    margin: 0;
    width: max-content;
    border-radius: 4px;
    border: 1px solid $selected-color;
    list-style-type: none;
    background: #27282a;
    z-index: 4;
    display: none;

    .option {
      @include user-select(none);
      position: relative;
      display: block;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $click-color;
      padding: 0 10px;
      line-height: 30px;

      &:first-letter {
        text-transform: uppercase;
      }

      i {
        margin-left: 5px;
      }

      &.selected, &:hover, &:hover.selected {
        color: $selected-color;
      }
    }
  }
}

@import "../../common/style/flexbox";
@import "infra/style/base";
@import "infra/style/style_guide";

$positive-color: #4cd43e;
$negative-color: #ea2626;

@mixin text-block-mixin() {
  @include display_inline_flex();
  @include flex_direction(column);

  margin: 5px 10px 0 20px;
  .number {
    font-size: 25px;
  }

  .text {
    text-align: center;
    font-size: 10px;
  }
}

@mixin brand-index-graph-color($brand_index_graph_axis_color: #FFFFFF) {
  brand-index-graph {
    .axis {

      .domain {
        stroke: none;
      }

      text {
        fill: $fade-color;
      }

      path, line {
        stroke: $fade-color;
      }
    }
  }
}

brand-index-widget {
  @include display_flex();
  @include flex_direction(column);

  width: 100%;
  height: 100%;

  .header {
    box-shadow: 1px 2px 0 0.00px rgba(0, 0, 0, 0.75), inset 0 2px 1px -2px #DDDFD0;
    padding: 15px;

    #brand_name {
      @extend .base_bold_font;
      float: left;
      font-size: 35px;
      border-radius: 4px;
      color: #ffffff;
      padding: 5px 10px 5px 10px;
    }

    #latest_index {
      float: left;
      position: relative;

      @include text-block-mixin();
    }

    #bi_change, #consumption-info, .divider {
      float: left;
      margin: 5px 15px 0 20px;

      @include text-block-mixin();
      .number {
        color: $positive-color;
        @extend .base_bold_font;
      }
      .number.negative {
        color: $negative-color;
      }
      .text {
        color: white;
        font-size: 14px;
        @extend .base_bold_font;
      }
    }
    #consumption-info {
      margin: 5px 10px 0 0;
    }
    #bi_change {
      padding-left: 20px;
    }

    div.divider {
      height: 40px;
      border-right: 1px solid #2e3236;
      margin: 7px 19px 0 10px;
    }

    .sentiment {
      line-height: 0.9;
      .text {
        color: white;
        font-size: 14px;
        display: flex;
      }
      .negative, .positive {
        margin-top: 5px;
        line-height: 1.39;
        font-size: 26px;
      }
      .negative {
        color: $negative-color;
      }
      .positive {
        color: $positive-color;
      }
      .downvote, .upvote {
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 12px;
        height: 14px;
        margin-left: 5px;
        margin-bottom: 2px;
        margin-right: 1px;
      }
      .upvote {
        -webkit-mask-image: url("/images/icons/thumbup.svg");
        background-color: $positive-color;
        margin-left: 15px;
      }
      .downvote {
        -webkit-mask-image: url("/images/icons/thumbdown.svg");
        background-color: $negative-color;
      }
    }
  }

  .legend {
    margin-top: 25px;
    height: 20px;
    .terms {
      float: right;
      padding-right: 15px;
    }
    .term {
      border-radius: 4px;
      margin-left: 5px;
      margin-right: 5px;
      color: $main-btn-color-hover;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      font-size: 14px;
      @extend .base_bold_font;
      text-align: center;
      height: 19px;
      line-height: 19px;
    }

    .edit-btn {
      color: $second-btn;
      padding: 0 2px 0 1px;
      cursor: pointer;
      &:hover {
        color: $second-btn-hover;
      }

      .icon-edit_icon {
        font-size: 12px;
        margin: 0 1px;
      }
    }
  }
  brand-index-graph {
    @include flex(1 1 auto);

    display: block;
    position: relative;

    .axis {
      text {
        fill: $regular-color !important;
        font-size: 14px !important;
      }

      path, line {
        fill: none;
        stroke: #FFFFFF;
        shape-rendering: crispEdges;
      }
    }
    .x-axis.axis path {
      display: none;
    }

    .line {
      fill: none;
      stroke-width: 2px;
    }
    circle.point {
      stroke-width: 1.5px;
    }
    .area {
      stroke-width: 0px;
      fill: url("../#brandgardient")
    }
  }
}

quick-tree{
	font-family: Helvetica,Arial,sans-serif;
	font-size: 13px;
		
	.leaf{
		position: relative;
	    display: block;
	    line-height: 1.8em;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    padding-right: 1em;
	    padding-left: 5px;
		
		.arrow{
			display:inline-block;
			font-size:10px !important;
			transition: transform 0.2s linear;
			&.open{transform: rotate(90deg)}
    		float: left;
    		margin-right: .5em;
		}

		.name{
			padding-right: 6px;
    		display: inherit;
		}

		/*3 state colors checked/semi/''*/
		.icon-Box_Roll_On{color: transparent;}

		&.checked, .checked{
			color: $selected-color;
			.icon-Box_Roll_On{color: $selected-color;}
		}

		&.semi .icon-Box_Roll_On{color: $border-color-focus;}
		
		.icon-Box_Roll_On{color: transparent;
			position:absolute;
			right:10px;top:0px;}
	}

	.children{margin-left: 16px;}

	.search-tree{
		margin-left: 3px;
    	margin-top: 6px;
    	margin-bottom: 6px;
    	*{font-size:13px}

    	i{color: $disabled}

    	&.searching *{color:$selected-color;}
    	
		input{
			background:transparent;
			width:150px;
			border:0px;
			border-bottom:1px solid $disabled;

			&::-webkit-input-placeholder{color: $disabled}
		}	
	}

	.summary{font-size: 14px;height:18px;max-width:90%;
    	overflow:hidden;text-overflow:ellipsis}

    .select-all{
    	display:block;
        padding: 0.5em;
    	padding-bottom: 0.2em;
    }
    
}
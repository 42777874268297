@import "../../infra/style/base";
@import "../../infra/style/style_guide";

am-toggle-button {
    font: 14px Helvetica;

    .toggle-button-wrapper {
        -webkit-user-select: none;
        display: inline-block;
        border: 1px solid black;
        border-radius: 4px;
        background: $background-color; 
        height: 30px;  

        &.disabled {
            pointer-events: none;

            toggle-button {
                color: $disabled!important;
            }
        }

        toggle-button {
            padding: 0px 10px;
            height: 100%;
            vertical-align: middle;
            color: $click-color;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            line-height: 30px;
            border-right: 1px solid black;

            &:last-of-type {
                border-right: none;    
            }

            &.selected {
                color: white;
                background: #151719
            }    

            button-icon {
                margin-right: 5px;    
            }
        }             
    }
}

$filterWidth: 236px;

.open-filter-menu {
  side-filters-menu {
    margin-right: 0;
  }
}

.reset-filter-div {
  height: 50px;
  position: fixed;
  bottom: 0;
  right: 0;
  border-top: 1px solid #2b3036;
  opacity: .95;
  left: 0;

  .reset-filters-button {
    @extend .base_bold_font;
    color: $click-color;
    border: none;
    position: fixed;
    bottom: -5px;
    left: 50%;
    width: 140px;
    height: 30px;
    font-size: 14px;
    transform: translate(-50%, -50%);
    img {
      padding-left: 5px;
    }
    &:hover {
      color: $main-btn-bg-hover;
      background-color: transparent;
      img {
        content: url('/images/icons/Reset_filter_hover.svg');
      }
    }
  }
}

side-filters-menu {
  transition: all $animation-time linear;
  transform: translate3d(0, 0, 0);
  float: right;
  display: block;
  margin-right: - $filterWidth;
  flex-direction: column;
  background-color: #151719;
  width: $filterWidth;
  height: 100%;
  overflow: hidden;
  border-left: 3px solid #010101;
  @include user-select(none);
  font-family: helvetica;

  

  .tree-container{
    .container{
      display:block !important;
      margin-bottom: -0.7em;
      }
      
    .tree{
      display:none;
      margin-left:-0.7em;
      margin-right:-1em;
    }

    .custom-icon{
      margin-left:3px;
      margin-top:1px;
    }

    .triangle{
      margin-right:3px;
    }
    

    &.open .tree{display:block;}

    .summary{    
      height: auto;
      line-height: 1.3em;
      margin-left: -1.1em;
      margin-top: 0.7em;
      clear: none;
      }  
  }


  am-select.programs.open {
    flex: none;
  }

  .filters-container {
    max-height: calc(100% - 50px);
    overflow: auto;
  }

  .section-title {
    @extend .base_bold_font;
    color: #dddee1;
    font-size: 16px;
    margin-top: 26px;
    margin-bottom: 20px;
  }

  h5 {
    @extend .base_bold_font;
    color: white;
    margin: 0.5em 0 1em; /*top right/left bottom*/
    cursor: default;
    font-size: 13px;

    am-input {
      float: right;
      color: gray;
      &[checked] {
        color: white;
      }
    }
    &.radio-group-caption {
      margin: 16px 0 1em; /*top right/left bottom*/
    }

    &.checkbox-group-caption {
      margin: 1.8em 0 .2em; /*top right/left bottom*/
    }
  }

  am-container[type=accordion] {
    am-input[type=radio-group] {
      width: 100%;
      .option {
        width: 33.33333%;
      }
    }
  }
}

hr {
  border: none;
  margin: 0;
  border-top: 1px solid #35393C;
}

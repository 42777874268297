@import "../../../infra/style/style_guide";

$animation-time: 0.2s;
$animation-method: cubic-bezier(0.4, 0.5, 0.38, 0.82);
$audience-skin-opacity: 0.98;
$triangle-width: 10px;

am-select {
  &.audience-2-skin {
    select-title {
      border: solid 1px $click-color;
    }

    .disabled {
      select-title {
        border: solid 1px $disabled-color;
      }
    }

    select-title, .menu.audience-skin {
      background: #2c2f38;
    }

    &.open {
      select-title, .menu.audience-skin {
        background: #22252b;
      }
    }

    &:not(.open) {
      height: 14px;
      .menu, .menu-triangle {
        display: none;
      }
    }

    summary.audience-skin {
      font-size: 14px;
      margin-top: 0.8em;
      color: $click-color
    }

    &.open summary.audience-skin {
      color: $regular-color;
    }

    .menu-triangle:after {
      border-color: transparent transparent #22252b transparent;
    }
    .icon-Triangle_Down {
      color: $click-color;
    }
  }

  display: block;
  flex-direction: column;
  color: #777d8b;
  font-size: 13px; /* Approximation due to font substitution */

  text-align: left;
  text-transform: capitalize;
  margin-bottom: 12px;
  position: relative;

  .meta-data {
    color: $second-btn;
    padding: 2px;
    margin-left: 5px;
    background: black;
    font-size: 10px;
    font-weight: bold;
  }

  .top-values-toggle {
    color: $click-color;
    font-weight: normal;
    padding: 10px 0;

    .control-button {
      background-color: #202427;
      border: 1px solid black;
      padding: 0 8px;
      cursor: pointer;
      margin-right: -5px;
    }

    .control-button:hover {
      color: $selected-color;
    }

    .selected-control {
      background-color: $selection-background;
      cursor: default;
      color: $selected-color;
      @extend .base_bold_font;
    }

    .edit-custom-sources {
      float: right;
      margin-right: 10px;
      margin-top: 5px;
      line-height: 16px;

      .icon-edit_icon {
        visibility: visible;
        margin-left: 5px;
      }

      &:hover {
        cursor: pointer;
        color: $selected-color;
      }
    }
  }

  ng-transclude {
    overflow: visible;
    display: block;
  }

  i {
    transition: transform $animation-time $animation-method;
  }

  &.open {
    overflow: hidden;
    .menu {
      overflow: auto;
      height: auto;
    }
    .menu.open-up {
      position: fixed;
    }
    summary {
      opacity: 0;
      display: none;
    }
    i.icon-Triangle_Down {
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }

  select-title {
    @extend .base_bold_font;
    width: 100%;
    display: inline-block;
    cursor: pointer;
    text-transform: capitalize;
    background-color: #202427;
    line-height: 30px;
    font-size: 13px;
    margin-bottom: .2em;
    padding: 0 11px;
    color: #81878c;

    &::before {
      margin-right: 8px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      line-height: 23px !important;
      font-size: 0.9em;
    }

    i {
      float: right;
      &::after {
        font-size: 1em;
        vertical-align: middle;
        line-height: 30px !important;
      }
    }
  }

  .tool-bar {
    padding: 0 10px;
    border-bottom: 1px solid #30343c;
    margin-bottom: 6px;
    height: 32px;
    line-height: 30px;
  }

  .owned-content {
    border-top: 1px solid #30343c;
    margin-top: 6px;
    padding-top: 10px;

    am-switch-button {
      margin-right: 0;

      .clickable-container {
        width: 100%;
      }
    }
  }

  .menu {
    overflow: hidden;
    height: 0;
    display: block;
    padding: 0 6px;
    margin: 0;
    list-style: none;
    line-height: 1.8em;
    font-size: 13px;
    position: relative;

    &:not(.tree) {
      i {
        visibility: hidden;
      }
    }

    .option {
      @include user-select(none);
      position: relative; /*for ✓ align right*/
      display: block;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 1em; /*left place to ✓*/
      padding-left: 5px;
      color: #81878c;

      .text-with-button {
        position: initial !important;
      }

      &:hover {
        text-overflow: initial;
        span {
          position: relative;
        }
      }

      i {
        float: left;
        margin-right: 0.5em;
        &:before {
          font-size: 1.2em;
        }
      }

      & > div {
        overflow: hidden;
      }

      am-input[type=checkbox] {
        position: absolute;
        right: 5px;
        top: 0;
      }

      &.selected, &:hover, &:hover.selected, &:hover.selected am-input {
        color: white;
      }

      &.partial {
        color: $click-color;
        am-input[type=checkbox][indeterminate]::before {
          color: $click-color !important
        }
      }
    }

    .sub-menu {
      position: relative;
      padding-left: 2em;
      max-height: 0; /*for animation*/
      border-width: 0; /*for animation*/
      overflow: hidden;
      transition: max-height 0.1s $animation-method;
    }

    .main.open {
      i {
        -moz-transform: rotate(90deg) translate(0, 2px);
        -webkit-transform: rotate(90deg) translate(0, 2px);
        -o-transform: rotate(90deg) translate(0, 2px);
        -ms-transform: rotate(90deg) translate(0, 2px);
        transform: rotate(90deg) translate(0, 2px);
      }

      & + .sub-menu {
        max-height: 50em;
        margin-bottom: 5px;
        padding-bottom: 5px;
      }
    }

    .add-source {
      text-align: center;

      div.wrapper {
        display: inline-block;
        line-height: 16px;
        margin-top: 6px;
        padding: 6px;
        color: $click-color;

        &:hover {
          color: $selected-color;
          cursor: pointer;
        }

        i {
          visibility: visible;
          margin-right: 5px;
        }
      }
    }
  }

  summary {
    padding: 0 6px;
    color: $regular-color;
    font-size: 12px; /* Approximation due to font substitution */
    line-height: 1em; /* Approximation due to font substitution */
    text-align: left;
    margin-top: 0.5em;
    opacity: 1;
    &.ng-hide {
      opacity: 0;
    }
    .tag {
      &::after {
        content: ', ';
        display: inline;
      }
    }
  }

  .ngsb-wrap {
    overflow: visible !important;
  }
  .ngsb-wrap .ngsb-scrollbar {
    right: -10px;
  }
}

.custom-filter {
  * {
    font-family: helvetica;
  }

  .summary .default-text {
    display: block !important;
    .title, .value {
      display: inline-block;
      margin-bottom: 8px
    }
    .title {
      width: 120px;
    }
  }
}

.open {
  .audience-skin.menu, .audience-skin select-title {
    border: 1px solid white;
  }

  .audience-skin.menu {
    border-bottom: 3px solid white;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
  }

  select-title {
    border: 1px solid #30343c;
  }

  .menu-triangle:before, .menu-triangle:after {
    visibility: visible;
  }
}

.menu-triangle:after {
  content: '';
  display: block;
  position: absolute;
  top: 34px;
  left: calc((100% - #{$triangle-width}) / 2);
  width: 0;
  height: 0;
  border-color: transparent transparent #202427 transparent;
  border-style: solid;
  border-width: 10px;
  z-index: 5;
  opacity: 0.99;
  visibility: hidden;
}

.menu-triangle:before {
  content: '';
  display: block;
  position: absolute;
  top: 32px;
  left: calc((100% - #{$triangle-width}) / 2 - 1px);
  width: 0;
  height: 0;
  border-color: transparent transparent white transparent;
  border-style: solid;
  border-width: 11px;
  z-index: 5;
  visibility: hidden;
}

.audience-skin {
  .scroll-container {
    overflow-y: auto;
  }
  &.menu {
    border: 0;
    margin-top: 11px;
    margin-bottom: 11px;
    background: #202427;
    z-index: 4;
    line-height: 2em;
    opacity: $audience-skin-opacity;

    .option {
      &:hover {
        background: black;
        border-radius: 4px;
        color: #81878c;
      }

      &.selected {
        .span {
          color: white !important;
        }
      }
    }

    .tool-bar {
      padding: 0 15px;
    }
  }
}

.audience-skin, .radio-buttons-skin {
  select-title {
    height: 36px;
    line-height: 37px;
  }
  .icon-Triangle_Down {
    font-size: 11px;
    color: #cacaca;
  }
}

.disabled > select-title {
  color: $disabled;
  cursor: default;
  i.icon-Triangle_Down:before {
    color: $disabled;
  }
}

am-select.open {
  .radio-buttons-skin, .audience-skin {
    i.icon-Triangle_Down {
      transform-origin: center bottom;
      line-height: 10px
    }
  }

  .radio-buttons-skin.menu {
    overflow: initial;
  }

  summary.radio-buttons-skin {
    display: none !important;
  }

  .radio-buttons-skin {
    .scroll-container {
      display: flex;
      overflow: hidden;
    }
    select-title {
      visibility: hidden;
    }
  }

  .radio-buttons-triangle {
    .icon-Triangle_Down {
      visibility: visible;
      right: 11px;
      position: absolute;
      top: -3px;
      z-index: 99;
      cursor: pointer;
    }
  }
}

.radio-buttons-skin {
  &.menu {
    padding: 0
  }

  .scroll-container {
    display: none;
    flex-flow: column wrap;
    border: 0;
    margin-top: -42px;
    background: $textbox-bg;
    z-index: 4;
    line-height: 2.5em;
    opacity: $audience-skin-opacity;
    padding-top: 13px;

    .option {
      overflow: visible; // avoid cutting left edge of circles on some screens

      &:hover {
        color: $click-color;
        cursor: default;
      }

      div {
        display: inline-block;
        line-height: 9px;
      }

      .text-with-button {
        &:hover {
          color: white;
          cursor: pointer;
        }

        &:hover am-input[type=checkbox].Off:before {
          color: white !important;
        }
      }

      am-input[type=checkbox] {
        left: 0;
        width: 30px;
        &:hover {
          cursor: pointer;
        }
      }
      am-input.On:before {
        @extend .icon-radio_on:before;
        color: $audience-highlight-color;
      }

      am-input.Off:before {
        @extend .icon-circle-o:before;
        color: $click-color;
      }

      i {
        margin-right: 1.5em;
      }
    }
  }
}

summary.audience-skin, summary.radio-buttons-skin {
  position: absolute;
  top: 0;
  margin-top: 0.85em;
  opacity: 1 !important;
  display: block !important;
  cursor: pointer;
  padding-left: 13px;
  padding-top: 0;
  font-weight: bold;
  font-size: 13px;
  &.disabled {
    color: $disabled !important;
    cursor: default;
  }
}

summary.audience-skin {
  font-size: 15px;
  margin-top: 0.70em;
}

.flat-list-skin {
  select-title {
    border: 0;
    background-color: initial;
    padding-left: 0;
    color: white;
    cursor: default;
    line-height: 20px;
  }
  &.menu {
    padding: 0;
    font-family: Helvetica;
  }
}

consumption-channel-widget {
  margin: 15px;

  .channel-sliders{
    padding-left: 90px;
    .bar-wrapper {
      position: relative;
      z-index: 1;
    }
  }
  .bar-avg {
    position: absolute;
    border-top: solid 1px #485056;
    bottom: 30px;
    width: 100%;

    .bar-avg-text {
      position: relative;
      margin-left: -90px;
      transform: translate(0, -50%);
      display: inline-block;
      z-index: 1;
      line-height: 190px;
      color: #485056;
      background-color: #202427;
      padding-right: 17px;
    }
  }
  .title-container {
    padding-bottom: 20px;
    .title {
      display: block;
      color: #ffffff;
      font-weight: bold;
      margin-bottom: 5px;
      font-size: 16px;
    }
    .description {
      color: #cacaca;
    }
  }

  .hidden {
    display: none;
  }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";
@import "../../widgets/topic-selector-widget/topic-selector-widget";
@import "../../common/selectable-bar-chart.drv/selectable-bar-chart.drv";


$content-drivers-open: 320px;
$small-screen-height: 900px;

audience-interests-widget {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  display: block;

  .audience-interests-widget {
    height: 100%;
    
    .main-title {
      margin-left: 20px;
      font-size: 16px;
      color: white;
      font-family: Helvetica Bold; 
    }

    .sub-title {
      margin-left: 20px;
      color: #cacaca;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .sort-toggle {
      position: absolute;    
      right: 27px;
      top: 3px;
      font-size: 14px;

      .sort-by-title {
        color: $highlight-color;
        padding-right: 10px;
        font-size: 16px;
      }
    }

    .row.disabled {
      am-input{pointer-events:none;}
    }

    selectable-bar-chart {
      float: left;
      height: calc(100vh - 391px);
      box-shadow: inset 0 2px 1px -2px #DDDFD0;
      border: 1px solid black;
      padding-top: 1px;
      width: 100%;
      margin-top:-16px;

      @media(max-height: $small-screen-height){
        height: calc(100vh - 356px)!important;
      }

      &.loading{margin-top:15px;}

      &.all #select-all-checkbox{margin-right:82px;}

      &.table #select-all-checkbox{display:none;}
    }

    .table-container {
      position: absolute;
      width: 100%;
      top: 300px;

      @media(max-height: $small-screen-height){
        top: 265px;
      }

      quick-table {
        .thead {
          .column {
            border-top: 1px solid #08090A;
          }
        }

        .quick-table-wrap {
          margin: 0;
          width: 100%;
          height: calc(100vh - 464px);

          @media(max-height: $small-screen-height){
            height: calc(100vh - 429px);
          }
        }

        .quick-table .tbody .row.disabled{
          cursor: auto; 
          *{color: #{$selected-color} !important;}
          i.checked, i.unchecked{color: #{$disabled-row} !important;}
        }
      }
    }

    .data-type-toggle {
      position: relative;  
      z-index: 10;
      top: 31px;
      left: 0px;
      font-size: 14px;
    }

    .output-type-toggle {
      position: relative;  
      z-index: 10;
      top: 30px;
      left: calc(100% - 249px);
      button-icon {margin: 0 !important;}
      font-size: smaller;

      .toggle-button-wrapper{height:29px;}
    }

    & > .loader, & > .loader-overlay {
      display: none;
    }

    #topics-spinner{
      .loader-overlay{height:200px}
      .loader{top: calc(350px - 100vh)}
    }

    .summary{
      position: absolute;
      left: 0px;
      bottom: -15px;
      font-weight: 700;
      color: #{$selected-color}
    }

    /*CONTENT DRIVERS*/

    .content-drivers-pane{
      height: calc(100% - 89px);
      position: fixed;
      top: 109px;
      right: -290px;
      margin-right: 0px;
      width: #{$content-drivers-open};
      .pane-tabs .pane-tabs-tail{height:calc(100% - 108px)}

      .target-drawer-title {width: calc(360px - 100px);}
    }

    /*open state*/
    &.content-drivers-open {
        width: calc(100% - #{$content-drivers-open});
        
        .table-container{width: calc(100% - #{$content-drivers-open})}

        .content-drivers-pane{right: 10px;}

        .pane-tabs{display:none}

        .content{
          max-width: calc(#{$content-drivers-open} + 10px);
          width: calc(#{$content-drivers-open} + 10px);
          height: calc(100% - 111px);
        }

        .sort-toggle { 
          right: 344px;
        }

        .pane-title {
          display: inline-block;
          vertical-align: top;
          width:100%;
          .icon-Targeting{display:inline-block;vertical-align: top;}
          .target-drawer-title{display:inline-block}
        }
    }

    .target-buttons{
      position:absolute;
      right: -12px;
      bottom: -23px;
    }

    .content_drivers_link, .icon-Content_drivers{display:none !important} /*only targets here*/
 }
}

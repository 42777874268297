$topics-background: #22252b;


.modal-overlay.edit-target,
.modal-overlay.create-target,
.modal-overlay.dynamic-trends,
.modal-overlay.manual-phrases {

  .quick-table-wrap {
    width:inherit !important;
    height:30vh;
  }

  &.modal-overlay.edit-target .modal {
    width: auto !important;
    position: absolute !important;
    right: 5em !important;
    left: 5em !important;
    top: 3em !important;
    padding: 20px;
    margin-top: 0 !important;
    font-size: 14px;

    &.no-manual .quick-table-wrap {height:45vh;}
  }


  .quick-table{
    .row{margin-bottom:0px !important;} /*overrides modal css*/

    input{margin-top: 0px !important;}

    .tbody{
      .icon-X{cursor:pointer;color:$click-color;}

      .column.emoji .icon-X{margin-top:-6px;}
    }
  }


  .note{
    margin-top: 6px;
    color: $click-color;
    .icon-preview{font-size:11px;}
  }

  .title-name{
    color:#fff;
    font: 18px Helvetica Bold,sans-serif;
  }

  .title-summary{
    padding-left:0px !important;
    color: $regular-color;
  }

  .edit-name{
    font-size:14px;
    width:250px;
    height:30px !important;
    margin-bottom: -2px;
  }
  &:not(.create-target) .edit-name{margin-top: -7px !important;}


  input[type=text].am-style.ng-dirty{border:1px solid $separator-color !important}

  label, am-input[type=checked] {
    cursor: pointer;
  }

  .icon-indicator{font-size: 12px;margin-top:2px;}


  .navigation{
    justify-content: inherit;
    padding: 0px;

    .nav-button{
      margin:0px;
      text-transform: capitalize;
      background-color:#1B1F21;
      border: 1px solid #000;
      border-bottom:0px;
      border-bottom-left-radius:0px;
      border-bottom-right-radius:0px;
      border-right-width:0px;

      &.selected{
        background-color:#151719 !important;
        border-radius:0px !important;
      }

      &:first-child{border-top-left-radius:6px !important;}
      &:last-child{border-top-right-radius:6px !important;border-right-width:1px;}

      &.disabled{color:#363a3d; cursor:text}
    }
  }

  .modal-body {
    border-bottom: 1px solid $border-color;
  }

  #manual-phrases-link{
    padding:6px;
    border:1px solid #000;
    border-top:0px;

    .plus{
      margin-top: -1px;
      vertical-align: top;
      font-weight: bold;
    }
  }

  .modal-bottom-bar {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $border-color-focus;


    .activate-option {
      display: inline-block;
      margin: 0 10px;
    }

    .Switch{margin-right:5px;}

  }

  .right-side-action {
    float: right;
    padding-top:4px;
    i{margin-left: .2em;font-size:16px;}
    i.icon-Export {vertical-align: bottom;}
    a[download-excel]{padding-left:0px;}
  }

  .delimiter {
    width: 8px;
    height: 20px;
    vertical-align:middle;
    margin-right: 8px;
    display: inline-block;
    border-right: 1px solid #444;
  }


  /*AUTOCOMPLETE START*/
  am-autocomplete{

      .autocomplete-err-msg{
        position: relative;
        display: inline-block;
        left: -270px;
        top: 24px;
      }

    .autocomplete-options-dropdown {
        width: 247px;
        max-height: calc(35px * 11);/*11 is the num of items to show in the viewable area*/

        #marketListAC-noMatchMsg{
          color: $textbox-text-error;
          @extend .base_bold_font;
        }
     }


     .autocomplete-options-list {
          list-style-type: none;
          margin: 0;
          padding: 0;
          width: 100%;


          .autocomplete-option {
               width: 219px;
               height: 35px;
               line-height: 35px;
               padding: 0px 15px;

               span {
                display: inline-block;
                text-overflow:ellipsis;
                max-width:195px;
                overflow: hidden;
                white-space: nowrap;
               }

               .markLetters{
                  font-weight: 600!important;
                  display:inline;
                  color:#fff!important;
               }

          }

          .specialTitle{
              line-height: 55px;
              height: 55px;
              margin-bottom: 8px;
              border-bottom: 1px solid #3f4348;
              border-bottom-left-radius:0px!important;
              border-bottom-right-radius:0px!important;
          }



        }
  }
  /*AUTOCOMPLETE END*/


  .manual-phrases{
      display: inline-block;
      width: 318px;
      min-height: 1em;
      padding:12px;
      margin-left:8px;
      margin-top:-6px;

      label{padding:2px;}
      .icon-Search, .demi-icon-pipe, .input-bar-right-buttons{display:none;}

      .button-remove-tag{display:none;}
  }

  .icon-X.small{font-size:8px;}


  &.create-target .modal{
    width:410px;
    height:245px;
    padding:20px;
    margin-top: calc(50vh - 180px) !important;

    .title{margin-top:-5px;}

    label{color:$regular-color;}

    .edit-name{width:368px;font-size:14px;}

    .icon-X{color:#fff;}
  }

}

.modal-overlay.dynamic-trends{

  .modal {
    width: 70vw;
    height: 80vh;
    margin:10vh auto 0;}

  .quick-table-wrap {
    height:calc(100% - 297px) !important;
    margin-top:16px;
    margin-bottom:16px;
  }

  .info-title{
    color:$selected-color;
    &.large{font: 14px helvetica bold;}
  }

  .info-line{
    margin-bottom:6px;
    .info-title{display:inline-block;}
    .info-sub{color:$click-color;}
    }

  .info-seed{
    background:$term_grayscale;
    color:$selected-color;
    margin:12px 10px 8px 0px;
    padding:2px 4px 2px 4px;
    font:14px helvetica bold;
    border-radius:2px;}
}

#topics{

  #manual-topics-link{
    border: 1px solid #545a66!important;
    padding: .5em;
    width: 310px;
    display: inline-block;

    &.limit-topics-opened{
      background: $topics-background;
      border-color:$main-btn-color !important;
      border-bottom-color: transparent !important;
      span{color:$main-btn-color;}
    }
  }


  .tree{
    border: 1px solid white;
    width: 310px;
    background: $topics-background;
    height: 114px;
    overflow: auto;
    position:absolute;
    z-index:1000;
    display:none;

    .icon-Topic, .meta-data{display:none;}
  }

  quick-tree.limit-topics-opened  .tree{display:block;}

  quick-tree.dynamic-trends .tree{height: 157px;}


  .summary{
    margin-left:3px;
    white-space: nowrap;
    max-width: 100%;
    }
}

.manual-phrases{

  .modal {
    width: 700px !important;
    height: 402px;
  }

  input-bar{
    background:transparent;
    height:202px;

    &.input-mode{
      background:#22252b !important;
      border: 1px solid #fff;
    }

    textarea{height:180px !important}
    .clear-all, .demi-icon-pipe{display:none}
  }
}

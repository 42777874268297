$border-color: #2e3138;
$best-ratio: 1.6180339;
$modal-height: 250px;
$modal-width: 370px;
$dark-bg: #151719;
$modal-inner-padding: 20px;
$modal-icon-size: 50px;

#abi-notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  height: 100%;
  width: 100%;

  .modal {
      margin: 0 auto;
      padding: 28px 30px 30px 30px;
      top: calc(50% - 120px);

      border: 1px solid white;
      border-bottom: 2px solid white;
      border-radius: 8px;

      width: 400px;

      color:$text-color;
      background-color: #2c2f38;
      opacity: 0.95;
      font: 14px Helvetica;

    #body-modal {
      height: 100%;
      position: relative;
      .title {
        font: 300 18px "Helvetica Bold";
        color: $title-color;
        padding-bottom: 20px;
      }
      .notification {
        i {
          position: relative;
          left: calc(50% - 20px);
          font-size: $modal-icon-size;
        }
        .icon-tick_in_circle {
          color: #238a6a; // Green
        }
        .icon-exclamation_in_circle {
          color: #d73939; // Red
        }
        .text {
          text-align: center;
          padding-top: 10px;
          color: $title-color;
        }
      }
    }
  }
}

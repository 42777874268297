@import "../../infra/style/base";
@import "../../infra/style/style_guide";

$large-resolution: 1600px;

scatter-chart {
  margin: auto;
  font-family: Helvetica;

  svg {
    height: initial;
    width: initial;

    text {
      font-family: Helvetica;
    }
  }

  circle.point {
    r: 7;
    fill: DEFAULT_TREND_CLASS;

    @media (min-width: $large-resolution) {
      r: 8;
    }
  }

  text.point-label {
    font-size: 14px;
    font-weight: bold;
    fill: #FFFFFF;
    text-anchor: start;
    cursor: default;

    @media (min-width: $large-resolution) {
      font-size: 16px;
    }
  }

  .x-axis path, .y-axis path {
    stroke: none;
    fill: none;
  }

  .tick {
    stroke: none;
    fill: none;
  }

  .axis-labels {
    .x-axis-labels {
      dominant-baseline: text-before-edge;
    }

    .y-axis-labels {
      dominant-baseline: text-after-edge;
    }

    .center-axis-label-wrapper {
      margin: auto auto;
      width: 65%;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      color: #CACACA;
      font-weight: normal;

      @media (min-width: $large-resolution) {
        font-size: 16px;
      }

      .center-axis-label {
        color: #FFFFFF;
        font-weight: bold;
      }

      .axis-edit {
        font-size: 11px;
        cursor: pointer;
        padding-right: 5px;
        vertical-align: middle;

        @media (min-width: $large-resolution) {
          font-size: 13px;
        }

        &:hover {
          color: $selected-color;
        }
      }
    }

    text.low-axis-label, text.high-axis-label {
      font-size: 14px;
      font-weight: normal;
      fill: #CACACA;

      @media (min-width: $large-resolution) {
        font-size: 16px;
      }
    }

    .low-axis-label {
      text-anchor: start;
    }

    .high-axis-label {
      text-anchor: end;
    }
  }

  & > .loader-overlay {
    padding: 26px;
    background-clip: content-box;
    opacity: .2;
  }
}

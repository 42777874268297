@import "infra/style/base";
@import "infra/style/style_guide";
@import "common/style/flexbox";
@import "./channels.insights";
@import "widgets/base_widget";
@import "widgets/consumption-index/consumption-index-widget";
@import "widgets/sentiment-index/sentiment-widget";
@import "widgets/timing-widget/timing-widget";
@import "widgets/topic-trends/topic-trends-widget";
@import "widgets/top-association/top-association-widget";
@import "widgets/referrals-widget/referrals-widget";
@import "widgets/consumption-channel/consumption-channel-widget";
@import "widgets/landscape-widget/landscape-widget";
@import "widgets/insights-geo-widget/insights-geo-widget";

$control-height: 31px;
$wide-view-min-width: 1280px;

@mixin tab-properties() {
  background-color: #202427;
  border: 1px solid black;
  box-shadow: inset 0 2px 1px -2px #DDDFD0;
}

side-filters-menu {

  am-input[type=checkbox-text] span {
    font-family: Helvetica;
  }

  am-input[type=radio-group] {
    margin-bottom: 7px;
  }

  am-select {
    summary, select-title {
      font-size: 14px;
    }

    .meta-data {
      font-size: 9px;
    }

    select-title:hover, &.open select-title {
      color: $selected-color;
      border: 0;
    }
  }

  am-container {
    .top:hover, &.open .top {
      color: $selected-color;
    }

    &[type=accordion] .summary{
      font-size: 14px;
    }
  }
}

section.insights-page {
  overflow: hidden;
  height: 100%;
  position: relative;

  .tabs {
    text-align: center;
    width: 100%;

    @media (max-width: 1095px) {
      .tab {
        font-size: 14px;
        &.selected {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }

  .button-edit-tag {
    display: inline-block;
  }

  bar-input {
    min-height: 56px;
  }

  input-bar.main {
    border: 1px solid #000;
    outline: none;
    &.input-mode {
      border: 1px solid;
      .icon-Search {
        color: inherit;
      }
    }
    &.open {
      border-color: initial;
      i.icon-Search, i.demi-icon-pipe {
        color: #81878C;
      }
    }
    results {
      outline: none;
      border-bottom: 2px solid #fff;
      margin-top: 1px;
    }
    & > label {
      cursor: text;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 17px 0 17px 0.5em;
      overflow: hidden;
      margin-left: 3.2em;
      border-left: 2px solid #333841;
    }
  }

  .social-overflow {
    tag[type="mention"], tag[type="hashtag"], tag[type=post], .social-bl {
      @include not-in-use();
      @include hide-refine();
    }
  }

  .social-overflow.grayscale {
    @extend .grayscale;
  }

  .not-allow-boolean {
    tag:not([type="term"]) {
      @include not-in-use();
    }
    @include hide-refine();
  }

  .not-allow-boolean.grayscale {
    @extend .grayscale;
  }

  .allow-boolean {
    .define-interest {
      width: 100%;
      display: inline-block !important;
    }
  }

  .disable-posts{
    tag[type=post] {
      @include not-in-use();
    }
  }

  .widget-panel {
    @include flex_default_layout();
    @include flex_column();
    @include flex_item_align(stretch);
    @include tab-properties();
    height: 100%;
    margin-right: 1em;
  }

  .widget-panel.channels-panel{
    padding: 0;
    border: none;
    box-shadow: none;
  }

  .main-container {
    display: block;
    position: absolute;
    bottom: 1em;
    top: 127px;
    left: 0;
    right: 0;
    margin-left: 1em;
  }

  *:focus {
    outline: 0;
  }

  am-empty-screen-message {
    .empty-screen-img {
      width: 321px;
    }
    .empty-screen-msg-text {
      width: 450px;
    }
  }

}

// Animation classes
.fade.ng-enter, .fade.ng-show {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  animation: fadein 1s;
}

.fade.ng-leave {
  -webkit-animation: fadeout 400ms;
  animation: fadeout 400ms;
}

.fade-n-grow.ng-enter {
  -webkit-animation: fadein 300ms;
  animation: fadein 300ms;
}

.fade-n-grow.ng-leave {
  -webkit-animation: fadeout 100ms;
  animation: fadeout 100ms;
}

tr.fade-n-grow:hover {background-color: $table-content-hover;}

.modal-fadein {
  -webkit-animation: fadein 300ms;
  animation: fadein 300ms;
}

.rollout-n-fade.ng-enter {
  -webkit-animation: rollout 1s;
  animation: roolout 1s;
}

.animate-bloat {
  -webkit-animation: bloat 300ms ease 1;
  animation: bloat 300ms ease 1;
}

//Animations

@-webkit-keyframes bloat {
	50% { font-size: 16px; }
}

@keyframes bloat {
	50% { font-size: 16px; }
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}


@keyframes grow {
  from { background-color: black;  }
  to   { background-color: #171819 ; }
}
@-webkit-keyframes grow {
  from { background-color: black; }
  to   { background-color: #171819 ; }
}


@keyframes shrink {
  from { background-color: #171819 ;}
  to   { background-color: black; }
}
@-webkit-keyframes shrink {
  from { background-color: #171819 ;}
  to   { background-color: black; }
}


@keyframes rollout {
  from { margin-right: -400px; opacity: 0.2; white-space: nowrap; }
  to   { margin-right: 0; opacity: 0.9; white-space: nowrap; }
}

@-webkit-keyframes rollout {
  from { margin-right: -400px; opacity: 0.2; white-space: nowrap;}
  to   { margin-right: 0; opacity: 0.9; white-space: nowrap;}
}


@keyframes colorchange {
  50% {
    color: #459c6a;
  }
}
@-webkit-keyframes colorchange {
  50% {
    color: #459c6a;
  }
}

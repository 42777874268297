$modal-background: #2c2f38;
$modal-border: #ffffff;
$separator-color: #545a66;

$title-color: #ffffff;
$highlight-color: #ffffff;
$modal-text-color: #cacaca;

$cancel-text-color: #81878c;
$submit-text-color: #33373f;
$submit-color-disabled: #5f6570;
$submit-bg-color: #98e1ee;
$submit-hover-bg-color: #69a6b1;
$input-focus-bg-color: #22252b;

$invalid-color: #d73939;
$border-color-error: #d73939;
$list-item-bg-hover: #151719;

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .modal {
    position: relative;
    margin: 20vh auto 0;
    padding: 25px;
    z-index: 102;
    flex: none;

    border: 1px solid $modal-border;
    border-bottom: 3px solid $modal-border;
    border-radius: 6px;

    color: $modal-text-color;
    background-color: $modal-background;
    font: 13px Helvetica;
    text-align: left;

    .title {
      font: 18px Helvetica bold;
      color: $title-color;
      margin-bottom: 25px;
    }

    .icon-X.close-modal {
      position: absolute;
      top: 29px;
      right: 26px;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: $highlight-color;
      }
    }

    .row {
      margin-bottom: 10px;

      input, textarea {
        margin-top: 5px;
        background-color: $modal-background;
        color: $highlight-color;
        border: 1px solid $separator-color;

        &:focus {
          background-color: $input-focus-bg-color;
          border-color: $modal-border;
        }

        &:not(:focus).ng-invalid.ng-dirty {
          border: 1px solid $border-color-error;
        }
      }

      input[type='search'] {
        height: 20px;
        font-size: 14px;
      }

      input[type='text'], input[type='email'], input[type='password'],
      input[type='url'], input[type='number'], input[type='file']{
        height: 40px;
        padding-left: 10px;
      }

      // override autofill css
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $modal-background inset;
        -webkit-text-fill-color: $highlight-color;

        &:focus {
          -webkit-box-shadow: 0 0 0 50px $input-focus-bg-color inset;
        }
      }

      label {
        .error-messages {
          display: inline-block;
          margin-left: 5px;

          .error {
            font-size: 12px;
            display: inline-block;
          }
        }
      }

      ul {
        list-style-type: none;
        padding: 5px;
        margin: 0 20px 0 auto;

        li {
          cursor: default;
          padding: 8px;

          &:hover {
            background-color: $list-item-bg-hover;
          }

          span {
            font-size: 14px;
          }

          .ui-select-highlight {
            color: $highlight-color;
          }

          button {
            float: right;
            line-height: 0.8;
          }
        }
      }
    }

    .control-buttons {
      padding-top: 20px;
      width: 100%;
      border-top: 1px solid $separator-color;

      .submit-btn {
        font: 14px Helvetica bold;
        padding: 6px 10px;

        float: right;
        cursor: pointer;
        color: white;
        background-color: $amobee-blue;
        border: 1px solid $amobee-blue;
        border-radius: 4px;
        transition: .5s color;

        &:hover {
          color: black;
        }

        &:disabled {
          cursor: default;
          color: $submit-color-disabled;
          border: 1px solid $submit-color-disabled !important;
          background-color: transparent;
        }
      }

      .cancel-btn {
        font-size: 14px;
        margin-right: 10px;
        margin-top: 7px;

        float: right;
        cursor: pointer;
        color: $cancel-text-color;

        &:hover {
          color: $modal-text-color;
        }
      }
    }
  }
}

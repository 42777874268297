am-input[type=checkbox-group] {
  text-align: justify;
  display: block;
  overflow: hidden;
  font-family: helvetica;

  .option {
    display: inline-block;
    width: 63px;
    line-height: 1em;
    text-align: center;
    border: 1px solid;
    margin-bottom: 13px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    padding: 6px 3px;

    &.selected {
      border: 1px solid white;
      color: white;
    }
  }

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0;
    background-color: darkred;
  }
}
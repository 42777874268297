$icon-size: 27px;

top-chart {

  @include flex_fit_and_fill();
  @include flex_direction(column);
  flex: 0 1 auto;
  background-color: transparent;

  .top-chart-legend {
    @include display_flex();
    @include flex_direction(row);
    flex: 1 1 auto;
    position: relative;
    align-items: flex-start;
    height: 25px;
    max-height: 25px;
    min-height: 25px;
    margin: 40px 20px 18px 20px;
    .top-chart-title {
      font-family: Helvetica, Arial, sans-serif;
      color: $regular-color;
      font-size: 18px; // 14px;
      font-stretch: normal;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
    }
    .top-chart-description {
      margin-left: 10px;
      font-family: Helvetica, Arial, sans-serif;
      color: $click-color;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 25px;
      text-align: end;
      vertical-align: bottom;
    }
  }

  .chart-container {

    background: transparent;
    position: relative;
    margin: 0 18px 0 18px;
    order: 0;
    padding-right: 15px;

    .split-bar-chart {

      fill: transparent;
      background-color: transparent;
      overflow-y: visible;
      width: calc(100% + 70px);

      .split-bar-cluster {

        fill: transparent;
        background-color: transparent;
        text-anchor: start !important;

        // Hover or selecting the .group-background will make the .graph-button visible
        .group-background.hover ~ .graph-button, .group-background.selected-row ~ .graph-button {
          visibility: visible;
        }

        // Hover or selecting the .group-background will make the .cluster-label highlighted
        .group-background.hover ~ .cluster-label, .group-background.selected-row ~ .cluster-label {
          fill: #fff;
        }

        .group-background.hover, .group-background.selected-row {
          fill: #151719;
        }

        .graph-button {
          visibility: hidden;
          cursor: pointer;
        }

        .graph-button-rect, .group-background.parent-background {
          cursor: pointer;
        }

        .remove-button {
          alignment-baseline: central;
          cursor: pointer;
          //font-family: helvetica, sans-serif;
          font-size: 15px;
          font-stretch: normal;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          fill: $click-color;
          stroke: $click-color;
          text-anchor: start !important;
        }

        .remove-button:hover {
          fill: white;
          stroke: white;
        }

        .cluster-label {
          alignment-baseline: middle;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          font-family: Helvetica, Arial, sans-serif !important;
          fill: $click-color;
          text-anchor: start !important;
          -webkit-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;

          &.parent-label {
            cursor: pointer;
          }
        }

        .expand-children {
          cursor: pointer;
        }

        .split-bar-cluster-divider {
          stroke: $selection-background;
          stroke-width: 2;
          fill: $selection-background;
          shape-rendering: crispedges;
          pointer-events: none;
        }

        .split-bar {

          .split-bar-divider {
            stroke: $selection-background;
            stroke-width: 2;
            fill: $selection-background;
            shape-rendering: crispedges;
            pointer-events: none;
          }

          .share-bar {
            stroke: none;
          }

          .right-container, .left-container {
            pointer-events: bounding-box;
          }

          .text {
            alignment-baseline: central;
            cursor: default;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            fill: $click-color;
            &.left {
              text-anchor: end;
              &.hidden {
                display: none;
              }
            }
            &.right {
              text-anchor: start;
            }
            &.percent {
              font-size: 13px;
            }
          }
        }

        .cluster-content {
          visibility: visible;
          transition: visibility 250ms;
        }

        &.open {
          .cluster-content{
            visibility: hidden;
          }
        }
      }

      .sub-topic-background {
        visibility: hidden;
        fill: #151719;
        stroke: #111111;
        stroke-width: 1px;

        &.visible {
          visibility: visible;
        }
      }
    }

    .split-bar-header {
      height: 31px;
      .split-icon {
        color: $regular-color;
        background-color: transparent;
        border: 1px solid black;
        border-top: 2px solid black;
        border-radius: 4px;
      }
      font-family: Helvetica, Arial, sans-serif !important;
    }
  }
}

#program-settings-overlay {
  #program-settings-modal {
    #dashboard-interests {
      height: 351px;
      overflow-y: visible;

      .row {
        overflow: hidden;

        .error-messages {
          display: block;
          margin-left: 0;
        }

        input[type='text'] {
          width: 500px;
          float: right;
        }

        label {
          display: inline-block;
          margin-top: 15px;
        }
      }

      ul {
        list-style-type: none;
        position: absolute;
        padding: 10px;
        margin: 0 auto;
        width: 500px;
        right: 25px;
        z-index: 103;

        font-size: 14px;
        color: $cancel-text-color;
        background-color: rgba(15, 17, 19, 0.9);

        li {
          cursor: pointer;
          padding: 10px;

          &:hover {
            background-color: black;
          }

          .ui-select-highlight {
            color: $highlight-color;
          }
        }
      }
    }
  }
}
am-map-component {
  .map-container {
    width: 100%;
    height: 100%;

    .id_, .id_0 {
      fill: #33383b !important;
      stroke: none !important;
    }

    #selected-zone {
      stroke: #fff !important;
      stroke-width: 2px !important;
    }

    &:active {
      cursor: all-scroll;
    }
  }

  .buttons-container {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 15px;
    display: block !important;

    .zoom-button.out {
      border-radius: 0 0 4px 4px;
    }
    .zoom-button.in {
      border-radius: 4px 4px 0 0;
    }

    .zoom-button {
      height: 24px;
      width: 23px;
      cursor: pointer;
      background-color: #202427;
      border: 1px solid black;
      text-align: center;

      i {
        width: 100%;
        height: 100%;
        font-size: 10px;
        line-height: 24px;
      }

      &:hover {
        background-color: #151719;
        i {
          color: #ffffff;
        }
      }
    }
  }

  .popup-container {
    display: none;
    position: absolute;

    .arrow-down {
      margin-top: -1px;
      width: 0;
      height: 0;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
    }

    .arrow-fill {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #cacaca;
    }

    .arrow-border {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #000000;
    }

    .map-popup {
      border-radius: 3px;
      border: 1px solid #000000;
      font: 14px Helvetica;
      padding: 10px;
      background-color: #cacaca;

      .area-popup-title {
        margin-bottom: 10px;
        font: 14px Helvetica bold;
      }

      b {
        font: 14px Helvetica bold;
      }

      .area-popup-body {
        margin-bottom: 10px;
      }

      span {
        display: block;
        color: #000000;
      }
    }
  }

  .show-popup {
    display: block;
  }
}

@import "src/infra/style/base";
@import "src/infra/style/style_guide";
@import "src/common/style/flexbox";
@import "../base_widget";

news-widget {
	display: block;
	height:100%;
	width: 100%;
	position: relative;
	.pagination {
		position: absolute;
		top: 10px;
		right: 15px;
		width: 80px;
		height: 18px;
		color: white;
		border: 1px solid black;
		.arrow-wrapper-left{
			height: 15px;
			width: 15px;
			position: absolute;
		}
		.arrow {
			position: absolute;
			top: 3px;
			width: 0;
			height: 0;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			cursor: pointer;
		}
		.arrow-wrapper {
			cursor: pointer;
			height: 17px;
			width: 15px;
			position: absolute;
			-webkit-user-select: none; /* webkit (safari, chrome) browsers */
                        &:hover {
				.arrow {
					border-left-color: white;
					border-right-color: white;
				} 
					
			}
		}
		.arrow-wrapper-right {right: 0; }
		.arrow-wrapper-left { left: 0; }

		.arrow-right {
			right: 5px;
			border-left: 5px solid $second-btn; 
		}
		.arrow-left {
			left: 5px;
			border-right: 5px solid $second-btn;
		}
		.dots {
			height: 16px;
			width: 47px;
			position: absolute;
			text-align: center;
			right: 16px;
			color: $second-btn;
			background-color: $textbox-bg;
			cursor:default;
			.dot{
				width: 10px;
				display: inline-block;
				font-size: 20px;
				line-height: 0.8;
			}
			.selected { color: $second-btn-selected; }
		}
	}
	.items {
		padding: 5px 15px 0px 15px;
		display:block;
		height: calc(100% - 25px);

		.addborder {
			border-bottom: 1px solid $border-color;
		}
		.item {
			position: relative;
			height: calc(27% - 3px);
			font-size: 14px;
			width:100%;

			.article-text {
				position: absolute;
				top: calc(50% - 16px);
				color: $regular-color;
				width: 100%;
				height: 16px;
				span {
					cursor: pointer;
				}
			}
			.domain-text {
				position: absolute;
				top: calc(55%);
				color: $click-color;
				width: 100%;
				font-size: 12px;
				cursor: pointer;
				&:hover { color: white; }
			}
		}
	}
}

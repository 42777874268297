$selection-background: #151719;

trend-chart {

  margin-right: 0px;

  .hover-line {
    stroke: red;
    stroke-width: 2px;
  }

  .hover-date, .chart-index-rect {
    fill: $selection-background;
    stroke: $selection-background;
  }

  .hover-text, .chart-index-text {

    fill: white;
    font-family: Helvetica, sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    @extend .base_bold_font;
  }

  .trend-chart-circle{
    r: 9;
    cursor: pointer;
  }

  .trend-chart-text{
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
    text-anchor: middle;
    dominant-baseline: central;
  }


  .trend-chart-circle:not(.highlighted){
    fill: #202427;
  }

  .trend-chart-circle.highlighted{
    r: 11;
  }

  .trend-chart-text.highlighted{
    fill:#202427;
    background-color:#202427;
    stroke:#202427;
    cursor: pointer;
    font-size: 13px;
  }

  .axis {

    .domain {
      stroke: none;
    }

    text {
      fill: $click-color;
      font-family: Helvetica, sans-serif;
    }

    path, line {
      fill: none;
      stroke: #2e3236;
      shape-rendering: crispEdges;
    }
  }

  .y-axis.axis text {
    margin-right: 10px;
  }

  .x-axis.axis path {
    display: none;
  }

  .line {
    fill: none;
    stroke-width: 2px;
    // stroke-opacity: 0.8;
  }

  .hover-chart-line {
    stroke-width: 3px;
    // stroke-opacity: 1;
  }
}

@import "src/common/style/flexbox";
@import "src/infra/style/style_guide";
@import "src/infra/style/base";

brand-perceptions-widget {
  display: block;
  height: 100%;
  position: relative;
  .items {
    padding: 10px 15px 5px 15px;
    display: block;
    height: calc(100% - 25px);
    .item {
      position: relative;
      height: 25%;
      font-size: 14px;
      width: 100%;

      .brand-name {
        position: absolute;
        top: calc(50% - 7px);
        color: $regular-color;
        width: 30%;
        cursor: default;
      }

      .longText {
        //top: calc(50% - 16px);
      }
      .bar {
        position: absolute;
        top: calc(50% - 7px);
        left: 30%;
        color: $click-color;
        height: 16px;
        font-size: 12px;
        background: $second-btn;
      }
      .bar-value {
        color: white;
        position: absolute;
        top: calc(50% - 7px);
      }
      .brandBgColor {
        background: $amobee-blue;
      }
      .brandColor {
        color: $amobee-blue;
      }
      .fatBar {
        height: 24px;
        top: calc(50% - 11px);
      }
    }
  }
}

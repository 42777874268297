@import "infra/style/base_modal";
@import "common/am-range-slider.drv/am-range-slider.drv";

.demographics-modal {

  $table-border: 1px solid #373a44;

  @media (max-height: 799px) {
    .modal {
      padding: 15px !important;
    }
    .title {
      margin-bottom: 15px !important;
    }

    .icon-X.close-modal {
      right: 20px !important;
      top: 20px !important;
    }

    .bordered-row {
      height: 45px !important;
    }

    .cell-after-separator {
      margin-top: -5px !important;
    }

    .box {
      margin-left: -15px !important;
      margin-right: -15px !important;
    }

    .cell {
      padding: 14px 18px 13px 18px!important;
    }

    .income-slider {
      margin-top: -15px !important;
    }

    .dropdown-top-padding {
      margin-top: -8px !important;
    }

    .separator {
      height: 32px !important;
    }

    .geo-dropdown .scroll-container {
      max-height: calc(100vh - 570px) !important;
    }

    .ethnicity-dropdown .scroll-container {
      max-height: calc(100vh - 620px) !important;
    }
  }

  .modal {
    width: 800px;
    padding: 22px;

    .title {
      margin-bottom: 22px;
      font-size: 16px !important;
    }

    .control-buttons {
      border-top: 0 !important;
    }

    .box {
      border-top: $table-border;
      margin-left: -25px;
      margin-right: -25px;
      max-height: calc(100vh - 368px);
    }

    .bordered-row {
      border-bottom: $table-border;
      clear: left;
      height: 60px;
      position: relative;

      span.option {
        margin-right: 5px;
        &.selected {
          border-color: $audience-highlight-color !important;
        }
      }

      [class|=icon], span.option, div.option {
        color: $click-color;
        &.selected {
          i, div {
            color: $audience-highlight-color;
          }
        }
      }

      .geo-dropdown .scroll-container {
        max-height: calc(100vh - 690px);
      }

      .ethnicity-dropdown .scroll-container {
        max-height: calc(100vh - 680px);
      }

      am-select {
        summary.audience-skin {
          font-size: 14px;
          margin-top: 0.72em !important
        }
        .audience-skin {
          select-title {
            height: 32px;
            line-height: 33px;
          }
        }
        .menu-triangle {
          &:before {
            top: 28px;
          }
          &:after {
            top: 30px;
          }
        }
      }

      am-input[type=checkbox-group] {
        margin-top: 3px;
        .option {
          font-size: 14px;
          line-height: 8px;
          width: 54px;
        }

        z-index: 10;
        position: absolute;
        width: 475px;
        top: 12px;
        left: 18px;
      }

      am-input[type=radio-group] {
        border-color: $textbox-bg;
        background-color: none;
        line-height: 9px;
        font-size: 14px;
        .option {
          background-color: #2c2f38;
          color: $click-color;
          border-top: transparent;
          &.selected {
            background-color: $textbox-bg;
          }
        }
      }

      .cell {
        padding: 20px;
        float: left;
      }

      .cell-item {
        float: left;
      }

      .small-title {
        padding-left: 7px;
        font-size: 14px;
      }

      .income-slider {
        width: 500px;
        margin-top: -5px;
        padding-top: 0px !important;
      }

      .ethnicity {
        padding-left: 50px;
        padding-right: 20px;
        padding-top: 9px;
      }

      .separator {
        border-right: $table-border;
        margin-top: 6px;
        height: 50px;
        left: 115px;
        position: absolute;
      }

      .cell-after-separator {
        @extend .cell;
        position: absolute;
        left: 115px;
        padding-top: 15px;
      }

      .dropdown-top-padding {
        padding-top: 14px;
      }

      .dropdown {
        width: 234px;
      }

    }

  }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

responsive-bar-chart {
  width: 100%;
  height: 100%;

  .responsive-bar-chart-drv {
    height: 100%;
    display: flex;
    justify-content: space-around;
    position: relative;
  }

  element, .index-line-element {
    display: block;
    height: calc(100% - 65px);
    width: 100%;
  }

  .index-line-element {
    position: absolute;

    .index-line {
      width: 100%;
      position: absolute;
      bottom: -34px;
      border-top: 1px solid #444;
    }
  }

  element {
    position: relative;

    bar, label, value, .index-line {
      display: block;
      position: absolute;
    }

    label, value {
      width: 100%;
      text-align: center;
      white-space: nowrap;
    }

    bar {
      bottom: -34px;
      left: 50%;
      transform: translateX(-50%);
      min-height: 1px;
    }

    label {
      bottom: -60px;
    }
  }
}

hr {
  display: block;
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
}

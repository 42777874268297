@import "infra/style/base";
@import "../../common/style/flexbox";

@import "widgets/geo-widget/geo-widget";
@import "widgets/brand-index/brand-index-widget";
@import "widgets/news-ticker/news-ticker";
//override variables

@import "widgets/news-widget/news-widget";
@import "widgets/rt-associations-widget/rt-associations-widget";
@import "widgets/audience-widget/audience-widget";
@import "widgets/brand-perceptions-widget/brand-perceptions-widget";
@import "/bower_components/nvd3/nv.d3.css";
@import "widgets/drilldown-component/drilldown";

@import "../../common/style/flexbox";

$thin-widget-min-width: 248px; //210px;
$thick-widget-min-width: 766px;  //756px;
$short-widget-min-height: 185px;
$tall-widget-min-height: 378px;
$widget-border: 1px;
$padding-between-rows: 11px;
$padding-between-cols: 11px;
$width-little-width: 1335px;
$height-little-height: 662px;
$news-ticker-height: 29px;
$flex-box-basis: 100px;

.dashboard{
  height: 100%;
}
.widgets-container, .ngsb-container {
  // padding-right: $padding-between-rows;
  padding-left: $padding-between-rows + $widget-border;
  padding-top: $padding-between-rows + $widget-border;
  // padding-bottom:  $padding-between-rows + $widget-border * 2;
  //min-height: $tall-widget-min-height + $short-widget-min-height + 3 * $padding-between-rows + 4px;
  //min-width: 800px;
  box-sizing: border-box;
  *{box-sizing: border-box;}
  @include display_flex();
  -ms-flex-wrap: wrap;
  height: 100%;
  @include flex-flow(column wrap);
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-direction: row;
  -ms-flex-pack: justify;
  overflow: auto;
  /*little screen*/
  @media (max-width: $width-little-width) {
      @include flex-flow(row wrap);
      justify-content: flex-start;
      -ms-flex-align: start;
      -ms-flex-direction: column;
      overflow-y: scroll;
      //overflow-x: hidden;
  }

  @media (max-height: $height-little-height) {
    @include flex-flow(row wrap);
    justify-content: flex-start;
    -ms-flex-align: start;
    -ms-flex-direction: column;
    overflow-y: scroll;
    //overflow-x: hidden;
  }
}

@mixin widget {
	background-color:$background-color;
	position:relative;
}

@mixin thin-widget {
  /*regular screen*/
  width: calc(25% - #{$padding-between-cols});
  min-width: $thin-widget-min-width;
  /*little screen*/
  @media(max-width: $width-little-width) {
    width: calc(100%*1/3 - #{$padding-between-cols});
    position:relative;
  }

  @media(max-height: $height-little-height) {
    width: calc(100%*1/3 - #{$padding-between-cols});
    position:relative;
  }
}
@mixin thick-widget {
  /*regular screen*/
  width: calc(75% - #{$padding-between-cols});
  min-width: $thick-widget-min-width;
  position:relative;
  margin-bottom: $padding-between-rows;
  /*little screen*/
  @media (max-width: $width-little-width) {
    width: calc(100% - #{$padding-between-cols});
  }

  @media(max-height: $height-little-height) {
    width: calc(100% - #{$padding-between-cols});
  }
}

@mixin tall-widget {
  @include widget;
  height: calc(100%*2/3 - #{$padding-between-rows});
  min-height: $tall-widget-min-height;
}

@mixin short-widget {
  @include widget;
  height:  calc(100%*1/3 - #{$padding-between-rows});
  min-height: $short-widget-min-height;
  margin-bottom: #{$padding-between-rows};
}

@mixin bordered_widget {
  border: $widget-border solid black;
 // change to: #313538;
  box-shadow: inset 0 2px 1px -2px #DDDFD0;
}

.widget-1 {
  @include thick-widget;
  @include tall-widget;
  @include bordered_widget;
}

.widget-2 {
  @include thick-widget;
  @include short-widget;
  background-color: transparent;
  display: flex;
  .sub-widget-left{
    margin-right: $padding-between-cols;
  }
  .sub-widget-left,
  .sub-widget-right{
    @include bordered_widget;
    height: 100%;
    width: 50%;
    min-width: $thin-widget-min-width;
    background-color:$background-color;
    color: #202427;
    float: left;
  }
}

.widget-3 {
//  @include aside;
  @include thin-widget;
  @include short-widget;
  @include bordered_widget;
  @media(max-width: $width-little-width) {
    margin-right: $padding-between-cols;
  }

  @media(max-height: $height-little-height) {
    margin-right: $padding-between-cols;
  }
}

.widget-4 {
  @include thin-widget;
  @include short-widget;
  @include bordered_widget;
  @media(max-width: $width-little-width) {
    margin-right: $padding-between-cols;
  }

  @media(max-height: $height-little-height) {
    margin-right: $padding-between-cols;
  }
}

.widget-5 {
  @include thin-widget;
  @include short-widget;
  @include bordered_widget;
}

brand-index-widget {
  .header {
    background-color: $widget-dark;
    color: $highlight-color;
  }
  @include brand-index-graph-color($text-color);
}

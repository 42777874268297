am-sub-nav-button {
  margin: 0 !important;
  .sub-text {
    display: block;
    line-height: 3em;
    margin-left: 50px;
    margin-right: 15px;
    @extend .base_bold_font;
  }
  a {
    cursor: pointer;
    padding-left: 5px;

    i[class^=icon-] {
      margin-left: 8px;
      line-height: 43px;
    }
  }
  &:hover {
    color: white;
  }

  > .disabled {
    svg-inline > * {
      fill: $fade-color;
    }

    & > * > .sub-text {
      color: $fade-color;
    }

    & > * > i {
      color: $fade-color;
    }

    &:hover {
      color: $fade-color;
    }
  }
}

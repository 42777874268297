
prediction{ 

	.dialog{
		top: 42px;
		left: 6.5%;
	    width: 84%;
	    padding-right:30px;
	    height: calc(100% - 96px);  
	    .title{
	    	font: 16px helvetica bold;
			border-bottom:0px;margin: 6px 0px 6px 10px;

		}
	    background-color:#2c2f38 !important;

	    .switch-buttons{
	    	margin-left:8px;
	    	.switch-button{font: 13px helvetica bold;}
	    }
	}

	.legend-tabs{margin-right:-12px;margin-top:-12px;}

	.legend-tab{
		display:inline-block;
		padding:3px;
		width:190px;
		color:$regular-color;

		.legend-line{
			display:inline-block;
			border: 2px solid #fff;
		    height: 1px;
		    width: 16px;
		    vertical-align: middle;

		    &.prediction{border:2px solid #62C2DA;}
		    &.prediction_top{border:1px dotted #62C2DA;}
		    &.prediction_bottom{border:1px dashed #62C2DA;}
			}
	}

	

    .loading-img{top:50%}

	#interests{
		height:67px;
		padding:7px;
		vertical-align: middle;
		color:$regular-color;margin-left:10px;
	}

	input-bar{
		display:inline-block;
		background:transparent;
		width:calc(100% - 90px);
		border-width:0px;
		outline: 1px solid #545a66;
		padding-top: 11px;
		height:40px;
		min-height:auto;

		margin-top:50px;

		&.input-mode{outline: 1px solid #fff !important; color: #fff;background: #22252b !important;}

		tags, .tags-input{width: calc(100% - 90px);}


		tag{margin-bottom:4px;}
		label{padding-top: 12px;}
		results{top:-466px;}
	}

	
	#prediction-chart{
		&.loading{opacity:0.3}
		height:calc(100% - 206px) !important;
		margin-top:40px;
	}
}

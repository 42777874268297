am-sub-nav {
  position: absolute;
  top: 0px;
  left: 74px;

  .sub-buttons {
    opacity: .98;
    font-size: 14px;
    background-color: #111111;
    border: 1px solid white;
    border-bottom: 3px solid white;
    border-radius: 5px;
  }
}

am-sub-nav:before {
  display: block;
}

am-sub-nav{
  .sub-buttons:before {
    left: -5px;
    top: 43%;
    height: 13px;
    width: 13px;
    box-shadow: -1px 2px 0px 0px white;
    border-right: none;
    border-top: none;
    border-radius: 0px 0px 0px 2px;
    content: " ";
    background: #111;
    position: absolute;
    pointer-events: none;
    transform: rotate(45deg);
  }
}

am-sub-nav.buttons-1 {
  top: 1px;
  .sub-buttons:before {
    top: 35%;
  }
}
am-sub-nav.buttons-2 {
  top: -21px;
}
am-sub-nav.buttons-3 {
  top: -40px;
}

menubar.open am-sub-nav {
  left: 215px;
}

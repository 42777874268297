$border-color: #2e3138;
$best-ratio: 1.6180339;
$modal-height: 250px;
$modal-width: 370px;
$dark-bg: #151719;
$modal-inner-padding: 20px;
$modal-icon-size: 50px;

#forgot-password-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  height: 100%;
  width: 100%;

  .modal {
    position: relative;
    margin: 0 auto;
    padding: 27px 30px 30px 30px;
    top: calc(50% - 120px);

    border: 1px solid white;
    border-bottom: 2px solid white;
    border-radius: 8px;

    z-index: 100;
    width: 440px;
    height: 240px;

    color: $text-color;
    background-color: #2c2f38;
    opacity: 0.95;
    font: 14px Helvetica;

    #body-modal {
      height: 100%;
      position: relative;
      .title {
        min-width: 220px;
        font: 700 18px helvetica;
        color: $title-color;
        margin-bottom: 30px;
        border-bottom: 1px solid $border-color;
      }
      .control_buttons {
        position: absolute;
        right: 0;
        bottom: 1px;

        .submit-btn {
          float: right;
          padding: 7px 14px 7px 14px;
          font: 14px helvetica;
          @extend .base_bold_font;
          cursor: pointer;
          &:disabled {
            color: #363a3d;
            cursor: default;
            border: 1px solid #363a3d;
            &:hover {
              background-color: transparent;
            }
          }
        }

        .close-btn {
          float: right;
          cursor: pointer;
          margin-right: 15px;
          margin-top: 8px;
          &:hover {
            color: $highlight-color;
          }
        }
      }

      .row {
        position: relative;
        margin-bottom: 10px;
        @extend .base_bold_font;
        label {
          top: 10px;
          color: $regular-color;
          padding: 0 0 5px 0;
        }
        input {
          padding-left: 10px;
          margin-top: 10px;
          height: 40px;
          background-color: #2c2f38;
          color: $regular-color;
          width: 100%;
          border: 1px solid grey;

          &:focus {
            background-color: #22252b;
            border-color: #ffffff;
          }
        }
      }

      .error-messages {
        display: inline-block;
        .error {
          font-family: "Helvetica";
          font-size: 13px;
        }
      }
    }
  }
}

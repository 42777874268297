plus-minus{
	color:$click-color;
	display:inline-block;

	.titletext{
		color:$regular-color;
		font-size: 12px;
		width:70px;
		margin-right:20px;
		margin-top:1px;
		display:inline-block;
		float:left;
	}

	.number{
		padding: 8px;
	    height: 30px;
	    font-size:13px;
	    background-color: #000;
	    display:inline-block;
	    
		input{
			width:40px;color:$selected-color;
			background-color: #000;
			border-color:#000;
			text-align:center;
			border:0px;
			&.def{color:$click-color}
		}

		.plus, .minus{color:#7F868A;cursor:pointer;}
		.minus{font-size:10px;}
	}
}
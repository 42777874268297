radiogroup-slider {
  // you can change this value, but don't move it because it is readen by the JS
  font: 14px Helvetica; 
  // when changing this, also change DOT_HEIGHT in the JS 
  $dot-font-size: 28px;

  $bar-top-space: 1px;
  $bar-height: 3px;
  display: inline-block;

  .radiogroup-slider {
    
    width: 100%;
    height: 24px;
    border: 10px solid #1f2126;
    border-radius: 15px;
    background: #1f2126;

    .values-container {

      height: 100%;
      width: calc(100% - 80px);
      left: 40px;
  	  position: relative;

      .bar {
        position: absolute;
        background: black;
        height: $bar-height;
        top: $bar-top-space;
        width: 100%; 
      }

      .dot {
        position: absolute;
        top: calc(#{$bar-top-space} - #{$dot-font-size} / 2);
        font-size: $dot-font-size;
        cursor: pointer;
        // increase clicking area
        padding-right: 10px;
        padding-left: 10px;
        margin-left: -10px;
        &:after {
          content: "\2022"
        } 
        &.selected {
          border: 4px solid #44b0c7;
          height: 14px;
          width: 14px;
          border-radius: 16px;
          background: #1B1D1F;
          margin-top: 8px;
          margin-left: -3px;
          padding: 0;
          &:after {
            content: ""
          }                     
        }
      }

      .label {
        position: absolute;
        top: 20px;
        white-space: nowrap;
        cursor: pointer;
        // increase clicking area
        padding-top: 10px;
        margin-top: -10px;
        &.selected {
          color: white;
        }        
      }

    }
  }
}

@import "../style/base";

$menu-closed-width: 60px;
$menu-open-width: 200px;

menubar {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  background-color: #151719;
  height: 100%;
  width: $menu-closed-width;
  z-index: 101;

  .info {
    height: calc(37% - 150px);
    min-height: 70px;
    max-height: 200px;
  }

  .menu-top {
    display: none;

    .amobee-red-flag {
      margin: 0 5px 0 15px;
      display: inline-block;
      width: 65px;
      height: 65px;
      background-color: $amobee-blue;

      .amobee-text {
        position: absolute;
        color: white;
        top: 45px;
        left: 5px;
        font-size: 12px;
        @extend .base_bold_font;
      }
    }

    .title {
      position: relative;
      padding-top: 25px;
      padding-bottom: 2px;
      white-space: nowrap;
      overflow: hidden;

      .text {
        @extend .base_bold_font;
        color: $highlight-color;
        max-width: calc(100% - 30px);
        display: inline-block;
      }
    }
  }

  .small-logo {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 17px;
    left: 22px;
  }

  .settings-btn {
    position: absolute;
    bottom: 20px;
    width: 100%;

    am-sub-nav {
      top: -43px;
      .sub-buttons:before {
        top: 65%;
      }
    }
  }


  &.open {
    box-sizing: border-box;
    width: $menu-open-width;

    sub-nav-button, .sub-nav-button { left: 215px; }

    .small-logo {
      display: none;
    }

    .menu-top {
      display: block;
    }

    svg-inline {
      margin-left: 20px;
    }
  }

}


@import "../../infra/style/base";
@import "../../infra/style/style_guide";

audience-gender {

  display: block;
  height: 100%;

  .audience-gender-drv {

    position: relative;
    height: 100%;
    width: 100%;
    font-size: 14px;

    .data {

      height: calc(100% - 10px);
      color: $regular-color;

      .left-side, .right-side{
        height: 100%;
        width: 50%;
        float: left;
        padding: 0 20px;
      }

      .male, .female {
        height: 100%;
        position: relative;

        &.highlighted {
          color: white;

          .value {
            font-size: 22px;
            font-family: "Helvetica Bold"
          }
        }
        
        img {
          position: absolute;       
          bottom: 90px;
          min-height: 72px;
        }
      }    

      .value, .label, img {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0); 
      }

      .value {
        bottom: 40px;
      }   

      .label {
        bottom: 15px;
      }   
    }

  }
}

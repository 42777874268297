@import "../../infra/style/base";
@import "../../infra/style/style_guide";
@import "../../common/tv-show-info.drv/tv-show-info.drv";

audience-tv-widget {

    $brick-width: 215px;

    .output-type-toggle {
      position: absolute;
      right: 195px;
      top: 5px;
      z-index: 1;
      button-icon {margin: 0 !important;}
      font-size: smaller;
    }

    .sort-toggle {
      position: absolute;
      right: 20px;
      top: 5px;
      z-index: 1;

      .sort-by-title {
        color: $highlight-color;
        padding-right: 10px;
        font-size: 16px;
      }

      am-toggle-button {
        font-size: 14px;
      }
    }

    .left-aligned-title {
      color: $title-color;
      height: 10px;
      font-size: 16px;
      @extend .base_bold_font;
      display: table;
      margin-right: 0 !important;
      margin-left: 0px !important;
      padding: 10px 10px 25px 20px;
    }

    .shows {
        &.empty { background-image: url("/images/common/tvNoDataImg.png"); }
        display: grid;
        width: 100%;
        margin: 0 auto;
        padding-top: 3px;
        padding-bottom: 20px;
        height: calc(100vh - 165px);
        overflow-y: scroll;
        grid-template-columns: repeat(auto-fill, minmax($brick-width, $brick-width));
        justify-content: center;
        grid-gap: 24px;
    }

    quick-table .loading-img {
        display: none;

    }

    quick-table .quick-table-wrap {
      margin: 0;
      width: 100%;
      height: calc(100vh - 185px);
    }

    .table-container {
      height: calc(100vh - 160px);
      padding-right: 5px;
    }


}

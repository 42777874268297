$border-radius: 3px;

am-input[type=radio-group][icon-to-label-map]:not([icon-to-label-map=""]){
  line-height: 10px;
}

am-input[type=radio-group] {
  border-radius: $border-radius;
  display: inline-block;
  background-color: #202427;
  //border: 1px solid #151719;
  border: 1px solid #202427;
  text-align: center;
  line-height: 25px;
  white-space: nowrap;
  font-family: helvetica;

  .option {
    display: inline-block;
    //height: 1em;
    background-color: #202427;
    line-height: 25px;
    cursor: pointer;
    border: 1px solid #202427;
    border-top: 2px solid #202427;
    border-bottom: none;
    border-right: 1px solid #151719;
    padding: 0 1em;

    .icon-div{
      float: left;
    }

    .label-div {
      float: left;
      padding-left: 8px;
    }
    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-right: none;
    }

    &.selected {
      border-color: black;
      color: white;
      background-color: #151719;

      &:first-child {
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border-bottom-right-radius: 0;
      }
    }
  }
}

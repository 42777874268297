@import "infra/style/base_modal";

$buttons-row-border-color: #21242a;

#alerts-settings-overlay {
  #alerts-settings-modal {
    width: 500px;
    margin-top: 15vh;

    #alerts-settings {
      margin-bottom: 20px;

      .row {
        textarea {
          width: 100%;
          outline: 0;
          padding: 10px;
          resize: none;
        }

        .buttons-row {
          display: inline-block;
          margin-left: 15px;
          border: 1px solid $buttons-row-border-color;
          border-radius: 4px;

          .tab-button {
            display: inline-block;
            cursor: pointer;
            padding: 4px 10px;
            border-right: 1px solid $buttons-row-border-color;
            border-left: 0;

            &:hover {
              color: $highlight-color;
            }

            &:last-child {
              border-right: none;
            }
          }
        }

        .chosenTime {
          color: $highlight-color;
          font-weight: bold;
          box-shadow: inset 0 2px 0 0 #000;
          border-width: 1px 0;
          background-color: $buttons-row-border-color;
        }

        .first {
          border-left: 1px solid $buttons-row-border-color;
          border-radius: 4px 0 0 4px;
        }

        .last {
          border-radius: 0 4px 4px 0;
        }

        .chosenFirst {
          border-left: 0 solid;
        }
      }

      .weekends {
        margin-top: 15px;

        .icon-tick_in_circle {
          color: $highlight-color;
          font-size: 15px;
          vertical-align: middle;
        }

        .icon-circle-o {
          font-size: 15px;
          vertical-align: middle;
        }

        .text-after {
          @extend .pointer;
        }

        .selected {
          .text-after {
            color: $highlight-color;
          }
        }
      }

      .select-style-wrapper {
        display: inline-block;
        margin-top: 5px;

        label {
          margin-right: 5px;
        }

        .select-style {
          display: inline-block;
          background-color: $modal-background;
          color: $cancel-text-color;
          border: 1px solid $separator-color;
          margin-right: 5px;

          select {
            padding: 5px 5px 6px;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            background: transparent none;

            &:focus {
              outline: none;
              background-color: $input-focus-bg-color;
              border-color: $modal-border;
              option {
                &:hover {
                  background-color: black;
                }
              }
            }

            &:disabled {
              cursor: default;
              color: $separator-color;
            }
          }
        }
      }
    }
  }
}
@import "widgets/bubbles-widget/bubbles-widget";
@import "widgets/streams-widget/streams-widget";
@import "common/modals/define-interest/define-interest";
@import "prediction";
@import "../../infra/style/style_guide";
@import "grid.discovery";

side-filters-menu {

  am-input[type=checkbox-text] span {
    font-family: Helvetica;
  }

  am-input[type=radio-group] {
    margin-bottom: 7px;
  }

  am-select {
    summary, select-title {
      font-size: 14px;
    }

    .meta-data {
      font-size: 9px;
    }

    select-title:hover, &.open select-title {
      color: $selected-color;
      border: 0;
    }
  }

  am-container {
    .top:hover, &.open .top {
      color: $selected-color;
    }
  }

  &[type=accordion] .summary {
    font-size: 14px;
  }
}

.bubble-view-wrapper {
  overflow: hidden;
  height: 100%;
  position: relative;

  .define-interest {
    width: 100%;
    display: inline-block !important;
  }

  .button-edit-tag {
    display: inline-block;
  }

  input-bar.main {
    border: 1px solid #000;
    outline: none;
    &.input-mode {
      border: 1px solid;
      .icon-Search {
        color: inherit;
      }
    }
    &.open {
      border-color: initial;
      i.icon-Search, i.demi-icon-pipe {
        color: #81878C;
      }
    }
    results {
      outline: none;
      border-bottom: 2px solid #fff;
      margin-top: 1px;
    }
  }
}

.no-margin-top {
  margin-top: 0 !important;
}

.custom-filter {
  .container {
    padding-top: 35px !important;
  }
  .filter-block {
    margin-bottom: 30px;
  }
}

.manual-phrase {
  font-size: 6px;
  color: #1FB0C4;
}

@import "../../widgets/base_widget";
@import "../../infra/style/base";
@import "./custom-axis-dialog/custom-axis-dialog";

landscape-widget {
  @include display_flex();

  height: 100%;

  .landscape-panel {
    @include display_flex();
    @include flex_direction(column);

    width: 100%;

    .controls {
      border-bottom: 1px solid #000;
      padding-top: 10px;
      padding-bottom: 10px;

      .control {

        display: inline-block;

        .association-control {
          height: 36px;
          margin-left: 20px;
          display: inline-block;

          .association-control-label {
            margin-right: 5px;
            height: 44px;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            color: #CACACA;
          }

        }

        am-dropdown-select {
          padding-top: 4px;

          .dropdown-select {
            .summary {
              .summary-text {
                display: inline-block;
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .axis-icon {
          display: inline-block;
          vertical-align: top;
          height: 43px;
          line-height: 43px;
          margin: 0 5px 0 15px;

          svg-inline {
            display: inline-block;
            vertical-align: middle;
            width: 17px;
            height: 17px;
          }
        }

        input-bar.search {
          width: 235px;
          border-color: #81878c;
          results {
            .results-scrollable {
              max-height: 350px;
            }
          }

          label {
            padding-top: 6px !important;
          }
        }

        .dropdown-custom-axis {
          text-align: center;
          padding: 5px 0 5px 0;

          .button-divider {
            border-bottom: 1px solid $click-color;
            margin: 0 10px 10px 10px;
          }

          .add-axis-button {
            font-size: 14px;
            padding: 6px 10px 6px 10px;
          }

        }
      }
    }

    .landscape-container {
      @include display_flex();
      @include flex_column();
      overflow-y: scroll;
      height: 100%;

      .landscape-chart-frame {
        @include display_flex();
        height: calc(100% - 65px);
      }
    }

  }

  & > .loader, & > .loader-overlay {
    display: none;
  }

  am-empty-screen-message {
    &.top-layer-no-terms {
      z-index: 1;
      height: 100%;
      position: relative;
    }
  }
}

@import "infra/style/base";
@import "infra/style/style_guide";
@import "common/style/transitions";
@import "common/modals/confirmation/modal";
@import "common/modals/password/reset-password";
@import "./common/permissions-panel.drv/permissions-panel.drv";

@import "../phrases/phrases";
@import "../programs/programs";
@import "../users/user-dialog/user-dialog";
@import "../users/bulk-user-dialog/bulk-user-dialog";
@import "../users/users";
@import "../domains/domain-dialog";
@import "../organizations/organization-dialog";
@import "../targets/targets";

.settings {
  text-transform: capitalize;
  .navigation {
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding: 10px 0 10px 0;
    line-height: 31px;

    .selected {
      @extend .base_bold_font;
      color: white;
      background-color: #151719;
      border: 1px solid #000;
      border-top: 2px solid #000;
      border-radius: 4px;
      outline: 0;
    }

    nav-button {
      padding: 0 10px 0 10px;
      margin: 0 5px 0 5px;
      cursor: pointer;
      z-index: 10;
      &:hover {
        color: white;
      }
    }
  }
}


  .domains-search{
    margin-top: -32px!important;

     .new-item-button {
        margin: -19px 29px 0 0 !important;
      }
  }


.settings-container {
  padding-top: 10px;
  width: 100%;
  margin: auto;

  .documentation{
    margin: 10px;
  }
  .table-content {
    height: calc(100vh - 105px);
    overflow-y: scroll;
    position: relative;
  }
  .domains-table{
    height: calc(100vh - 131px);

    th.domain-data,td.domain-data {
      width: calc(100vh/4);
    }

    th.domain-name,td.domain-name{
      width: calc(100vh/2);
    }
  }


  .search {
    // Search Filter
    position: relative;
    margin: -57px 10px 10px;

    input[type=search] {
      width: 250px;
      border: 1px solid $click-color;
      background-color: initial;
      color: $click-color;

      @include placeholder {
        color: $click-color;
        font-family: "Helvetica Bold",sans-serif;
        font-size: 13px;
      }
    }
  }

  .export-button {
    @extend .base_bold_font;
    float: right;
    font-size: 14px;
    padding: 7px 14px 5px 14px;
    margin: 5px 15px 0 0;
    &:focus {
      outline: none;
    }
  }

  .icon-Export {
    margin-right: 3px;
    margin-bottom: 3px;
    font-size: 12px;
  }

  .new-item-button {
    @extend .base_bold_font;
    float: right;
    font-size: 14px;
    padding: 7px 14px 7px 14px;
    margin: 5px 15px 0 0;
    &:focus {
      outline: none;
    }
  }

  form.settings-details {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    padding: 25px 15px 25px 10px;
    color: #888;
    border: 1px solid #E4E4E4;
  }

  .settings-details label {
    display: block;
    margin: 0;
  }
  .settings-details label > span {
    width: 30%;
    float: left;
    text-align: right;
    padding-right: 10px;
    margin-top: 6px;
  }

  .settings-details input[type='text'], .settings-details input[type='email'], .settings-details input[type='search'] {
    border: 1px solid #DADADA;
    color: #888;
    height: 18px;
    margin-bottom: 16px;
    margin-right: 6px;
    margin-top: 2px;
    outline: 0 none;
    padding: 3px 3px 3px 5px;
    width: 60%;
  }

  .settings-details input[type='checkbox'] {
    display: inline-block;
    margin-right: 5px;
  }

  .settings-details select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    width: 25%;
    height: 25px;
    line-height: 18px;
  }

  .settings-details input[type='button'], .settings-details input[type='submit'] {
    color: black;
  }

  table {
    border: 1px solid black;
    color: $regular-color;
    width: calc(100% - 20px);
    margin: 10px;
    background-color: $textbox-bg;
    text-align: left;

    .edit-btn {
      font-size: 10px;
      color: $second-btn;
      padding: 0 2px 0 1px;
      cursor: pointer;
      &:hover {
        color: $second-btn-hover;
      }
    }

    .share-btn, .delete-btn, .clone-btn {
      @extend .edit-btn;
      font-size: 14px;
    }

    .remove-btn {
      @extend .edit-btn;
      font-size: 18px;
    }

    i[class='icon-share'] {
      margin-right: 10px;
      margin-top: 2px;
      font-size: 16px;
    }

    .inActiveIcon {
      color: $disabled;
    }

    th {
      @extend .base_bold_font;
      border: 1px solid black;
      color: $title-color;
      font-size: 16px;
      padding: 0 10px;
      height: 60px;

      .sortorder {
        font-size: 8px;
        font-family: icomoon;
      }

      .sortorder.reverse:after {
        content: '\e608';
      }
      .sortorder:after {
        content: "\e606";
      }
    }

    td {
      border: 1px solid black;
      text-align: left;
      padding-left: 10px;
      height: 40px;

      .ui-select-highlight {
        @extend .base_bold_font;
        color: white;
      }
    }

    td.actions {
      text-align: center;
      width: 25px;
      padding: 0;
    }

    td.owner-actions {
      text-align: center;
      width: 90px;
      padding: 0;
    }
  }


  #domainsList, #targetlist{

         .tbody{
            height: calc(100vh - 200px)!important;
          }
  }

}

@import "src/infra/style/style_guide";

.news-ticker {
	border-top: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px solid black;
	border-top-right-radius:3px;
	border-bottom-right-radius:3px;
	position: absolute;
	bottom: 6px;
	left:65px;
	height: 25px;
	width: calc(100% - 80px);
	background-color: $textbox-bg;
}

.live-label {
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
	border-top-left-radius:4px;
	border-bottom-left-radius:4px;
	position: absolute;
	bottom: 6px;
	left:15px;
	height: 25px;
	width: 50px;
	color: $title-color;
	text-align: center;
  	@extend .base_bold_font;
	line-height: 1.6;
}

ul.marquee {
  /* required styles */
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1;
  position: relative;
  overflow: hidden;

  /* optional styles for appearance */
  width: 100%;
  height: 100%; /* height should be included to reserve visual space for the marquee */
}

ul.marquee li {
  /* required styles */
  position: absolute;
  top: -999em;
  left: 0;
  display: block;
  white-space: nowrap; /* keep all text on a single line */

  /* optional styles for appearance */
  font: 14px Arial, Helvetica, sans-serif;
  color: $regular-color;
	padding: 4px 0 0 10px;
  .date { color: $title-color; }
  span { cursor: pointer; }
}

ul.marquee li.emoji {
	padding: 0 0 0 10px;
}

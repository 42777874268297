@import "/bower_components/nvd3/nv.d3.css";
@import "/bower_components/qtip2/jquery.qtip.css";
@import "../../common/style/flexbox";
@import "infra/style/base";
@import "widgets/base_widget";
@import "common/charts/trend-chart";
@import "common/charts/vertical-bar-chart";

$control-trend-height: 16px;

consumption-index-widget {

  @include flex_default_layout();
  @include flex_column();
  @include flex_item_align(stretch);

  .message-frame {
    width: 100%;
    height: 50%;
    margin: 15% auto auto auto;
    .message-line {
      width: 100%;
      height: 14px;
      line-height: 20px;
      text-align: center;
      vertical-align: middle;
      font-family: Helvetica, sans-serif;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      color: $regular-color;
    }
  }

  .switch-button-label {
    margin-right: 15px;
  }

  .label-on {
    color: white;
  }

  .label-off {
    color: #81878C;
  }

  .consumption-trend-legend {

    margin: 15px 15px 10px 15px;
    @include display_flex();
    @include flex_direction(row-reverse);
    justify-content: space-between;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;

    .legend-data-in-process {
      float: left;
      margin: 28px 0px 0px 0px;
      float: left;
      height: 14px;
      font-family: Helvetica, sans-serif;
      font-size: 14px;
      position: absolute;
      z-index: 2;
      color: $click-color;
    }

    .legend-description {
      float: left;
      margin: 5px 8px 0px 0px;
      font-family: Helvetica, sans-serif;
      font-size: 14px;
      color: $click-color;
    }
    .clickable-container {
      margin: 0px 5px 0 10px;
    }

    .legend-right {
      @include display_flex();
      @include flex_direction(row-reverse);

      .legend-trends {
        @include display_flex();
        justify-content: flex-end;

        .legend-trend {
          @include display_flex();

          .legend-icon {
            margin: 5px 0px 0px 10px;
            float: left;
            height: $control-trend-height;
            width: $control-trend-height;
            vertical-align: middle;
          }
          .legend-title {
            margin: 5px 8px 0px 5px;
            float: left;
            font-family: Helvetica, sans-serif;
            color: $click-color;
            font-size: 14px;
            line-height: $control-trend-height;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .charts-frame {

    @include flex_default_layout();
    @include flex_row();
    margin-bottom: 10px;

    vertical-bar-chart {
      height: 100%;
      min-height: 100%;
      margin: 17px 16px 0px 0px;
    }

    trend-chart {
      @include flex_default_layout();
      @include flex_column();
      @include flex_item_align(stretch);
      margin: 0px 0px 0px 0px;
      z-index: 3;
    }
  }
}

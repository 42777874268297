#trending{
	
	/*top panels*/

  	.top_right{
  		position:absolute;
  		top: calc(#{$theme-height} + 5px);right:10px;
  		#search_phrase{
  			width:170px;
  			background:transparent;
  			border:1px solid $click-color;
  			padding-left:10px;
  			margin-right:-3px;
  			padding-right: 27px;
    		height: 30px;
    		border-radius:1px;
  		}
  		.icon-Search{position:relative;left:-19px;}

  		.icon-Export{display:none;}
  	}

  	#topbar-trending{
  		position:fixed;
  		top:15px;
  		right:80px;

  		.icon-refresh_new{
  			font-size: 14px;
		    margin-left: 10px;
    		margin-top: -2px;
		}

		.icon-Export{
			font-size:16px;
			margin-top: -3px;
    		margin-left: 14px;
    		margin-right: 6px;
		}

		#last-update{
			width: 55px;
			margin-right:7px;
			padding-right: 0px;
			}
  	}

  	
 
	/*tables*/

	.trend_half_title{
  		font-size:16px;font-weight:bold;
  		color:$title-color;
  		padding-left: 12px;
    	margin-bottom: -5px;
  		img{vertical-align: middle;}
  	}

  	.quick-table-wrap{
  		width:inherit;
  		margin: 10px 0 0 10px;}
	
	.column.count {
		width:     80px;
		min-width: 80px;
		max-width: 80px;
	}


	.column.velocity{
		width: 110px;
		min-width: 110px;
		max-width: 110px;
	}

	.column.actions{
		width:55px;
		padding-left:0px;
		padding-right:0px;
		text-align:center;

		.icon-plus{
			margin-top: 2px;
		    display: inline-block;
		    vertical-align: top;
	    }
	}
  	

  	.up{color:#21DA77;}
  	.down{color:#D15E5E;}
	
 

 	/*groups dialog*/

	#trending_groups_dialog{
		margin-left: -200px;
	  	width: 400px;
	  
		#trending_groups{
			width:100%;
			height:auto;
			border-top: 1px solid #333;
  			margin-top: 4px;
		}
	}


	/*blacklist dialog*/


	#blacklist_dialog{
		margin-left: -300px;
	  	width: 600px;
	  	height: 450px;
	}

	#blacklist{
		.column.name{min-width:380px;}
		.column.actions{width:100px}

		.gray_box{padding:0px 4px 0px 4px;}

		.tbody{height:400px;max-height:300px;overflow:auto;overflow-x:hidden}
		.data_table_title{text-transform:none}
	}
}

.open-filter-menu{
	.top_right{right:250px !important;}
}

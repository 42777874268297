/*temp style to make quick-table look like ohter settings tables*/
/*should remove when all settings tabs use quick-table*/


#domainsList.settings-table, #targetlist.settings-table{

        .row .thead{
          line-height: 45px;

         .column{
           line-height: 40px;
         }
        }


        .search, .search.selected{
          font-size: 14px;
          font-family: Helvetica;
          font-weight: normal;
          color: $text-color !important;
        }

        .search input{
            border: 1px solid $text-color;
        }

         .column-title{
            color: #ffffff;
         }

         .table-options-col{
            font-size: 14px !important;
            width: 100%;
            display: inline-block;
            font-weight: normal !important;
         }

         .thead{
            height: 60px !important;
            background-color: #151719;
            box-shadow: inset 0 0px 0px 0px #000;
            border-bottom-width: 1px !important;
            
            div{
              line-height: 45px;
              font-size: 16px;
            }

            .column.selected{
                font-weight: normal;
            }
         }

         .tbody{

            .row{
                 line-height: 20px;
                 &:nth-child(even){background-color:#151719;}
                 &:nth-child(odd){background-color:#151719;}
            }
            .row:hover{background:$table-content-hover;}

           .column{
             line-height: 25px;
             height: 40px;
             border-bottom: 1px solid #000;
           }

           .delete-txt{
              display: inherit;
              margin-left: 10px;
            }
         
         }


         ._delete{
            text-align: center;
            width: 25px;
            padding: 0;
            font-size: 10px;
            color: #81878c;
            padding: 0 2px 0 1px;
            cursor: pointer;
         }

    }
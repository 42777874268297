@import "../../common/style/flexbox";
@import "infra/style/base";
@import "widgets/base_widget";
@import "common/charts/top-chart";

$selection-background: #151719;

referrals-widget {
  @include flex_fit_and_fill();
  @include flex_direction(column);

  .top-container {
    min-height: 70px;
    border-bottom: 1px solid #000;

    .buttons-container {
      margin: 15px 15px 0px 0px;
      display: inline-flex;
      float:right;

      .control-button {
        background-color: #202427;
        border: 1px solid black;
        padding: 5px 10px;
        cursor: pointer;
      }

      .selected-control {
        background-color: $selection-background;
        padding: 5px 10px 5px 10px;
        cursor: default;
        color:$selected-color;
        @extend .base_bold_font;
      }
    }
    .top-chart-legend {
      display: inline-block;
      margin: 20px 20px 18px 0px;
      float:left
    }

    .title-container {
      float: left;
      margin: 15px 0px 0px 15px;

      .title {
        display: block;
        color: #ffffff;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
      }
      .description {
        color: #cacaca;
      }
    }
  }

  .referrals-graph-container {
    overflow-y: scroll;
  }
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

.irs {
  position: relative; display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.irs-line {
  position: relative; display: block;
  overflow: hidden;
  outline: none !important;
}
.irs-line-left, .irs-line-mid, .irs-line-right {
  position: absolute; display: block;
  top: 0;
}
.irs-line-left {
  left: 0; width: 11%;
}
.irs-line-mid {
  left: 9%; width: 82%;
}
.irs-line-right {
  right: 0; width: 11%;
}

.irs-bar {
  position: absolute; display: block;
  left: 0; width: 0;
}
.irs-bar-edge {
  position: absolute; display: block;
  top: 0; left: 0;
}

.irs-shadow {
  position: absolute; display: none;
  left: 0; width: 0;
}

.irs-slider {
  position: absolute; display: block;
  cursor: default;
  z-index: 1;
}
.irs-slider.single {

}
.irs-slider.from {

}
.irs-slider.to {

}
.irs-slider.type_last {
  z-index: 2;
}

.irs-min {
  position: absolute; display: block;
  left: 0;
  cursor: default;
}
.irs-max {
  position: absolute; display: block;
  right: 0;
  cursor: default;
}

.irs-from, .irs-to, .irs-single {
  position: absolute; display: block;
  top: 0; left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute; display: none;
  bottom: 0; left: 0;
  width: 100%; height: 20px;
}
.irs-with-grid .irs-grid {
  display: block;
}
.irs-grid-pol {
  position: absolute;
  top: 0; left: 0;
  width: 1px; height: 8px;
  background: #000;
}
.irs-grid-pol.small {
  height: 4px;
}
.irs-grid-text {
  position: absolute;
  bottom: 0; left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px; line-height: 9px;
  padding: 0 3px;
  color: #000;
}

.irs-disable-mask {
  position: absolute; display: block;
  top: 0; left: -1%;
  width: 102%; height: 100%;
  cursor: default;
  background: rgba(0,0,0,0.0);
  z-index: 2;
}
.lt-ie9 .irs-disable-mask {
  background: #000;
  filter: alpha(opacity=0);
  cursor: not-allowed;
}

.irs-disabled {
  opacity: 0.4;
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

/* SKIN */

/**** Amobee custom skin start *****/

span.irs-grid-pol.small {
  display: none;
}

span.irs-grid-text {
  font-size: 12px;
  padding-left: 0;
  color: $text-color;
}

span.irs-grid {
  margin-bottom: 7px;
}

span.irs-grid-pol {
  margin-top: -9px;
  background: #151719;
}

.irs-bar, .irs-line, .irs-slider {
  cursor: pointer;
}

span.irs-bar {
  height: 30px;
  top: 15px;
  background-position: 0 -50px;
  z-index: 2;
}

span.irs-line {
  height: 25px;
  top: 17px;
  z-index: 1;
}

$increased-slider-area: 8px;

span.irs-slider {
  z-index: 3;
  padding-right: 8px + $increased-slider-area;
 
  height: 15px + $increased-slider-area;
  top: 21px - $increased-slider-area/2;
  margin-left: $increased-slider-area / 2;
  &.from {
    margin-left: -$increased-slider-area / 2;  
  }
  background-position: (0 + $increased-slider-area / 2) (-120px + $increased-slider-area / 2);
  &:hover, &.state_hover {
    background-position: (0 + $increased-slider-area / 2) (-150px + $increased-slider-area / 2);  
  }
}

span.irs-line-mid,
span.irs-line-left,
span.irs-line-right{
  top: 8px;  
}

/**** Amobee custom skin end ****/

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
  background: url(../images/common/am-range-slider.drv/sprite-skin-amobee.png) repeat-x;
}

.irs {
  height: 40px;
}
.irs-with-grid {
  height: 60px;
}
.irs-line {
  height: 6px; top: 25px;
}
.irs-line-left {
  height: 6px;
  background-position: 0 -30px;
}
.irs-line-mid {
  height: 6px;
  background-position: 0 0;
}
.irs-line-right {
  height: 6px;
  background-position: 100% -30px;
}

.irs-bar {
  height: 6px; top: 25px;
  background-position: 0 -60px;
}
.irs-bar-edge {
  top: 25px;
  height: 6px; width: 7px;
  background-position: 0 -90px;
}

.irs-shadow {
  height: 1px; top: 34px;
  background: #000;
  opacity: 0.75;
}
.lt-ie9 .irs-shadow {
  filter: alpha(opacity=75);
}

.irs-slider {
  width: 8px; height: 15px;
  top: 21px;
  background-position: 0 -120px;
}
.irs-slider.state_hover, .irs-slider:hover {
  background-position: 0 -150px;
}

.irs-min, .irs-max {
  color: #c0c0c0;
  font-size: 10px; line-height: 1.333;
  text-shadow: none;
  top: 0; padding: 1px 3px;
  background: rgba(0,0,0,0.1);
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.lt-ie9 .irs-min, .lt-ie9 .irs-max {
  background: #3654b0;
}

.irs-from, .irs-to, .irs-single {
  color: #000;
  font-size: 10px; line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: rgba(255,255,255,0.8);
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.lt-ie9 .irs-from, .lt-ie9 .irs-to, .lt-ie9 .irs-single {
  background: #d8dff3;
}

.irs-grid-pol {
  background: #777;
}
.irs-grid-text {
  color: #e0e0e0;
}

.irs-disabled {
}

.audience-confirmation {
  #conformation-modal-overlay {
    .modal {
      border-radius: 6px;
      width: 420px;
      padding: 30px 30px 25px 30px;
      background-color: #2c2f38;

      #body-modal {
        overflow: inherit;
        height: auto;

        .title {
          @include user-select(none);
          margin: 0;
          padding-bottom: 30px;
          border: none;
          cursor: default;
        }

        .icon-X.close-modal {
          display: inline-block;
        }

        .row {
          @include user-select(none);
          width: calc(100% + 30px);
          height: auto;
          padding: 0 15px 30px 15px;
          margin: 0 0 25px -15px;
          border-bottom: solid 1px #545a66;

          label {
            font: 14px Helvetica, sans-serif;
            position: initial;
            padding: 0;

            span {
              line-height: 20px;
            }
          }
        }

        .control_buttons {
          position: initial;
          height: 30px;

          .submit-btn {
            &:hover {
              color: #2a2d36;
            }
          }

          .cancel-btn {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
@import "../../infra/style/style_guide";

$animation-time: 0.2s;
$triangle-width: 20px;

am-dropdown-select {
  width: 228px;
  line-height: 40px;
  height: 40px;
  display: inline-block;

  &.ng-invalid {
    .dropdown-select {
      .summary {
        border: 1px solid $border-color-error;
      }
    }
  }

  .dropdown-select {
    position: absolute;
    display: inline-block;
    width: inherit;

    &.open {
      .summary {
        border-color: #ffffff;

        .icon-Triangle_Down:before {
          transform: rotate(-180deg);
          transition: all $animation-time linear;
          color: #ffffff;
          transform-origin: center;
          margin-right: 2px;
          top: -1px;
        }
      }
      .menu-triangle:before, .menu-triangle:after {
        visibility: visible;
      }
      .options {
        display: block;
      }
    }

    .summary {
      border: 1px solid #81878c;
      background-color: inherit;
      width: inherit;
      height: inherit;
      cursor: pointer;
      color: #81878c;
      text-transform: capitalize;
      line-height: 36px;
      height: 36px;

      .summary-text {
        padding-left: 10px;
        font-size: 14px;
      }

      .icon-Triangle_Down {
        font-size: 11px;
        float: right;
        padding-right: 5px;

        &:before {
          position: relative;
          display: inline-block;
          transform: rotate(0deg);
          transition: all $animation-time linear;
          transform-origin: center;
        }
      }
    }

    .menu-triangle:after {
      content: '';
      display: block;
      position: absolute;
      top: 38px;
      left: calc((100% - #{$triangle-width}) / 2);
      width: 0;
      height: 0;
      border-color: transparent transparent #202427 transparent;
      border-style: solid;
      border-width: 10px;
      z-index: 5;
      opacity: 0.99;
      visibility: hidden;
    }

    .menu-triangle:before {
      content: '';
      display: block;
      position: absolute;
      top: 35px;
      left: calc((100% - #{$triangle-width}) / 2 - 1px);
      width: 0;
      height: 0;
      border-color: transparent transparent white transparent;
      border-style: solid;
      border-width: 11px;
      z-index: 5;
      visibility: hidden;
    }

    ul.options {
      position: absolute;
      width: inherit;
      border: 1px solid #ffffff;
      border-radius: 4px;
      font-size: 14px;
      list-style-type: none;
      margin: 20px 0 0 0 !important;
      background: $input-focus-bg-color;
      opacity: .95;
      text-transform: capitalize;
      z-index: 4;
      display: none;
      overflow-y: auto;
      padding: 5px;

      .tool-bar {
        padding: 0 10px;
        border-bottom: 1px solid #30343c;
        margin-bottom: 6px;
        height: 32px;
        line-height: 30px;
      }

      .option {
        position: relative; /*for ✓ align right*/
        display: block;
        @include user-select(none);
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 1em; /*left place to ✓*/
        color: #81878c;
        padding: 0;
        line-height: 30px;

        .text-with-button {
          position: initial !important;

          .label-container {
            padding-left: 10px;
          }
        }

        &:hover {
          text-overflow: initial;
          background-color: #151719;
          span {
            position: relative;
          }
        }
        i {
          float: left;
          margin-right: 0.5em;
          &:before {
            font-size: 1.2em;
          }
        }
        & > div {
          overflow: hidden;
        }
        am-input[type=checkbox] {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }

        &.selected, &:hover, &:hover.selected, &:hover.selected am-input {
          color: white;
        }

        &.indeterminate {
          color: $click-color;
          am-input[type=checkbox][indeterminate]::before{color: $click-color !important}
        }
      }
    }
  }
}

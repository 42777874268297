permissions-panel {

    side-filters-menu.dropdown {
      border: 0 !important;
      background-color: initial;
      display: inline-block;
    }

  .permissions-header {
    margin-bottom: 10px;
  }

  .error-messages {
    font-size: 0.99em;
  }

  .section-title {
    font: 16px Helvetica bold;
    color: #fff;
    margin-bottom: 25px;
    min-width: 110px;
  }

  am-switch-button {
    width: 290px;
    display: table-cell;
    .switch-button-label {
      text-transform: capitalize;
      padding-right: 8px;
    }
  }

  am-input[type="checked"] {
    .text-after {
      text-transform: capitalize;
    }
  }

  am-input[type="checked"].Off {
    color: #81878C;
  }

  am-input[type="checked"].On {
    color: #fff;
  }

  am-input[type="checked"][disabled] {
    color: #81878C;
  }

  .switch-button {
    min-width: 132px;
    margin-bottom: 5px;
    display: table;
  }

  .switch-button.multiple {
    //display: inline;
  }

  .switch-button.all {
    margin-top: 14px;
    max-width: 290px;
    margin-left: 13px;
    display: initial;
  }

  .clearfix:before, .clearfix:after {
    content: " ";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  .row.geo-channel-row {
    margin-bottom: 35px;
    background-color: $modal-background;
    display: table-cell;
    width: 290px;

    am-dropdown-select {
      display: inline;
      margin-left: 25px;
    }

    .geo-label {
      color: #fff;
      position: relative;
      left: 1px;
    }
  }

  .permissions-table {
    display: table;
    .permissions-group {
      margin-top: 10px;
      display: table-row;

      .permissions-group-title {
        font-size: 13px;
        font-weight: bold;
        display: table-cell;
        width: 110px;
      }
      .permissions-list {
        padding-top: 5px;
        padding-left: 10px;
        display: table-cell;

        .table {
          display: table;
        }

        .sub-applications {
          display: flex;
          margin-top: 6px;
          margin-bottom: 6px;
          margin-left: 10px;
          flex-flow: row wrap;
          margin-bottom: -5px;

          .sub-application {
            display: inline-table;
            margin-right: 7px;
            white-space: nowrap;
            margin-bottom: 10px;

            am-input {
              .icon-tick_in_circle, .icon-circle-o {
                font-size: 12px;
              }
            }

            &.indeterminate {
              am-input.On {
                color: #444d55;
              }
            }
          }
          .error {
            margin-left: 290px;
          }
        }
      }
    }
  }
}

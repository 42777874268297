dotted-slider{
	display: inline-block;
	width:190px;
	.range_back{position:relative;
				margin:auto;
				margin-bottom:5px;
				height:21px;
				background: #1B1D1F;
				border: 8px solid #222527;
				border-radius: 8px;
				}

	.dot{
		position: absolute;
		display:inline-block;
	    top: -11px;
	    color: #7F868A;
	   	font-size:26px;
	   	cursor:pointer;
    }

	input[type=range] {
		-webkit-appearance: none;
		width: 100%;
    	position: absolute;
		&:focus {outline: none;border:0px;}
	}
	input[type=range]::-webkit-slider-runnable-track {
		width: 100%;
		height: 0px;
		border:0px;
		cursor: pointer;
		border-radius: 0;
	}
	input[type=range]::-webkit-slider-thumb {
		-webkit-appearance: none;
		margin-top: -6px;
		border: 6px solid #fff;
		height: 16px;
		width: 16px;
		border-radius: 16px;
		background: #1B1D1F;
		cursor: pointer;
	}

	.titlemin, .titlemax{
		color:$regular-color;
		font-size:12px;
		display:inline-block;
		&.title{float:left;}
		&.titlemax{float:right;margin-right:4px}
	}
}
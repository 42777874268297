
#realtime{

	$box_title_height: 81px;

	/*sentiment colors*/

	.all_back{background-color:#fff;}
	.pos_back{background-color:#57D08D;}
	.neu_back{background-color:#2D76E1;}
	.neg_back{background-color:#D15E5E;}

	.all_text{color:#fff;}
	.down_text{color:$regular-color;}
	.pos_text{color:#62DA9A;}
	.neu_text{color:#2974e1;}
	.neg_text{color:#D15E5E;}

	.crums{color:$click-color;}

	.box_title_text_part{	
		display:inline-block;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
	}

	.table-options{top:46px;}

	.main-td{vertical-align:top}
    

    #tweets_examples_date{
		font: 14px Helvetica,Arial,sans-serif;
  			color:$regular-color;
  			margin-top:3px;
  			margin-bottom:-3px;
		}	

	
	.title{

		font-size:14px;
		i{
			color:$click-color;
			&:hover{color:$selected-color;}
		}

		.icon-Export{font-size:16px;margin-top:-4px;}
		.icon-maximize{font-size:11px;margin-left:5px;margin-right:5px}
		.icon-minimize{font-size:13px;margin-left:3px;margin-right:5px}
	}
	
 

	#groups{

		.quick-table-wrap{margin:0px 5px 5px 10px;}

		.column.socialmentions{width:170px; min-width:170px;vertical-align:top;cursor:auto !important}
		
		.tbody{
			max-height: 265px; height:265px;
			
			.column.sentiment{
				li{list-style-type:none}

			div{
				display:inline-block;
				margin:0px;
				height:8px;
				cursor:help;
				transition:all 0.8s ease-in-out;
				}
			}

			jq-sparkline{
				display:block;height:calc(100% + 14px);
			}

			.phrases_title{font-weight:bold;}

			.remove-phrase{font-size:7px;}
			
		}
	}

	#remove-phrase-dialog{left:16%;}

	#associations{
		display:inline-block;
		vertical-align:top;
		border-bottom-width:0px;
		
		.quick-table-wrap{
			margin:0px 10px 0px 5px;
			.quick-table{
				border-bottom-width:0px;
				.tbody{
					height:300px;
					.column {color: $click-color;}
				}
			}
		}

		.add{
			font-size:10px;		
    		margin-left: 5px;
    		margin-right: 5px;
		}
		
		.column.count{
			width: 90px;
    		max-width: 90px;
    		min-width: 90px;
		}

		.associations-title{color:#fff}
	
	}


	#tweets_examples{
		display:inline-block;

		margin: 0px 10px 0px 5px;
	
		#sentiment-icons{
			.img_8{vertical-align:middle;margin:1px;}
			.img_8.selected{width:12px;height:12px;}
		}

	    .icon-threedots{margin-right:-4px;}
	
		#tweets_examples_tweets{
			padding: 14px 0px 6px 0px;
			overflow:auto;
			overflow-x:hidden;
			color:$click-color;
			border-top:1px solid #303437;

			a{color:inherit;text-decoration:none !important}
		
			.tweets_examples_tweet{
				display: block;
				cursor:pointer;
				padding-bottom: 15px;
    			margin-bottom: 15px;
    			margin-right:0px;
				border-bottom:1px solid #303437;	

				img{margin:0px 12px 0px 12px;width:73px;height:73px;}
				.tweet_text{font-size:14px;color:$regular-color;}
				.tweet_footer{
					color:$click-color;
					margin: 4px 0px 4px 0px;

					.tweet_date{color:$regular-color}
				}


			}
		}
	}


	#main_graph{
		height:20px;
		margin: 5px 5px 0px 10px;

		#sentiment_graph_wrap{
			position:relative;
		}

		#main_graph_title_name{
			overflow: hidden;
		    vertical-align: middle;
		    text-overflow: ellipsis;
		    display: inline-block;
		}

		#realtime_or_fixedpoint a{
			color:#005580;
			text-transform:none;
			text-decoration:underline;
		}

		#graph_date{position:absolute; font:13px helvetica;color:$regular-color;}

		#max_graph{
			margin-top:-4px;
			opacity:0;
			margin-right:10px;	
			border: 1px solid #111;
			font-size: 14px;
			border-radius: 4px;
			background-color: #151719;

			.spike{
				display:inline-block;
				vertical-align:middle;padding: 2px 4px 2px 4px;	
				font: 13px Helvetica;
				font-size:13px;
				i{color:$selected-color;}
			}

			.max{cursor:pointer;font-weight:normal;}
		}

		.spikes{div{vertical-align: middle}}

		#summary_wrap{
			margin-left:24px;
		}

		#summary_graph{
			opacity: 0;
			#summary_total{font-size:16px;vertical-align:top}
			table td{
				color:$selected-color;
				font:14px helvetica;
				text-align:center;vertical-align:bottom;
				padding-bottom:4px;
				
				div{vertical-align: bottom}

				.bar{width:15px;margin-top:10px;margin-bottom:10px;}


				.summary_label{color:$regular-color;}
			}
		}
	}

}

.jqstooltip{
	width: auto !important;
	height: auto !important;
}

.modal-overlay.targets-modal {

  .modal{
    width:80vw;
    height:70vh;
  }

  .current-target{
    text-align:center;
    width:calc(100% - 380px);
  }

  .title-note{font-size:14px;margin-top:-16px;}

  .quick-table-wrap{
  	width:100%;

  	.quick-table{
    	.row{cursor:pointer;
      margin-bottom:0px !important;} /*overrides modal css*/
  	}
  
  }
}

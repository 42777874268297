@import "../../infra/style/base";
@import "../../infra/style/style_guide";
@import "../../common/segment-size.drv/segment-size.drv";
@import "../../common/audience-gender.drv/audience-gender.drv";
@import "../../common/am-responsive-bar-chart.drv/am-responsive-bar-chart.drv";
@import "../../common/age-gender-chart.drv/age-gender-chart.drv";
@import "../../common/am-donut-chart.drv/am-donut-chart.drv";
@import "../../common/am-2-values-donut.drv/am-2-values-donut.drv";

audience-demographics-widget {
  $controls-height: 35px;
  $space-between-boxes: 5px;
  height: calc(100% + 22px);
  width: 100%;
  display: block;

  .audience-demographics-widget {
    height: 100%;
    min-height: calc(548px - #{$controls-height});

    .controls {
      display: flex;
      height: $controls-height;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
      padding: 0 5px calc(#{$controls-height} - 30px) 5px;
    }

    $rows: 2;
    .box {
      width: calc(#{1 / $rows} * 100% - #{$space-between-boxes * 2});
      height: calc(#{1 / $rows} * 100% - #{$controls-height / $rows} - #{$space-between-boxes * 2});
      border: 1px solid #000;
      box-shadow: inset 0 2px 1px -2px #DDDFD0;
      background-color: #202427;
      display: block;
      position: relative;
      padding: 20px;
      float: left;
      margin: $space-between-boxes;

      &.small-box {
        width: calc(100% / 3 - #{$space-between-boxes * 2});
      }
    }

    .box-title {
      position: absolute;
      color: #fff;
      font-family: 'Helvetica Bold', Helvetica, sans-serif;
      font-size: 16px;
      top: 20px;
      left: 20px;
    }

    .gender {
      padding-bottom: 0;
      padding-top: 30px;
    }

    .responsive-bar-chart-container {
      height: 100%;
      width: 100%;
      padding-top: 20px;
      responsive-bar-chart hr {
        width: calc(100% - 12px);
        left: 6px;
      }
    }

    .gender-age {
      padding-top: 40px;
    }

    .with-children-chart-container {
      height: 100%;
      width: 100%;
      padding-top: 20px;
    }
  }
}
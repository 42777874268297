@import "../../infra/style/base";
@import "../../infra/style/style_guide";

am-2-values-donut {
  height: 100%;

  .am-2-values-donut {
    height: 100%;
    position: relative;

    svg {
      position: absolute;
      height: 100%;
      width: 100%;
    }

  }
}

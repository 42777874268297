@import "infra/style/base_modal";

.lifestyles-modal {
  .modal {
    width: 800px;

    .control-buttons {
      border-top: 0 !important;
    }

    .segments {
      clear: left;
      padding-top: 20px;
    }

    .title {
      margin-bottom: 6px !important;
      font-size: 16px !important;
    }

    am-select {
      max-height: calc(100vh - 460px);
      overflow-y: auto;

      .radio-buttons-triangle {
        display: none;
      }
      .scroll-container {
        background: initial;
      }
    }
  }
}

.clickable-container {
    &:hover, .label-on {color: white;}
}

am-switch-button {
  $switch-height: 21px;
  $toggle-vert-padding: 3px;

  .clickable-container {
    display: inline-table;
    width: inherit;
    cursor: pointer;
    color: #81878c;
    &:hover, .label-on {
      color: white;
      border-radius: 0.2em;

      .clickable-child {
        color: $selected-color !important;
      }
    }

    &.disabled, &.disabled span, &.disabled div {
      color: $disabled !important;
      cursor: pointer;
      &:hover, &:hover .clickable-child {
        color: $disabled !important;
      }
    }

    i {
      margin-right: 10px;
    }
  }

  .switch-button-label {
    padding-right: 15px;
    line-height: 23px;
  }

  .Switch, am-input[type=switch] {
    box-sizing: content-box;
    position: relative;
    display: inline-block;
    width: 57px;
    color: $click-color;
    height: $switch-height;
    border: #292f34 solid 1px;
    background: #151719;
    box-shadow: inset 0 2px 1px -2px #292f34;
    cursor: pointer;
    font-size: 12px;
    float: right;
    span {
      display: inline-block;
      width: 17px;
      text-align: center;
      line-height: $switch-height + 1px;
    }
    span.On {
      color: #dddee1;
      width: 31px;
      @include transition(color 0.16s step-end);
    }
    span.indeterminate {
      color: #444d55;
      @include transition(color 0.16s step-end);
    }
    .Toggle {
      position: absolute;
      top: $toggle-vert-padding;
      width: 23px;
      height: $switch-height - 2 * $toggle-vert-padding;
      border-radius: 2px;
      background: #434d54;

      z-index: 1;
      @include transition(all 0.15s ease-in-out);
    }
    &[disabled] {
      background-color: #363a3d;
    }

    &.Off .Toggle {
      left: 6%;
    }
    &.On .Toggle {
      left: 54%;
    }
  }
}

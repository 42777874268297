@import "src/infra/style/base";
@import "src/infra/style/style_guide";
@import "./drilldown-menu";

drilldown {
  position: absolute;
  right: 3px;
  top: 10px;
  display: block;
  height: 17px;

  &:hover {
    path {
      fill: $main-btn-bg-hover;
    }
  }

  svg-inline svg {
    width: 10px;
    height: 15px;
    float: right;
  }
  path {
    fill: $text-color;
  }
  .menu-container {
    visibility: hidden;
    float: right;
  }
  &:hover {
    .menu-container {
      visibility: visible;
    }
  }
}
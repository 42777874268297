@import "infra/style/base_modal";

#organization-settings-overlay {
  #organization-settings-modal {
    height: 85%;
    width: 1134px;
    margin-top: 9vh;

    form[name=editOrganizationForm] {
      height: 95%;
    }

    #organization-settings {
      height: calc(100% - 60px);
      overflow-y: scroll;
      position: relative;

      .row.text-input {
        width: 913px;
      }

      input {
        width: 100%;
      }

        .row.geo-channel-row {
          margin-bottom: 35px;
          background-color: $modal-background;

          .geo-filter {
            position: absolute;
            width: 228px;
            min-width: 194px;
            height: 40px;
            padding-left: 20px;
            z-index:100;
            overflow: visible;;

            am-select.open {
              overflow: visible;
              z-index: 100;
            }

            am-select {
              width: 228px;
              height: 100%;

              select-title {
                border: 1px solid $separator-color;
                background-color: inherit;
                i.icon-Triangle_Down {
                  color: $separator-color !important;
                }
              }
              &.open {
                ul {
                  visibility: visible;
                }
              }
              ul {
                margin-right: 0;
                margin-top: 11px;
                visibility: hidden;

                li {
                  padding: 0;
                  div {
                    padding-left: 15px;
                  }
                }
              }
            }
          }

          .geo-label {
            position: relative;
            color: #fff;
            left: 1px;
          }

        }

      .row.permissions-row {
        margin-bottom: 5px;
        margin-top: 25px;
        height: auto;
        overflow: auto;
        padding-right: 10px;
      }

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .control-buttons {
      .save-loader {
        margin-left: 15px;
        display: inline-block;
        width: 60px;
        height: 30px;
        cursor: default;
        float: right;
      }

      .buttons-wrap {
        float: right;
      }
    }
  }
}

a[download-excel] {
  /* for 'loading-spinner' */
  display: inline-block;
  background-size: 27px;
  background-repeat: no-repeat;
  width: auto;
  background-position: center;
  text-align: center;
  height: inherit;
  padding-right: 10px;
  padding-left: 10px;
}

$border-color: #FFF;
$highlight-color: #FFF;
$text-color: #81878C;
$input-text-color: #CACACA;
$header-footer-bg-color: #212425;
$header-footer-border-color: #050405;
$border-radius: 5px;

program-selector {
  line-height: 23px;
  color: $highlight-color;
  z-index: 50;
  cursor: pointer;
  -webkit-user-select: none;

  .input-selected {
    border-radius: 4px;
    border: 1px solid #21242A;
    font-family: "Helvetica Bold";
    font-size: 13px;
    text-transform: capitalize;
    padding: 0 .5em;

    &.open {
      background-color: #21242A;
      box-shadow: inset 1px 2px 2px black;
    }

    .icon-Program {
      float: left;
      margin-right: 10px;
      font-size: 12px;
    }

    .selected-label {
      span {
        vertical-align: middle;
      }
    }
  }

  .options {
    transition: opacity, transform 0.09s cubic-bezier(0.4, 0.5, 0.38, 0.82);
    border: 1px solid $border-color;
    border-bottom: 3px solid $border-color;
    border-radius: $border-radius;
    background-color: #292c33;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    min-width: 220px;
    margin-top: 10px;

    &.ng-hide {
      transform: translate(-50%, -5%);
      opacity: 0;
    }

    &:before, &:after {
      display: block;
      content: '';
      border: solid transparent;
      position: absolute;
    }
    &:before {
      border-width: 0 11px 11px 11px;
      border-bottom-color: $border-color;
      top: -12px;
      left: calc(50% - 11px);
    }
    &:after {
      border-width: 0 10px 10px 10px;
      border-bottom-color: $header-footer-bg-color;
      top: -10px;
      left: calc(50% - 10px);
    }

    input {
      line-height: 30px;
      padding: 10px 0 10px 20px;
      font-size: 14px;
      outline: 0;
      border: none;
      background-color: transparent;
      color: $input-text-color;
    }

    ::-webkit-input-placeholder {
      color: $text-color;
    }

    .top, .bottom {
      background-color: $header-footer-bg-color;
      position: relative;
      cursor: default;
    }

    .top {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom: 1px solid $header-footer-border-color;

      input {
        width: calc(100% - 40px);
      }

      .icon-Search {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: $text-color;
      }
    }

    .options-wrapper {
      padding: 0 20px 1px;
      max-height: 400px;
      overflow-y: overlay;

      .option {
        cursor: pointer;
        padding-right: 20px;
        border-bottom: 1px solid #3A3D42;
        color: $text-color;
        white-space: nowrap;
        line-height: 40px;
        font-family: "Helvetica Bold";
        font-size: 13px;
        position: relative;

        &:last-of-type {
          border-bottom: none;
        }

        .selected-icon {
          display: none;
          position: absolute;
          right: 0;
          font-size: 15px;
        }

        &:hover {
          color: $highlight-color;
        }

        &.selected {
          color: $highlight-color;

          .selected-icon {
            display: inline;
          }
        }
      }
    }

    .bottom {
      border-top: 1px solid $header-footer-border-color;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      input {
        width: calc(100% - 55px);

        &:hover:not(:focus) {
          cursor: pointer;
        }
      }

      ::-webkit-input-placeholder {
        font-family: "Helvetica Bold";
      }

      :hover::-webkit-input-placeholder {
        color: $highlight-color;
      }

      .separator {
        display: inline-block;
        height: 21px;
        vertical-align: middle;
        border-left: 1px solid #383d46;
      }

      .settings {
        width: 35px;
        height: 35px;
        padding-top: 6px;
        position: absolute;
        text-align: center;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $text-color;

        &:hover {
          color: $highlight-color;
          cursor: pointer;
        }
      }
    }

  }
}

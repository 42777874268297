@import "../../common/style/flexbox";
@import "infra/style/base";
@import "widgets/base_widget";
@import "common/charts/stacked-chart";
@import "common/charts/vertical-bar-chart";

$positive_color: #56cf87;
$normal_color : #2875e1;
$negative_color: #cf5b59;

$control-trend-height: 16px;

.positive-sentiment {
  background-color: $positive_color;
  stroke: $positive_color;
  fill: $positive_color;
}

.normal-sentiment {
  background-color: $normal_color;
  stroke: $normal_color;
  fill: $normal_color;
}

.negative-sentiment {
  background-color: $negative_color;
  stroke: $negative_color;
  fill: $negative_color;
}

sentiment-widget {

  @include flex_fit_and_fill();
  @include flex_column();
  @include flex_item_align(stretch);

  .message-frame {
    width: 100%;
    height: 50%;
    margin: 15% auto auto auto;
    .message-line {
      width: 100%;
      height: 14px;
      line-height: 20px;
      text-align: center;
      vertical-align: middle;
      //font-family: Helvetica, sans-serif;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      color: $regular-color;
    }
  }

  .chart-legend {

    margin: 15px 15px 10px 15px;
    float: right;

    .legend-description {
      float: left;
      margin: 5px 8px 0px 5px;
      //font-family: Helvetica, sans-serif;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      color: $click-color;
    }

    .legend-item {

      float: right;

      .legend-icon {
        margin: 5px 0px 0px 10px;
        float: left;
        height: $control-trend-height;
        width: $control-trend-height;
        vertical-align: middle;
      }

      .legend-title {
        margin: 5px 8px 0px 5px;
        float: left;
        font-family: Helvetica, sans-serif;
        color: $click-color; // #777d8b;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: $control-trend-height;
        vertical-align: middle;
      }
    }
  }

  .report-frame {

    @include flex_fit_and_fill();
    @include flex_row();
    @include flex_item_align(stretch);
    margin-bottom: 6px;
    margin-right: 6px;

    .charts-view-port {

      @include flex_default_layout();
      @include flex_direction(column);
      overflow-y: auto;
      flex: 1 1 100%;

    .charts-container {

      @include flex_default_layout();
      @include flex_column();
      @include flex_item_align(stretch);
      align-self: stretch;
      margin: 10px 0px 15px 0px;

      .chart-frame {

        @include flex_default_layout();
        @include flex_row();
        @include flex_item_align(stretch);
        margin-bottom: 10px;
        margin-top: 20px;

        .chart-legend {
          position: absolute;
          margin: -25px 0px 10px 29px;
          @extend .base_bold_font;
          color: $title-color;
        }

        stacked-chart {
          @include flex_default_layout();
          @include flex_column();
          @include flex_item_align(stretch);
          margin: 0px 15px 0px 10px;
          }
        }
      }
    }

    vertical-bar-chart {
      @include flex_fit_and_fill();
      @include flex_column();
      @include flex_item_align(stretch);
      flex: 1 0 auto;
    }
  }
}
@import "common/am-input-collection/am-switch.drv/switch-button";
@import "common/style/bordered-button";
@import "common/tooltip/tooltip";
@import "common/examples-popup/examples-popup";
@import "common/examples-popup/quick-refine-seed";
@import "common/discoveryViewSwitcher.drv/discoveryViewSwitcher.drv";
@import "common/am-switch-button/am-switch-button";
@import "exclusions-dialog/exclusions-dialog";

#bubble-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding-left: 70px;
  -webkit-user-select: none;

  am-switch-button {
    margin-right: 15px;
  }

  &.filters-small-screen {
    padding-left: 0;
  }

  .separator {
    border-left: $click-color 1px solid;
    height: 16px;
    margin-right: 15px;
  }
}

#balls-panel {
  height: calc(100% - 120px);
}

#balls {
  height: 100%;
}

am-discovery-view-switcher {
  position: absolute;
  left: -10px;
}

#exclusions-label {
  border-left: $click-color 1px solid;
  padding-left: 15px;
  padding-right: 15px;
  color: $click-color;
}

.small-screen {
  border-left: $click-color 1px solid;
  padding-left: 15px;
  padding-right: 15px;
  &.icon-Targeting {
    padding-right: 0;
  }
}

#targeting-label {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 15px;
  color: $click-color;
  border-left: $click-color 1px solid;
}

.legend-number {
  font-family: "Helvetica";
  font-size: 12px;
  fill: $regular-color;
  fill-opacity: 1;
}

.legend-title {
  font-size: 16px;
  @extend .base_bold_font;
  fill: $title-color;
  fill-opacity: 1;
}

#bubbles {
  stroke-width: 0;
}

.bubble {
  fill-opacity: 0.9;
  cursor: pointer;
  @extend .fill-color-transition;
}

.bubble-text {
  fill-opacity: 1;
  cursor: pointer;
  fill: #151719 !important;

  tspan {
    letter-spacing: -0.5px !important;
  }
}

.fill-color-transition {
  transition: all 1s linear;
  transition-property: fill, fill-opacity;
}

.heat-map-bubble {
  fill-opacity: 1;
  cursor: pointer;
  @extend .fill-color-transition;
}

.heat-map-text {
  fill: #151719;
  fill-opacity: 1;
  cursor: pointer;

  tspan {
    letter-spacing: -0.5px !important;
  }
}

.heat-map-text-seed {
  fill: #cacaca;
  fill-opacity: 1;
  cursor: pointer;

  tspan {
    letter-spacing: -0.5px !important;
  }
}

.audience-overlay {
  .heat-map-text-seed {
    fill: white;
  }
  .heat-map-block-1 {
    fill: #9bdec9 !important;
  }

  .heat-map-block-2 {
    fill: #75c0a8 !important;
  }

  .heat-map-block-3 {
    fill: #4db493 !important;
  }

  .heat-map-block-4 {
    fill: #26ac81 !important;
  }

  .heat-map-block-5 {
    fill: #16966d !important;
  }

  .heat-map-block-6 {
    fill: #158762 !important;
  }

  .heat-map-block-7 {
    fill: #187356 !important;
  }
}

.heat-map-block-1 {
  fill: #1a56d2 !important;
}

.heat-map-block-2 {
  fill: #5174b9 !important;
}

.heat-map-block-3 {
  fill: #6c789b !important;
}

.heat-map-block-4 {
  fill: #7e6267 !important;
}

.heat-map-block-5 {
  fill: #935352 !important;
}

.heat-map-block-6 {
  fill: #c14c48 !important;
}

.heat-map-block-7 {
  fill: #d13835 !important;
}

.trash {
  margin-top: 2px;
  font-size: 16px;
  cursor: pointer;
  color: $click-color;
  &:hover {
    color: $selected-color;
  }
}

.selected {
  &:hover {
    color: $selected-color;
  }
}

.clickable-container {
  cursor: pointer;
  color: #81878c;
  &:hover {
    color: white;
    border-radius: 0.2em;
  }
  display: inline-table;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lifestyles-dropdown-container {
  width: 212px;
  height: 20px;
  border-left: $click-color 1px solid;
  padding-left: 15px;

  .lifestyles-dropdown {
    $decrease-dropdown-width: 69px;

    position: absolute;
    width: 250px;
    margin-top: -7px;
    margin-left: -$decrease-dropdown-width / 2;
    &.open .icon-Triangle_Down {
      line-height: 8px !important;
    }
    .icon-Triangle_Down {
      line-height: 31px !important;
    }
    .menu-triangle {
      &:before {
        top: 27px;
      }
      &:after {
        top: 30px;
      }
    }

    .text-with-button {
      div {
        overflow: hidden;
        margin-right: 12px;
        text-overflow: ellipsis;
      }
    }

    summary.audience-skin, .audience-skin.menu {
      font-size: 14px;
      font-weight: 300;
      text-transform: none;
    }

    select-title {
      width: 250px - $decrease-dropdown-width;
      margin-left: $decrease-dropdown-width / 2;
      height: 29px;
      margin-top: 2px;
    }

    summary.audience-skin {
      margin-left: $decrease-dropdown-width / 2;
      @extend .ellipsis;
      max-width: 164px;
      height: 15px;
      margin-top: 0.7em;
    }

    .scroll-container {
      max-height: 40vh;
    }
  }
}

.icon-label {
  vertical-align: middle;
}

.clickable-container:hover .clickable-child {
  color: $selected-color !important;
}

.clickable-container.disabled:hover .clickable-child {
  color: $disabled !important;
}

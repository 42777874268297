@import "infra/style/base_modal";

#share-program-overlay {
  #share-program-modal {
    height: 457px;
    width: 460px;

    #share-program {
      height: 306px;
      overflow-y: scroll;

      .row {
        margin-bottom: 0;

        label {
          font-size: 14px;
        }

        .search {
          input[type='search'] {
            width: 350px;
          }
        }
      }
    }
  }
}

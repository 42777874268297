@import "infra/style/base";

$selection-background: #151719;

.discovery-view-switcher {
  @include display_flex();
  @include flex_direction(row);
  position: relative;
  align-items: flex-start;
  margin-left: 20px;
  color: $click-color;

  .control-button {
    background-color: #202427;
    border: 1px solid black;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    color: $text-color;
  }

  .control-button:hover {
    color: $selected-color;
  }

  .selected-control {
    background-color: $selection-background;
    padding: 5px 10px 5px 10px;
    cursor: default;
    color: $selected-color;
  }
}

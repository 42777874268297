.no-terms {
  text-align: center;
  position: absolute;
  height: calc(100% - 55px);
  width: 100%;
  z-index: -1;
}

.top-layer-no-terms {
  @extend .no-terms;
}

.empty-screen-img {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.empty-screen-msg {
  position: absolute;
  top: calc(40% + 150px);
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.empty-screen-msg-title {
  @extend .label-off;
  margin-bottom: 0;
}

.empty-screen-msg-text {
  @extend .label-off;
  margin-top: 6px;
}

@import "../../infra/style/base";
@import "../../infra/style/style_guide";
@import "../../common/am-gauge-chart.drv/am-gauge-chart.drv";

element-selector {
    $navigate-icon-height: 22px;
    $navigate-buttons-width: 20px;
    $border-width: 2px;
    $space-between-elements: 13px;
    $element-height: 130px;
    $element-width: 205px;
    $thin-element-height: 95px;
    $thin-element-width: 145px;
    $small-screen-height: 900px;

  .element-selector-drv {
    display: block;
    -webkit-user-select: none;
    color: $highlight-color;
    min-width: $element-width + $navigate-buttons-width * 2 + 3px;

    .navigate-icon-wrapper {
      display: block;
      font-size: $navigate-icon-height;
      width: $navigate-buttons-width;
      height: $element-height + 1px; // border
      position: relative;

      @media(max-height: $small-screen-height){
        height: $thin-element-height + 1px; // border
      }

      &.left {
        float: left;
      }

      &.right {
        float: right;
        padding-left: 8px;
      }

      .navigate-icon {
        position: absolute;
        top: calc(50% - #{$navigate-icon-height / 2});
        cursor: pointer;
        color: #81878c;

        &.disabled {
          cursor: default;
          color: $disabled;
        }

        &:hover:not(.disabled) {
          color: $highlight-color;
        }
      }
    }

    element {
      position: relative;
    }

    .elements-container {
      padding: $border-width $border-width 0 $border-width;
      float: left;
      width: calc(100% - #{$navigate-buttons-width * 2});
      height: #{$element-height + $border-width * 2 + 15};
      overflow-y: hidden;

      @media(max-height: $small-screen-height){
        height: #{$thin-element-height + $border-width * 2 + 15};
      }
  
      .scroll-container {
        display: flex;
      }

      am-gauge-chart .indicator-bar {
        background: $click-color;
      }

      .triangle-down {
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        bottom: -12px;
        border-top: 15px solid #ffffff;
        border-right: 10px solid transparent;
      	border-left: 10px solid transparent;
      }

      element {
        &:first-child {
          margin-left: 0px !important;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
      
      .element-container {
        display: inline-block;
        position: relative;
        height: $element-height;
        width: $element-width;
        box-shadow: 0 0 0 $border-width #545a66;
        border-radius: 4px;
        background-color: #2c3038;
        text-align: center;
        cursor: pointer;

        @media(max-height: $small-screen-height){
          height: $thin-element-height;
          width: $thin-element-width;
        }

        am-gauge-chart .bar {
          background: #3b3e42;
        }

        am-gauge-chart .indicator-bar {
          background: #cacaca;
        }

        &:hover, &.selected {
          box-shadow: 0 0 0 $border-width white;
          color: white;
          background-color: $background-color;

          .img-dark-overlay {
             background: transparent !important;
          }

          am-gauge-chart .indicator-bar {
            background: #ffffff;
          }

          am-gauge-chart .bar {
            background: #51555b;
          }

          .values-container {
            .values {
              color: white;
            }
          }
        }
        &.selected  {
          am-gauge-chart .indicator-bar {
            background: $audience-highlight-color;
          }
        }

        .image {
          img {
            width: 100%;
            @media(max-height: $small-screen-height){
              height: $thin-element-height;
            }
          }
          
          .img-dark-overlay {
            position: absolute;
            border-radius: 4px;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0,0,0,.65));
            height: 100%;
            width: 100%;
          }

          span {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-size: 16px;
            font-family: Helvetica Bold;
            @media(max-height: $small-screen-height){
              font-size: 14px;
            }
          }
        }

        #triangle-down {
          width: 0;
          height: 0;
          border-top: 15px solid red;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
        }
        
        .values-container {
          position: absolute;
          bottom: 0;
          width: 100%;
          @media(max-height: $small-screen-height){
            height: 30px;
          }

          .ts-bar {
            display: inline-block;
            width: 110px;
            margin-left: -65px;
            @media(max-height: $small-screen-height){
              width: 55px;
              margin-left: -70px;
            }
          }

          .values {
            display: inline-block;
            color: #cacaca;
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: 12px;

            @media(max-height: $small-screen-height){
              bottom: 8px;
              right: 8px;
            }

            .score {
              font-family: Helvetica;
            }
          }
        }
      }
    }

    .paging-bar {
      display: block;
      position: relative;
      float: left;
      width: 100%;
      height: 30px;
      padding-top: 11px;
      text-align: -webkit-center;

      i {
        @extend .icon-Circle_normal;
        cursor: pointer;
        display: inline-block;
        font-family: icomoon;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: inherit;
        margin: 0 3px;
        font-size: 11px;
        color: $click-color;
        cursor: pointer;
        -webkit-font-smoothing: antialiased;

        &.active {
          @extend .icon-Circle_on;
          color: white;
          cursor: default;
        }

        &:only-of-type {
          display: none;
        }
      }
    }
  }
}

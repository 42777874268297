
/*vars*/
$pane-tabs: 30px;

.content-drivers-pane{

  .stretch{width: calc(100% + 15px)}

  display:table;

  width: $content-driven;

  &.open.no-target{
    width: calc(#{$content-driven} - #{$pane-tabs});
    .pane-tabs{display:none;}
  }

  .loader-overlay{margin-bottom:-8px}

  height: calc(100% - 9px);
  float: right;
  border-top: 1px solid #121416;
  margin-right: -$content-driven + 30px;
  margin-left: 0.6em;

  a {
    font-size: 12px;
  }

  .pane-title {
    color: $title-color;
    border: 1px solid #121416;
    box-shadow: inset 0 0 1px #2e3133;
    border-top: 1px solid #33373D;
    height: 34px;
    line-height: 30px;
    padding: 1px .7em 0px 13px;
    background-color: #1c2022;
    font-size: 14px;
    border-bottom: 1px solid #000;
    font-family: "helvetica bold";
    overflow:hidden;
    .title-text {
      float: left;
    }

    .sub-title-icon {
      margin: 0 3px;
      float: left;
    }

    .content-drivers-sub-title {
      display: inline-block;
      width: 100px;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .icon{
      font-size: 13px;
      margin-right: 5px;
    }

    .icon-X_CLEAR {
      margin-right:3px;
      font-size: 8px;
      color:$click-color;
      &:hover{color:$selected-color;}

    }
    .point-on-word-icon {
      font-size: 10px;
      padding: 0 0.5em;
    }
    .word {
      color: #6b707d;
    }
  }

  .content {
    height: calc(100% - 26px);
    width: 100%;
    max-width: calc(#{$content-driven} - #{$pane-tabs});
    overflow: overlay;
    background-color: $main-btn-color-hover;
    border-left: 1px solid #121416;
    box-shadow: 0px 1px 0px #121416;
    padding-right: 15px;
  }

  .content-filters {
    float: right;

    am-input {
      font-size: 13px;
    }

    am-input[type=checked].content-filter {
      i {
        float: left;
        cursor: pointer;
        margin-right: 3px;
      }

      &.pos i{color: $positive-color;}
      &.neg i{color: $negative-color;}
    }
  }

  .pane-tabs{
    width: $pane-tabs;
    height:100%;


    .button{
      display:block;
      background:$main-btn-color-hover;
      padding:9px 7px 7px 6px;
      border-left: 1px solid #121416;
      border-bottom: 1px solid #121416;
      box-shadow: inset 1px 1px 1px #2e3133;
      height: 34px;

      &.selected{color:$selected-color;}

      i{font-size:13px;}
    }

    .pane-tabs-tail{
      background: $main-btn-color-hover;
      border-left: 1px solid #121416;
      border-bottom: 1px solid #121416;
      box-shadow: inset 1px 0 0 #2e3133;
      width: 7px;
      margin-top:-1px;
      float: right;
      height: calc(100% - 58px);
      &.no-target{height: calc(100% - 23px);}
    }
  }

  .pane-tab{
    display: table-cell;
    vertical-align: top;
    width: calc(#{$content-driven} - #{$pane-tabs});
  }

  .icon-Targeting{font-size:16px !important;}

  &:not(.open){
    .loader-overlay {display: none;}
  }
}

.content-drivers-pane.open {
  margin-right: 0;
}

.content-drivers-pane.ng-hide {
  margin-right: -$content-driven;
}

@import 'content-driver/content-driver.drv';


/*target drawer*/


.target_drawer{

  .target-drawer-title{
    width: calc(#{$content-driven} - 80px);
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }

  #input-select-target{margin:10px;}

  .folders{
    height:calc(100% - 52px);
    overflow:auto;
    overflow-x:hidden;
  }

  .folder-title{
    display:block;
    height:30px;
    padding:6px 8px 6px 12px;
    background: #2E3437;
    text-transform: capitalize;
    font: 14px helvetica;
    margin-bottom:1px;
  }

  .folder{
    &.open{
      .folder-title{color:#fff;}
    }

    &.disabled{
      .folder-title{
        color: #495055;
        .arrow{color: #495055 !important;}
        background:#272C2F;
      }
    }
  }

  .remove-all{
    text-align:center;padding:4px;
    a{font-size:14px;}
  }

  .no-results{
    width: 100%;
    text-align: center;
    margin-top: calc(50vh - 185px);
  }

  .folder-content{
    border:none;
    outline:none;
    margin-bottom:1px;
    background:transparent;
    overflow:auto;
  }

  .arrow{
    font-size:10px;
    margin-right:5px;
    padding-top: 4px;
    color:$click-color !important;}

  .preview{
      border-top: 1px solid $graph-axis;
      padding: 18px 9px 0px 6px;
      font-family: helvetica bold;
      a{font-size:14px;}

      .remove-all{
        font-family: Helvetica,Arial,sans-serif;
        font-size: 13px;
        font-weight: 700;
      }

      .icon-activate{
        font-size:14px;
        margin-left:2px;
      }
    }

  #folder-emojis {
    .emojis{
      margin-top: -32px;
      margin-right: -20px;
      margin-bottom: -31px;
      margin-left: -24px;
    }
  }
}

$background-color: #22252b;
$regular-color: #cacaca;
$font-color: #777d8b;
$click-color: #81878C;

@mixin user-select($value) {
  -webkit-user-select: $value;
  -khtml-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
}

@mixin not-in-use {
  opacity: 0.4;
  background-color: #4477C2;
}

@mixin hide-refine {
  .button-edit-tag {
    display: none;
  }
}

input-bar , .input-bar{
  @include user-select(none);
  display: block;
  position: relative;
  width: 100%;
  min-height: 3.4em;
  line-height: 1em;
  padding: 17px;
  background: $background-color;
  color: $click-color;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid;

  .demi-icon-pipe {
    display: block;
    float: left;
    font-size: 13px;
    margin-left: 1.2em;
    margin-right: .4em;
    border-left: 2px solid;
    min-height: 1.4em;
  }

  .input-bar-right-buttons {
    font-size: 13px;
    font-weight: 700;

    @media screen and (max-width: 1279px) {
      .text {
        display: none;
      }
    }
  }

  & > label {
    cursor: text;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 17px 0 17px 3.5em;
    overflow: hidden;
  }

  tags {
    display: inline-block;
    line-height: 1;
  }

  .simple-text {
    display: block;
    text-overflow: ellipsis;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 30px;
    font-size: 14px;
    letter-spacing: initial;

    .term-type {
      font-size: 14px;
    }
  }

  tag , .tag{
    @extend .base_bold_font;
    @include user-select(none);
    transition: all .5s; /* animation */
    position: relative;
    display: inline-block;
    height: 18px;
    border-radius: 2px;
    background-color: #2974e1;
    color: #1c2022;
    font-size: 14px;
    padding-left: .2em;
    margin: 0 5px;
    cursor: pointer;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;

    .text {
      @include user-select(none);
      vertical-align: middle;
    }

    .mention-wrapper {
      @include user-select(none);
    }

    .sub_text {
      font-size: 11px;
      vertical-align: top;
      display: inline-block;
      padding-top: 4px;
    }

    .invalid-message {
      font-size: 0.8em;
      color: black;
      text-transform: lowercase;
    }

    svg-inline {
      display: none;
      vertical-align: -0.12em;
      svg {
        width: 1em;
        height: 1em;
      }
    }

    /** tag state **/
    &.term-waiting {
      background-color: #A9A9A9;
      svg-inline {
        display: inline-block;
      }
    }
    &.invalid {
      background: gray;
      color: rgb(174, 168, 168);
      .button-add-tag {
        display: inline-block;
      }
    }
    &.tag-added {
      background-color: #008000;
      .icon-added {
        display: inline-block;
      }
    }

    &.overflow {
      @include not-in-use();
      @include hide-refine();
    }

    .button-add-tag, .button-remove-tag {
      @include user-select(none);
      position: relative;
      display: inline-block;
      width: 19px;
      line-height: 18px;
      background-color: rgba(0, 0, 0, .3);
      border: none;
      outline: 1px solid rgba(0, 0, 0, .2);
      text-align: center;
      vertical-align: middle;
      box-sizing: border-box;
      cursor: pointer;
      right: -.5px;
      font-size: 15px;

      &:hover {
        border-radius: 0.2em;
        color: white;
      }

      i {
        font-size: 11px;
      }
    }
    .button-remove-tag {
      bottom: 1px;
      padding-bottom: 1px;
    }
    .button-add-tag {
      display: none;
      margin-right: -4px;
    }

    .button-edit-tag {
      @include user-select(none);
      display: none;
      position: relative;
      width: 16px;
      line-height: 18px;
      font-size: 10px;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;
      border: none;
      border-right: 1px solid rgba(0, 0, 0, 0.5);

      &:hover {
        border-radius: 0.2em;
        color: white;
      }
    }
  }

  & > [class|="icon"] {
    float: left;
  }

  .tags-input {
    position: relative;
    padding-left: 1em;
    overflow: hidden;
  }

  textarea {
    resize: none;
    background: transparent;
    border: none;
    box-sizing: border-box;
    width: 100%;
    height:20px;
    padding:1px 0;

    &:focus {
      border: none;
      color: white;
      outline: 0;
    }
  }

  ::-webkit-input-placeholder {
    font-size: 14px;
  }

  tag.unvalid-tag {
    background-color: darkgray;
  }
  &.input-mode {
    tags {
      display: none;
    }
    .simple-text {
      display: none;
    }
  }

  &.tags-mode {
    .tags-input {
      .input.out-of-view {
        display: none;
      }
    }

    results {
      display: none;
    }
  }

  &.open {
    border-color: #ffffff;
    i.icon-Search, i.demi-icon-pipe {
      color: #ffffff;
    }
  }

  results {
    display: block;
    position: absolute;
    width: 100%;
    min-height: 20px;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 20px 20px 20px 20px;
    font-size: 14px;
    color: #81878C;
    opacity: .95;
    background-color: #22252b;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    outline: 1px solid #ffffff;

    .define-interest {
      display: none;
      width: 100%;
    }

    button.main-button {
      float: right;
    }

    .bordered-button {
      @extend .base_bold_font;
      margin: auto;
      height: 30px;
      font-size: 14px !important;
      padding: 6px 12px !important
    }

    .manage-interests {
      float: right;
      margin-right: 10px;
      line-height: 26px;
      font-weight: bold;
      cursor: pointer;

      .icon-manage-program {
        margin-right: 5px;
        font-size: 12px;
        margin-bottom: 3px;
      }

      &:hover {
        color: #cacaca;
      }
    }

    hr {
      border: none;
      border-top: 1px solid grey;
      padding-top: 15px;
    }

    .results-scrollable {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100% - 60px);
      max-height: 600px;

      .divider {
        border-bottom: 1px solid #81878c;
        order: 2;
        display: inline-block;
        flex-grow: 1;
        flex-basis: 100%;
        margin-top: 3px;
        margin-bottom: 12px;
      }

      .suggestions {
        width: 100%;
      }

      .suggestions-wrapper {
        display: inline-block;
        width: 100%;
        flex-grow: 1;
        flex-basis: 50%;
        min-width: 250px;

        &.suggestions {
          order: 1;
        }
        &.programs {
          order: 3;
        }
      }

    }

    .scrollable-suggestions {
      overflow-y: visible;
      max-height: 50vh;
      margin-top: 7px;
    }

    .suggestion-title {
      font-size: 14px;
      font-weight: bold;
      color: white;
    }

    result {
      display: block;
      cursor: pointer;
      line-height: 35px;
      padding-left: 10px;
      font-size: 14px;
      font-weight: bold;

      &:hover, &.selected {
        background-color: #161719;
        color: gray;
        border-radius: 0.3em;
      }
    }

    result.program-bl {
      height: 56px;
      line-height: 0;
      overflow-x: hidden;

      .program-bl-display {
        line-height: 30px;
      }

      .program-bl-desc {
        line-height: 20px;
        font-size: 12px;
        color: #cacaca;
        white-space: nowrap;
        overflow-x: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }

  .input-bar-right-buttons {
    float: right;
    z-index: 2;
    position: relative;
  }

  .ui-select-highlight {
    color: white;
  }

  .counter-tag {
    transition: all 0.2s;
    &.out-of-limit {
      font-size: 1.3em;
    }
  }

  .icon-Search {
    width: 16px;
  }

  .resize-corner {
    position: absolute;
    right: 0;
    bottom: -3px;
    z-index: 10;
    cursor: row-resize;
    font-size: 10px;
  }
}

/*** EXTRAS ***/
input-bar, .input-bar {
  &.grayscale {
    tag {
      .button-add-tag, .button-remove-tag {
        color: $click-color !important;
      }
    }
  }

  &.unsocial {
    tag[type=hashTag], tag[type=mention], tag[type=post], .social-bl {
      @include not-in-use();
      @include hide-refine();
    }
  }

  .term-type {
    font-size: 12px;
  }
}

/*single colored*/
input-bar.mono, .input-bar.mono{

    label{padding-left:40px;}
    .tags-input{padding-left:0em;}

    tag, .tag{
      background: #535D65 !important;
      color: white !important;
      font-family: Helvetica,Arial,sans-serif !important;
    }
}

/*search bar*/
input-bar.search {
  display: inline-block;
  background-color: inherit;
  border: 1px solid #545a66;
  outline: 0;
  height: 36px;
  min-height: 36px;
  line-height: 20px;
  padding: 0;

  &.input-mode {
    border-color: #fff;
    outline-color: inherit;
    .icon-Search {
      color: #fff;
    }
  }

  &.open {
    border-color: #ffffff;
  }

  .input-bar-right-buttons, .demi-icon-pipe {
    display: none;
  }

  .icon-Search {
    float: right;
    margin-top: 7px;
    margin-right: 10px;
  }

  label {
    padding: 5px 0;

    .tags-input {
      font-size: 14px;
      padding-left: 10px;

      .place-holder {
        &::-webkit-input-placeholder {
          color: $regular-color;
        }
      }
    }
  }

  results {
    .define-interest {
      display: inline-block;
    }
  }
}


/*div without editing*/
.input-bar-static{
    padding:10px;
    padding-left:6px;
    background: transparent;
    border: none;
    outline: none;
    min-height: auto;
    .tag{
      margin: 6px;
      padding-top: 1px;
    }
}

@import "../../common/style/flexbox";
@import "infra/style/base";
@import "widgets/base_widget";
@import "common/charts/top-chart";
@import "common/tags-viewer.drv/tags-viewer.drv";

$selection-background: #151719;
$border-color: #0e1011;

top-association-widget {
  @include flex_fit_and_fill();
  @include flex_direction(column);
  background-color: transparent;

  .associations-graph-container {
    overflow-y: scroll;
  }

  .consumption-container-show {
    right: 0 !important;
  }

  .consumption-container {
    @include display_flex();
    @include flex_direction(column);

    border-left: 1px solid black;
    border-top: 1px solid black;
    width: calc(100% - 300px);
    bottom: 0;
    right: -1500px;
    position: absolute;
    transition: all 0.5s linear;
    transform: translate3d(0, 0, 0);
    background-color: #202427;
    height: calc(100% - 70px);

    .x-close {
      color: $click-color;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 8px;

      &:hover {
        color: $selected-color
      }
    }

    .loader .loader-overlay {
      display: none;
    }
    .top-association-chart-loader {
      @extend .loader;
    }
    .top-association-chart-loader-overlay {
      @include overlay();
      background-color: #0C0E0F;
      opacity: 1;
    }

    .association-trend-legend {
      @include display_flex();
      @include flex_direction(row-reverse);

      justify-content: space-between;
      margin: 15px 15px 10px 15px;

      .legend-right {
        @include display_flex();
        @include flex_direction(row-reverse);

        .legend-trends {
          @include display_flex();
          justify-content: flex-end;

          .legend-trend {
            @include display_flex();

            .legend-icon {
              margin: 5px 0 0 10px;
              float: left;
              height: $control-trend-height;
              width: $control-trend-height;
              vertical-align: middle;
            }
            .legend-title {
              margin: 5px 8px 0 5px;
              float: left;
              font-family: Helvetica, sans-serif;
              color: $click-color;
              font-size: 14px;
              font-stretch: normal;
              font-style: normal;
              font-variant: normal;
              font-weight: normal;
              line-height: $control-trend-height;
              vertical-align: middle;
            }
          }
        }

        .association-title {
          margin: 5px 5px 0 10px;
          float: right;
        }
      }
    }

    .charts-frame {
      @include display_flex();
      @include flex_direction(row);
      flex: 1 1 auto;
      position: relative;
      margin-bottom: 10px;

      trend-chart {
        @include display_flex();
        @include flex_direction(column);

        flex: 1;
        position: relative;
        align-items: stretch;
        margin: 0;
      }
    }
  }

  *:focus {
    outline: 0;
  }

  .main-container {
    @include display_flex();
    @include flex_direction(column);
    position: relative;
    align-items: stretch;
    flex: 1 1 auto;
    background-color: transparent;
    border: 1px solid black;
    box-shadow: inset 0 2px 1px -2px #DDDFD0;
    width: 100%;
    height: 100%;
    margin: 0;

    .message-frame {
      width: 100%;
      height: 50%;
      margin: 15% auto auto auto;
      .message-line {
        width: 100%;
        height: 14px;
        line-height: 20px;
        text-align: center;
        vertical-align: middle;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        color: $regular-color;
      }
    }
  }

  .user-panel {
    @include display_flex();
    @include flex_direction(column);
    background-color: transparent;
    flex: 0 1 auto;
    position: relative;
    min-height: 70px;

    .controls-panel {
      @include display_flex();
      @include flex_direction(row);
      background-color: transparent;
      position: relative;
      align-items: stretch;
      padding-left: 14px;
      padding-right: 16px;

      input-bar {
        width: 275px;
        margin-top: 16px;
        &.input-mode {
          background-color: #22252b;
        }
        results{
          .results-scrollable {
            max-height: 55vh;
          }
        }

        label {
          padding-top: 6px !important;
        }
      }

      .allow-boolean {
        .define-interest {
          width: 100%;
          display: inline-block !important;
        }
      }

      .custom-trend-snippet {
        @include display_flex();
        @include flex_direction(row);
        position: relative;
        align-items: stretch;
        margin: 25px 0 6px 0;
        flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        .custom-trend-label {
          @include display_flex();
          @include flex_direction(row);
          position: relative;
          align-items: stretch;
          margin: 3px 6px 3px 6px;
          color: #777d8b;
          font-family: Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
        }
      }

      .filter-selector {
        @include display_flex();
        @include flex_direction(row);
        position: relative;
        align-items: flex-start;
        margin: 20px 6px 6px 20px;
        font-family: Helvetica, sans-serif;
        color: $click-color;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        .control-button {
          background-color: #202427;
          border: 1px solid black;
          padding: 5px 10px 5px 10px;
          cursor: pointer;
        }
        .control-button:hover {
          color: $selected-color;
        }
        .selected-control {
          background-color: $selection-background;
          padding: 5px 10px 5px 10px;
          cursor: default;
          color: $selected-color;
          @extend .base_bold_font;
        }
      }
    }
  }

  .suggested-associations-container {
    @include display_flex();
    @include flex_direction(column);
    background-color: transparent;
    flex: 0 1 auto;
    position: relative;
    min-height: 92px;
    align-items: stretch;
    margin: 20px 20px 10px 20px;

    .suggested-associations-header {
      margin-bottom: 10px;

      .associations-title {
        font-family: Helvetica, Arial, sans-serif;
        color: $regular-color;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        padding-right: 16px;
        float: left;
      }
    }

    .suggested-associations-tags {
      min-height: 50px;
      max-height: 50px;
      overflow: hidden;
    }

    .tags-viewer-drv {
      tag {
        .box {
          border-color: $click-color;;
        }
        .plus, .text {
          color: $click-color;
        }

        &:hover{
          .plus, .text {
            color: $regular-color;
            background: transparent;
          }
        }

      }
    }

  }

  .associations-separator {
    border-bottom: 1px solid black;
  }

  .clear-associations-container {
    margin-top: 15px;
    margin-right: 15px;
    .clear-associations {
      cursor: pointer;
      color: #81878c;
      float: right;
      position: relative;
      right: 10px;
      &:hover {
        color: #FFFFFF;
      }
    }
  }
}

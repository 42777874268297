@import '../../infra/style/style_guide';

.common-tooltip {
  background-color: #202427;
  opacity: 0.9;
  border-color: #4f5961;
  color: $regular-color;
  font-size: 14px;
  border-radius: 4px;
  z-index: 999999 !important;
}

.common-tooltip-info {
  @extend .common-tooltip;
  background-color: $regular-color;
  color: #202427;
  border-color: #151719;
  line-height: 1em;
  min-width: 15px; //Override
}

.no-width-limit-tooltip {
  @extend .common-tooltip-info;
  max-width: none;
}

.common-tooltip-wrap-content {
  white-space: nowrap;
  max-width: none;
}

@import "infra/style/base_modal";

#define-interest-overlay {
  z-index: 99998;

  #define-interest-modal {
    z-index: 99999;
    width: 503px;
    height: 482px;
    margin-top: 15vh;

    .results-scrollable {max-height:26vh;}

    #define-interest {
      position: relative;
      height: 331px;
      overflow-y: visible;

      input{
        width: 450px;
        font-size: 13px;
      }

      .search-div {
        position: relative;
        width: 450px;
        height: 40px;
      }

      .title-and-search {
        margin-bottom: 10px;

        .seed-title {
          margin-bottom: 10px;

          .interests-info {
            font-size: 14px;
            color: $cancel-text-color;

            &:hover {
              color: $highlight-color;
            }
          }
        }
      }

      input-bar {
        padding: 10px 0 0;
        min-height: 2em;
        height: 40px;
        background-color: $modal-background;
        border-color: $separator-color;

        .input-bar-right-buttons, .icon-Search, .demi-icon-pipe {
          display: none;
        }

        &.input-mode {
          background-color: $input-focus-bg-color;
          border-color: $modal-border;
        }

        tag {
          margin-bottom: 10px;

          .text {
            font-size: 13px;
          }
        }

        .tags-input {
          padding-left: 10px;
        }

        label {
          padding: 10px 0 0;
        }

        &.ng-invalid {
          border: 1px solid $invalid-color !important;
        }

        &:focus {
          background-color: $input-focus-bg-color;
          border-color: $modal-border;
        }
      }

      .bottom-error {
        font-size: 14px;

        margin-bottom: 15px;
        text-align: center;
      }

      .allow-scroll {
        label {
          overflow: auto;
        }
      }
    }

    .control-buttons {
      label.selected {
        &:hover {
          color: $highlight-color;
        }
      }

      .icon-tick_in_circle {
        color: $highlight-color;
        font-size: 15px;
      }

      .icon-circle-o {
        font-size: 15px;
      }

      .activate-option {
        margin-top: 5px;
      }

      .disabled-bl {
        pointer-events: none;
        color: $submit-color-disabled;
        .checked {
          color: $submit-color-disabled;
        }
      }

      .clickable-container-di {
        color: $cancel-text-color;
        cursor: pointer;
        float: right;
        margin-top: 7px;
        margin-right: 10px;

        #delete-label {
          border-left: $cancel-text-color 1px solid;
          padding-left: 10px;
          font-size: 14px;
        }

        .trash-delete {
          font-size: 13px;
          cursor: pointer;
          margin-bottom: 2px;
        }
      }

      .clickable-container-di:hover .clickable-child {
        color: $modal-text-color;
      }
    }
  }
}

@import "infra/style/base_modal";
@import "./alert-dsp/alert-dsp-component";

$buttons-row-border-color: #21242a;

#alert-dialog-overlay {
  .transformable {
    -webkit-transition: max-height 300ms linear;
    -moz-transition: max-height 300ms linear;
    -o-transition: max-height 300ms linear;
    -ms-transition: max-height 300ms linear;
    transition: max-height 300ms linear, margin-top 300ms linear;
  }

  #alert-dialog-modal.expanded {
    max-height: calc(100% - 5vh);
    margin-top: 5vh;
  }

  #dialog-form-container {
    max-height: calc(100vh - 355px);
  }

  #alert-dialog-modal {
    overflow: overlay;
    width: 1040px;
    max-width: 100%;
    max-height: 270px;
    margin-top: 15%;
    padding-top: 30px;
    padding-left: 30px;

    #alert-title {
      margin-bottom: 30px;
    }

    #alert-close {
      top: 33px;
    }
    .alerts-types {
      display: block;
      margin-bottom: 20px;
    }

    #alert-dialog {
      margin-bottom: 25px;

      .alert-filter {
        display: inline-block;
        margin-right: 30px;
        vertical-align: top;

        am-select {
          margin-bottom: 0;
        }

        .filter-label {
          display: block;
          margin-bottom: 10px;
        }

        ul {
          overflow: hidden;
          position: absolute;
          width: 100%;
        }
      }

      .no-margin {
        margin-bottom: 0;
      }

      .sentiment-radio-group {
        am-select {
          position: absolute;
          bottom: 0;
          width: 300px;

          .scroll-container {
            padding: 7px 0 0;
          }

          li {
            padding: 0;
            &:hover {
              background-color: inherit;
            }
          }

          ul {
            overflow: visible;
            position: relative;
            padding: 0;
          }

          .radio-buttons-triangle {
            display: none;
          }

          .scroll-container {
            background: initial;
          }
        }
      }

      .row {
        &.timeframe-row {
          margin-top: 40px;

          .timeframe-notification {
            color: $invalid-color;
            margin-left: 5px;
          }
        }
        &.geo-channel-row.sentiment-first-row {
          z-index: 1;
          margin-bottom: 0;
        }
        &.geo-channel-row {
          background-color: $modal-background;
          position: relative;
          .geo-filter {
            am-select.open {
              overflow: visible;
            }

            am-select {
              height: 100%;

              select-title {
                border: 1px solid $separator-color;
                background-color: inherit;
                i.icon-Triangle_Down {
                  color: $separator-color !important;
                }
              }
              &.open {
                ul {
                  visibility: visible;
                }
              }
              ul {
                margin-right: 0;
                margin-top: 11px;
                visibility: hidden;

                li {
                  padding: 0;
                }
              }
            }
          }

          .alert-geo-label {
            position: relative;
            left: 1px;
          }

          .alert-channel-filter {
            span {
              width: 60px;
              text-align: center;
              padding: 6px 0 6px 0;
            }
          }
        }

        #alert-name {
          margin-top: 10px;
        }

        input {
          width: 100%;
        }

        &.trigger-option {
          .icon-tick_in_circle {
            color: $highlight-color;
            font-size: 15px;
          }

          .icon-circle-o {
            font-size: 15px;
          }

          .text-after {
            @extend .pointer;
            margin-left: 10px;
          }

          .selected {
            .text-after {
              color: $highlight-color;
            }
          }

          .trigger-input {
            margin-left: 30px;
            margin-top: 5px;

            input[type='number'] {
              width: 40px;
              height: 30px;
              padding: 0 5px;
              text-align: center;

              &:disabled {
                color: $cancel-text-color;
              }
            }

            .input-label {
              margin-left: 15px;
              cursor: default;
            }

            .or-label {
              font-style: italic;
              margin: 0 15px;
              cursor: default;
            }

            input[type='number']::-webkit-outer-spin-button,
            input[type='number']::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        .buttons-row {
          display: inline-block;
          border: 1px solid $buttons-row-border-color;
          border-radius: 4px;

          .tab-button {
            display: inline-block;
            cursor: pointer;
            padding: 4px 10px;
            border-right: 1px solid $buttons-row-border-color;
            border-left: 0;

            &:hover {
              color: $highlight-color;
            }

            &:last-child {
              border-right: none;
            }
          }
        }

        .chosenTime {
          color: $highlight-color;
          font-weight: bold;
          box-shadow: inset 0 2px 0 0 #000;
          border-width: 1px 0;
          background-color: $buttons-row-border-color;
        }

        .first {
          border-left: 1px solid $buttons-row-border-color;
          border-radius: 4px 0 0 4px;
        }

        .last {
          border-radius: 0 4px 4px 0;
        }

        .last.first {
          border-radius: 4px;
        }

        .chosenFirst {
          border-left: 0 solid;
        }
      }

      .turn-dsp-wrapper {
        border-top: 1px solid $separator-color;
        margin-top: 20px;
        padding-top: 20px;

        .select-style {
          display: inline-block;
          background-color: $modal-background;
          color: $cancel-text-color;
          border: 1px solid $separator-color;
          margin-right: 5px;

          select {
            padding: 5px 5px 6px;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            background: transparent none;

            &:focus {
              outline: none;
              background-color: $input-focus-bg-color;
              border-color: $modal-border;
              option {
                &:hover {
                  background-color: black;
                }
              }
            }

            &:disabled {
              cursor: default;
              color: $separator-color;
            }
          }
        }
      }

      .advanced-settings-title, .turn-dsp-title {
        display: inline-block;
        &:hover {
          color: $highlight-color;
          cursor: pointer;
        }

        .settings-collapse {
          padding: 0 5px;
        }
      }

      .advanced-settings, .turn-dsp {
        margin-top: 25px;
      }

      .error-messages {
        display: inline-block;
        margin-left: 5px;

        .error {
          font-size: 12px;

          &.triggers-error {
            padding-bottom: 5px;
            padding-left: 15px;
          }
        }
      }

      .info-tooltip {
        font-size: 14px;
        color: $cancel-text-color;

        &:hover {
          color: $highlight-color;
        }
      }
      .input-bar-row {
        margin-bottom: 10px;

        .wrapper-div {
          margin-top: 10px;

          input-bar {
            padding: 10px 0 0;
            background-color: $modal-background;
            border-color: $separator-color;

            .button-remove-all-tags, .demi-icon-pipe, .input-bar-right-buttons {
              display: none;
            }

            .icon-Search {
              float: right;
              margin-right: 10px;
            }

            .place-holder {
              font: 13px Helvetica;
              color: $cancel-text-color;
            }

            &.input-mode {
              background-color: $input-focus-bg-color;
              border-color: $modal-border;
            }

            tags {
              margin-left: 5px;
            }

            tag {
              margin-bottom: 10px;

              .text {
                font-size: 13px;
              }
            }

            .tags-input {
              padding-left: 10px;
            }

            label {
              padding: 10px 30px 0 0;
            }

            &.no-interests {
              outline-color: transparent;
              border: 1px solid $invalid-color !important;
            }

            &:focus {
              background-color: $input-focus-bg-color;
              border-color: $modal-border;
            }

            .define-interest {
              width: 100%;
              display: inline-block;
            }

            .button-edit-tag {
              display: inline-block;
            }

            results {
              padding: 20px;

              .suggestions-wrapper {
                width: calc(50% - 5px);
              }
            }
          }

          .allow-scroll {
            label {
              overflow: auto;
            }
          }
        }
      }
    }

    .control-buttons {
      .save-loader {
        margin-left: 15px;
        display: inline-block;
        width: 60px;
        height: 30px;
        cursor: default;
        float: right;
      }

      .buttons-wrap {
        float: right;
      }

      .estimation-wrap {
        margin-top: 7px;
        span.estimated-number {
          color: $positive-color;
        }

        .loading-spinner-small {
          height: 10px;
          width: 60px;
          display: inline-block;
        }
      }
    }
  }

  #dialog-form-container.scroll-alert-container {
    overflow-y: auto !important;
  }
}

.drilldown-popup {
  max-width: 500px;
  background-color: #202427;
  opacity: 0.9 !important;
  border-color: #4f5961;

  .qtip-content {
    padding: 0;
  }

  .drilldown-popup-main {
    padding: 10px 5px;
    width: 100%;
  }

  .item {
    cursor: pointer;
    padding: 10px;

    a {
      color: $click-color;
      font-weight: normal;
      font-size: 14px;
      text-decoration: none;
    }
    &:hover {
      background-color: #151719;
      a {
        color: $main-btn-bg-hover;
      }
    }
  }
}

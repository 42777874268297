@import "infra/style/base";
@import "infra/style/style_guide";
@import "../../style/bordered-button";

$border-color: #2e3138;
$best-ratio: 1.6180339;
$modal-height: 300px;

#conformation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .modal {
    position: relative;
    margin: 30vh auto 0;
    border: 1px solid $modal-border;
    border-bottom: 2px solid $modal-border;

    background-color: $modal-background;
    z-index: 1002;

    width: calc(#{$best-ratio} * #{$modal-height});
    padding: 20px;
    color: $text-color;
    font: 14px Helvetica;
    text-align: left;

    #body-modal {
      height: calc(#{$modal-height} / #{$best-ratio});
      overflow-y: scroll;
      position: relative;

      .title {
        min-width: 220px;
        font: 300 18px "Helvetica Bold";
        color: $title-color;
        padding-bottom: 16px;
        margin-bottom: 14px;
        border-bottom: 1px solid $border-color;
      }

      .icon-X.close-modal {
        display: none;
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 12px;
        cursor: pointer;
        color: $regular-color;

        &:hover {
          color: $selected-color;
        }
      }

      .control_buttons {
        position: absolute;
        bottom: 1px;
        right: 0;

        .submit-btn {
          font: 14px Helvetica bold;
          padding: 6px 10px;

          float: right;
          cursor: pointer;
          color: white;
          background-color: $amobee-blue;
          border: 1px solid $amobee-blue;
          border-radius: 4px;
          transition: .5s color;

          &:hover {
            color: black;
          }

          &:disabled {
            cursor: default;
            color: $submit-color-disabled;
            border: 1px solid $submit-color-disabled !important;
            background-color: transparent;
          }
        }

        .cancel-btn {
          font-size: 14px;
          margin-right: 10px;
          margin-top: 7px;

          float: right;
          cursor: pointer;
          color: $cancel-text-color;

          &:hover {
            color: $modal-text-color;
          }
        }
      }

      .row {
        position: relative;
        padding: 0 0 25px 0;
        height: 40px;
        margin-bottom: 10px;

        label {
          @extend .base_bold_font;
          position: absolute;
          top: 10px;
          color: $regular-color;
          font-size: 14px;
          display: inline;
          padding: 0 0 5px 0;
        }
      }
    }
  }
}

$animation-time: 0.09s;
$animation-method: cubic-bezier(0.4, 0.5, 0.38, 0.82);

am-input[type=dropdown].fancy,
am-container[type=drop].fancy {
  line-height: 23px;
  color: white;
  border-radius: 5px;
  min-width: auto;
  font-weight: normal;

  i.icon-Triangle_Down {
    display: none;
  }

  i.icon {
    display: inline;
    float: left;
    margin-right: 10px;
    font-size: 12px;
  }

  .input-selected {
    line-height: 23px;
    border-radius: 4px;
    border: 1px solid #21242A;
    background-color: transparent;
    font-family: "Helvetica Bold";
    font-weight: normal;
    font-size: 13px;
    text-transform: capitalize;
  }

  &.open {
    .input-selected {
      background-color: #21242A;
      box-shadow: inset 1px 2px 2px black;
    }
  }

  .options {
    border: 1px solid white;
    border-bottom: 3px solid white;
    border-radius: 5px;
    background-color: #282D33;
    font-weight: normal;
    &:before {
      border-bottom-color: #FFFFFF;
    }
  }

  &.open-left-down, &[open-left-down] {
    .options {
      border-top-left-radius: 0;
      &:before {
        border-left-color: #FFFFFF;
      }
    }
  }

  &.open-up, &[open-up] {
    .options {
      &:before {
        border-top-color: #FFFFFF;
        bottom: -14px;
      }
      &:after {
        border-top-color: rgba(28, 32, 34, 0.95);
      }
    }
  }

  .option-overflow-warpper {
    padding: 0 22px;
    border-radius: 4px;
  }

  .option {
    cursor: pointer;
    padding: 0 1.5em 0 .5em;
    border-bottom: 1px solid #3A3D42;
    color: #81878C;
    white-space: nowrap;
    line-height: 40px;
    font-family: "Helvetica Bold";
    font-size: 13px;
    &:hover, &.selected {
      background-color: transparent;
      color: white;
    }
    &.selected {
      color: white;
      .selected-icon {
        display: inline;
        position: absolute;
        right: 0;
        font-size: 15px;
      }
    }
  }
}
@import "../../infra/style/base";
@import "../../infra/style/style_guide";

$brick-width: 263px;
$tweet-image-height: 60px;
$gradient-start-trans: 1;

.masonry-brick {
  transition: opacity ease 1.2s;
  opacity: 0;
}

.delete-transition {
  transition: left, right, top 0.7s;
}

.no-transition {
  transition: none
}

.brick {
  .score-breakdown {
    .score-breakdown-element {
      position: relative;
    }

    .score-value {
      position: absolute;
      top: 0;
      right: 0;
    }

    .bar {
      height: 15px;
      max-width: 100%;
      background-color: #00AAEE;
    }

    .container {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .no-avail-image {
    z-index: 1; // to remove the gradient
  }

  .icon-Trash {
    &:hover {
      color: $selected-color !important;
    }
  }

  .pre-publish-date-space {
    margin-right: 6px;
  }

  .read-more {
    color: cadetblue;
    white-space: nowrap;
  }

  .nowrap {
    white-space: nowrap;
  }

  .publish-date {
    color: #6b707d;
    font-size: 12px !important;
    padding-top: 2px;
    vertical-align: text-bottom;
    @include base_font;
  }

  .title-max-height {
    max-height: 130px;
  }

  .title-bar {
    padding-left: 5px;
  }

  .brick-bottom {
    background-color: $widget-light;
  }

  .owned-content-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
  }

  .score {
    color: #353c43;
  }

  &:hover {
    .score {
      color: #f8f673;
    }
  }

  .social-bar {
    padding-top: 10px;
    background-color: #525c64;
    font-size: 12px;
  }

  @mixin gradient($start-after) {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop($start-after, rgba(0, 0, 0, $gradient-start-trans)), color-stop(100%, rgba(0, 0, 0, 0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) $start-after, rgba(0, 0, 0, $gradient-start-trans) 100%); /* W3C */
  }

  .gradient {
    @include gradient(50%);
  }

  margin-bottom: 25px;
  border-radius: 4px;

  box-sizing: border-box;

  width: $brick-width;
  max-width: $brick-width;

  box-shadow: 0 0 0 2px #525c64;

  font-family: Helvetica;
  color: white;

  .title {
    color: white;
    clear: both;
    overflow-wrap: break-word;

    font-size: 16px;
    @extend .base_bold_font;
    padding: 10px 5px 10px 5px;
  }

  .icon {
    padding-right: 5px;
    vertical-align: middle;
  }
  .icon.icon-facebook {
    padding-right: 2px;
  }

  .domain {
    float: left;
    clear: left;
    color: $regular-color;
    width: $brick-width - 13px;
  }

  .on-image-info {
    position: absolute;
    bottom: 5px;
    left: 7px;
    color: $regular-color;
    z-index: 11;
    width: $brick-width - 43px;
    font-size: 12px;
  }

  .time {
    float: right;
    color: $regular-color;
  }

  .left-icon {
    padding-left: 10px;
    float: left;
  }

  .align-left-icon {
    padding-left: 15px;
    float: left;
  }

  .align-right-icon {
    padding-right: 5px;
    float: right;
  }

  .icon-Star {
    vertical-align: bottom;
  }

  .right-icon {
    float: right;
    padding-right: 5px;
  }

  .bottom-padding {
    padding-bottom: 8px;
    clear: both;
  }

  .play-button {
    background-image: url("/images/common/play.svg");
    width: 100px;
    height: 70px;
    background-size: 100px 70px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -35px;
    pointer-events: none;
    opacity: 0.2;
  }

  a {
    text-decoration: none;
    color: $click-color;
    &:hover {
      color: white;
    }
  }

  .rollover-container {
    position: relative;

    &:hover {
      .rollover {
        visibility: visible;
      }
    }

    .rollover {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.85);
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 10;
      word-break: break-word;

      .x-close {
        position: absolute;
        right: 8px;
        bottom: 5px;
        font-size: 16px;
        pointer-events: all;
        cursor: pointer;
        color: $click-color;
      }

      .desc-and-terms {
        height: calc(100% - 25px);

        .board-name-container {
          font-size: 12px;
          color: $click-color;
          padding: 5px 10px;
        }
      }

      .desc-container-no-terms {
        max-height: 100% !important;
      }

      .desc-container {
        max-height: calc(100% - 18px);

        .desc {
          width: calc(100% - 8px);
          height: 100%;
          color: $regular-color;
          text-shadow: 1px 1px black;
          padding-left: 8px;
          padding-top: 10px;
          padding-bottom: 5px;
          font-size: 12px;

          span {
            vertical-align: middle;
          }
        }
      }
    }
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .highlighted-terms-container {
    padding-left: 10px;

    .highlighted-term {
      background-color: #363b3f;
      border-radius: 2px;
      padding: 2px 5px;
      font-size: 12px;
      margin-right: 5px;
    }
  }
}

.article-brick, .video-brick, .facebook-brick.with-image, .instagram-brick, .pinterest-brick {
  .domain {
    padding-top: 10px;
    padding-left: 10px;
  }

  img {
    width: $brick-width;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    position: relative;
    z-index: -1;
    display: block;
  }

  .image {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
  }

  .rollover-container {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
}

.video-brick {
  .icon-Video_icon {
    margin-top: 2px;
    float: left;
  }

  .desc-container {
    max-height: calc(100% - 17px) !important;
  }
}

.pinterest-brick {
  img {
    max-height: 468px;
    object-fit: cover;
    object-position: top center;
  }

  .rollover-container {
    max-height: 468px;

    .rollover {
      .desc-container {
        max-height: calc(100% - 43px);
      }
    }
  }
}

.tweet-brick, .facebook-brick.without-image {
  .title {
    padding-left: 10px;
  }

  img {
    border-radius: 4px;
    width: $tweet-image-height;
    height: $tweet-image-height;
  }

  .image {
    float: left;
    height: $tweet-image-height;
  }

  .icon {
    padding-right: 5px;
  }

  .tweet-info-container {
    float: left;
    margin-left: 15px;
    color: $regular-color;

    .tweet-info {
      .user-name {
        font-size: 18px;
        width: $brick-width - $tweet-image-height - 35px;
      }

      .followers {
        padding-top: 3px;
        font-size: 13px;
      }
    }
  }

  .handle-name {
    padding-top: 10px;
    font-size: 12px;
    float: left;
    clear: left;
    color: $regular-color;
    width: $brick-width - 20px;
  }

  .rollover-container {
    background-color: $widget-light;
    border-radius: 4px;

    &:hover {
      .title {
        background: linear-gradient(to bottom, grey, black);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .tweet-info-container {
        background: linear-gradient(to bottom, $regular-color, grey);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .handle-name {
        color: grey;
      }

      .title-bg-gradient {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      }
    }

    .rollover {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0) !important;
    }

    .highlighted-terms-container {
      position: absolute;
      bottom: 9px;
      color: $regular-color;
    }
  }

  .brick-top {
    padding: 10px 5px 0 10px;
    background-color: $widget-light;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

@import "../../infra/style/style_guide";
@import "../../infra/style/base";

$selection-background: #151719;

stacked-chart {

  #svg-container{
    overflow: visible;
  }

  .hover-line {
    stroke-width: 2px;
  }

  .hover-date {
    fill: $selection-background;
    stroke: $selection-background;
  }

  .unknown-stack {
    stroke: none;
    fill: none;
  }

  .hover-text {
    fill: white;
    font-family: Helvetica, sans-serif;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    @extend .base_bold_font;
  }

  .hover-date-text {
    fill: white;
    font-family: Helvetica, sans-serif;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
  }

  .axis {

    .domain {
      stroke: none;
    }

    text {
      fill: $click-color;
      font-family: Helvetica, sans-serif;
    }

    path, line {
      stroke: #2e3236;
      shape-rendering: crispEdges;
    }
  }

  .y-axis.axis text {
    margin-right: 10px;
  }

  .x-axis.axis path {
    display: none;
  }

  .line {
    fill: none;
    stroke-width: 2px;
  }

  .area {
    stroke-width: 0px;
  }

  .hover-chart-line {
    stroke-width: 3px;
  }

  .stacked-chart-circle {
    stroke: #FFFFFF;
    fill: #202427;
    r: 9;
    cursor: pointer;
    stroke-width: 2px;
  }

  .stacked-chart-text {
    fill: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    text-anchor: middle;
    dominant-baseline: central;
  }

  .stacked-chart-circle.highlighted{
    stroke: #FFFFFF;
    fill: #FFFFFF;
    r: 11;
  }

  .stacked-chart-text.highlighted{
    fill: #202427;
    font-size: 13px;
  }
}

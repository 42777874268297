@import "infra/style/base";
@import "widgets/base_widget";

timing-widget{

  height: 100%;

  .timing-panel{
    border-bottom: 1px solid black;
    flex: 0 1 auto;
    position: relative;
    min-height: 60px;

    .title-container{
      margin-top: 23px;
      float: left;
      margin-left: 20px;
      @include display_flex();
      @include flex_direction(row);

      .timing-description{
        font-size: 14px;
        font-weight: normal;
        color: #cacaca;
      }
    }

    #consumption-legend{
      @include display_flex();
      @include flex_direction(row);
      float: right;
      margin-top: 23px;
      width: 215px;
    }

    #compare-switch{
      @include display_flex();
      @include flex_direction(row);
      float: right;
      margin-top: 21px;
      margin-right: 28px;
    }
  }

  .timing-container{
    @include flex_fit_and_fill();
    @include flex_direction(column);
    flex: 0 1 auto;
    overflow-y: scroll;
    height: calc(100% - 70px);

    .chart-legend{
      margin-left: 20px;
      margin-top: 12px;
      font-family: "Helvetica Bold";
      font-size: 16px;
      color: $title-color;
    }

    .chart-frame{
      padding-top: 10px;
      margin-bottom: 15px;
      &.last{
        margin-bottom: 0;
      }
    }
  }

}
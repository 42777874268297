@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/iconmoon/fonts/icomoon.eot?on2igv');
  src:  url('../fonts/iconmoon/fonts/icomoon.eot?on2igv#iefix') format('embedded-opentype'),
    url('../fonts/iconmoon/fonts/icomoon.ttf?on2igv') format('truetype'),
    url('../fonts/iconmoon/fonts/icomoon.woff?on2igv') format('woff'),
    url('../fonts/iconmoon/fonts/icomoon.svg?on2igv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  &:before, &:after,
  & > [class^=path]:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
  }
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import "./iconmoon/style.scss";
.icon-dashboard .path1:before,
.icon-dashboard .path2:before,
.icon-youtube-play-button .path1:before,
.icon-youtube-play-button .path2:before {
  color: inherit;
}

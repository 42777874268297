@import "../../infra/style/style_guide";

.bordered-button {
  color: white;
  font-size: 12px;
  border: 1px solid white;
  background-color: transparent;
  padding: 3px 8px;
  border-radius: 4px;
  cursor: pointer;
  // transition: 1s color;
  &:hover {
    color: #1c2022;
    background-color: white;
  }
}

.branding-bordered-button {
  font-size: 12px;
  font-family: "Helvetica Bold", sans-serif;
  color: white;
  background-color: $amobee-blue;
  padding: 3px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: .5s  color;
  border: 1px solid $amobee-blue !important;
  &:hover {
    color: black;
    background-color: $amobee-blue;
  }
  &:disabled {
    color: $disabled;
    border-color: $disabled !important;
    background-color:transparent;
  }
}

/*general elements like tabs*/

.tabs {

    /*example: Interactions & Sentiment*/

    $control-height: 31px;

    margin: 22px auto 5px auto;
    height: $control-height;
    min-height: $control-height;
    width: 690px;

    .tab {
      border: none;
      margin: 0px 5px 0px 5px;
      padding: 0px 10px 0px 10px;
      height: $control-height;
      vertical-align: middle;
      background-color: transparent;
      color: $click-color;
      font-family: Helvetica, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-variant: normal;
      font-weight: 300;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
    }

    .tab:hover {
      color: $selected-color;
    }

    .tab.selected{
      color: $selected-color;
      background-color: $textbox-bg;
      border: 1px solid black;
      border-top: 2px solid black;
      border-radius: 4px;
      font-family: "Helvetica Bold";
      font-weight: 300;
      outline: none;
    }
}



select.input-selected{
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

    background-image: url(../images/images/dropdown_arrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 0px 12px 0px 2px;

    &.disabled{background-image:none;color:$regular-color;}
}

am-select.disabled{
  pointer-events:none;
  *{color: #{$disabled}}
}


div.search{
  font-weight:normal;

  input{height:18px; padding: 10px;}

  .icon-Search{
    position: relative;
    left: -36px;
    top:1px;
    color: $click-color;
    font-size: 18px;
  }
}

.dialog{
  //to use:
  //<div class="dialog-overlay" ng-show="condition"></div>
  //<div class="dialog" ng-class="{show: condition }"></div>

  position:absolute;
  top:15%;left:50%;

  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: #2B2E37;
  color: $regular-color;
  padding: 20px;
  opacity:0;
  z-index:-1;
  border:1px solid $regular-color;
  border-bottom-width:2px;
  border-radius:10px;
  transition: opacity 0.6s ease-in-out;

  &.show{opacity:1 !important;z-index:99 !important;}

  .icon-X{
    float:right;color:$regular-color;cursor:pointer;
    font-size:14px;
    &:hover{color:$selected-color}
  }

  input[type="text"]{
    background-color:transparent;padding:10px;border:1px solid #545a66;
    &:focus{background-color: $modal-background;outline-color: #fff;border-color:#fff;}
    }

  .title{
    font: 18px Helvetica bold;
    color: #fff;
    padding-bottom: 16px;
    margin-bottom: 25px;
    border-bottom: 1px solid #4f5961;
  }

  .control_buttons{float:right}

  label{display:block;color: $regular-color;margin-bottom:6px;}
}

.dialog-overlay{
  position:absolute;top:0px;left:0px;width:100vw;height:100vh;
  background:rgba(0,0,0,0.6);z-index:98 !important;
}


.switch-buttons{
  .switch-button{
      padding:6px 10px 6px 10px;
      border-radius:4px;
      border:1px solid black;
      background-color:#151719;
      color:#81878C;
      font-weight:700;
      cursor:pointer;
      transition: all 0.3s ease-in-out;

      &.selected{
        background-color:#202427;
        color:#fff;
      }

      &:first-child{
        border-right:0px;
        border-top-right-radius:0px;
        border-bottom-right-radius:0px;
      }
      &:last-child{
        border-left:0px;
        border-top-left-radius:0px;
        border-bottom-left-radius:0px;
      }
  }
}



/*small elements*/
.pointer{
  cursor:pointer;
  &:hover:not([disabled=disabled]), 
    &.selected, &.selected *{color:white !important;}

  &.norm{color:$click-color;}

  &.bold{font-family:helvetica bold}
}

.center{text-align:center;}
.inline-block{display:inline-block}
.right{float:right}
.fadeout{opacity:0}
.hide{display:none}
.small_input{width:105px !important}
.cancel{color:$click-color;}
hr.gray{border-top: 1px solid $separator-color;}
hr.gray.large{margin-top:22px;margin-bottom:22px;}

.popup-window {
  background-color: #22252b;
  opacity: 0.95 !important;
  border: 1px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  border-radius: 4px;

  .qtip-content {
    padding: 0px;
  }
}

.pointer[disabled] {
  cursor: default;
  color: $disabled;
  pointer-events:none;
}

.loading-img{
    display:block;
    position: absolute;
    top: 48%;
    left: 50%;
    margin-left:-106px;
    z-index:999;
    width:100px;height:12px;
    background-image:url('../images/widgets/Spinner.gif');
    background-size:100% 100%;
    background-repeat: no-repeat;
}

.kdebug-icon{color:#08f;font-weight:bold;cursor:help;}


am-input[type=dropdown].norm{
      min-width:8em;
      .icon-indicator{float:right;}
      &.open .icon-indicator{margin-top:-1px;}

      .options{
        border-radius:4px;
        margin-bottom: 20px !important;
        border-bottom-width:2px;
        border-color: $selected-color;
        &::before{border-top-color: $selected-color !important}
        &::after{border-top-color: rgb(28,32,34) !important}

        .option{
          color: $click-color !important;
          &:hover, &.selected {background:transparent; color: $selected-color !important;}
        }
      }

        .input-selected{
          height: 30px;
          width: 122px;
          line-height: 27px !important;
          background-color: transparent !important;
          border: 1px solid #545a66 !important;
        }
    }

@import "../../infra/style/base";
@import "../../infra/style/style_guide";

audience-table-view-widget {

  display: block;

  $widget-width: 350px;
  $widget-left-right-margin: 20px;
  $widget_horiz_padding: calc((100% - #{$widget-width * 3}) / 6 - 1px);

  .inherit-align {
    position: inherit !important;
  }

  .audience-table-view-widget {
    margin: 0 10px;

    // quick table overrides
    .quick-table-wrap {
      height: calc(100vh - 160px);
      width: 100%;
    }

    .back-to-builder {
      padding-left: 30px;
      padding-top: 15px;
      color: $click-color;

      a:hover {
        color: white;
      }

      .icon-Arrow {
        padding-right: 10px;
      }
    }

    .icon-Arrow {
      font-size: 10px
    }

    .max-height {
      max-height: 100%;
      height: 100%;
    }

    .tabs-container {
      display: inline-block;
      width: calc(100% + 6px);
      height: calc(100% - 312px);
    }
    .tabs {
      text-align: center;
      width: auto;
      margin: 0 auto 3px auto;
    }

    .table-container {
      height: calc(100vh - 160px);
      padding-right: 5px;
    }

    .no-top-padding {
      padding-top: 0 !important;
    }

    .proportions {
      padding-top: 3px !important;
      padding-bottom: 10px;
    }

    .tab-max-height {
      max-height: 100%;
    }

    .widget {
      width: $widget-width;
      height: 200px;
      float: left;
      margin-left: $widget_horiz_padding;
      margin-right: $widget_horiz_padding;
      .title {
        padding-top: 25px
      }
    }

    .right-widget {
      float: right !important;
    }

    .title {
      color: white;
      padding: 15px 0 20px 0;
      height: 10px;
      font-size: 16px;
      @extend .base_bold_font;
      display: table;
      margin-right: auto;
      margin-left: auto;
    }

    .left-aligned-title {
      @extend .title;
      margin-right: 0 !important;
      margin-left: 0px !important;
      padding-top: 0;
    }

    .interest-table, .user-examples {
      border: 1px solid;
      margin-top: 10px;
      td {
        border: 1px solid;
      }
    }

    .notification {
      @extend .base_bold_font;
      color: #DE5D64;
      font-size: 18px;
      padding-bottom: 10px;
    }
  }
}

am-input[type=checkbox] {
  display: inline;
  cursor: default;
  webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  /*&:hover{
    color:#777d8b;
  }*/
  &:hover:before {
    //    border:0.12em solid white;
  }
  &:before {
    @extend .icon-Box_Off:before;
    font-family: 'icomoon';
    color: white;
  }

  &[checked]:before {
    @extend .icon-Box_Roll_On:before;
  }
  &[indeterminate]:before {
    color: #334b4e;
  }
}

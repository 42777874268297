#group_management{

	outline:none;

	#delete_group_dialog{
		margin-left:-150px;
		width: 300px;
  		height: 180px;
	}

	.group_buttons{
		font-size: 14px;
		position: absolute;
	    right: 14px;
	    top: 139px;
	}

	.add-button{
		font-size: 14px;	
    }

    .edit-button{
		font-style: normal;
		margin-right: 10px;
    }

	.icon-watch, .icon-unwatch{&:hover{color:$regular-color !important;}}
	.icon-watch{font-size:10px;}
	.icon-unwatch{
		font-size: 16px;
    	line-height: 12px;
    	vertical-align: sub;
	}


	.clear-all {
  		display: none;
 	}

	.column.phrases{
		white-space: inherit;
    	text-overflow: inherit;
    	overflow: inherit;}

    #edit_group_dialog{
    	width: 720px;
    	margin-left: -360px;
    }

	#edit_group_dialog, #edit_theme_dialog{
		
	    
		#edit_group_title, #edit_brand_name, #input_logo_image, #input_cover_image{
			width:calc(100% - 11px);
		}

		#edit_group_title{
			width:calc(100% - 27px);
		}


		input-bar{

			&>label{overflow-y:auto;}
			
			margin-top:4px;width:680px;height:162px;
			tag{margin-bottom:8px;}

			.button-edit-tag{
				display:inline-block;
			}
		}
	}

	quick-table{
		.thead .scroll-td{width:30px;max-width:30px;}
		.tbody .scroll-td{width:17px;max-width:17px;}
		.crud{min-width:112px;}
	}

}

@import "infra/style/base";
@import "infra/style/style_guide";
@import "../../common/style/bordered-button";
@import "widgets/base_widget";
@import "common/am-gauge-chart.drv/am-gauge-chart.drv";


user-history-widget {

  $border-color: #2e3138;
  $date-history-seperator: #0a0b0c;
  $history-background: #181b1d;
  $url-higlighted-color: #44b0c7;
  $matching-moment-bar-height: 27px;
  $date-bar-width: 150px;
  height: calc(100% - #{$matching-moment-bar-height});
  display: block;
  position: relative;  
  
  .user-history-widget {
    height: 100%; 
 
    .moments-paging {
      margin-left: 5px;
      padding-top: 3px;
      height: $matching-moment-bar-height + 15px;
      font-size: 14px;
      color: white;
      -webkit-user-select: none;

      .vertical-align {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      .moment-button {
        cursor: pointer;
        color: $click-color;
        font-size: 6px;
        padding: 5px;
        &.disabled {
          pointer-events: none;
          color: $disabled;
        }
        &:hover {
          color: white;
        }
        &.icon-arrow_down {
          margin-left: 5px;  
        }
      }

      .square {
        background: $url-higlighted-color;
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 10px;
        margin-bottom: -1px;
      }
    }


    #moments-summary {
      float: left;
      width: calc((100% - #{$date-bar-width}) * 1 / 3);
      padding: 20px 20px 0 20px;
      background-color: $widget-light;
      height: 100%;
      border-left: solid 1px black;
      overflow-y: hidden;
      &:hover {
        overflow-y: overlay;
      }

      .summary-title {
        color: white;
        padding-bottom: 27px;
        font-family: Helvetica Bold;
        font-size: 16px;        
      }
      .moment-summary {
        color: $regular-color;
        width: 100%;
        height: 65px;
        position: relative;

        &:last-of-type {
          height: 60px!important;
        }

        .summary-top {
          width: 100%;
          position: absolute;
          .summary-label {            
            float: left;
            padding-left: 35px;
            text-transform: capitalize;
          }
          .summary-value {
            font-family: Helvetica Bold;
            float: right;
          }
        }
        .summary-bottom {
          position: absolute;
          width: 100%;
          top: 16px;
          .summary-icon {
            position: absolute;
            left: 0;
            width: 25px;
            height: 25px;
          }
          .summary-bar {
            position: absolute;
            left: 35px;
            width: calc(100% - 34px);
            top: -3px;
            am-gauge-chart {
              .indicator-bar {
                background-color: $regular-color; 
              }
              .bar {
                visibility: hidden;
              }
            }
          }
        }

      }
    }    

    #user-history {
        height: 100%;
        position: relative;
        margin: 15px 5px 0 3px;
        border: 1px solid black;

        [id^="moment-"] {
          position: absolute;
        }

        #user-history-container {
          position: relative;
          float: left;
          width: calc((100% - #{$date-bar-width}) * 2 / 3);
        }

        .ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .history-wrapper {
          color: $regular-color;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          background-color: $widget-light;

          .date-history-wrapper {
            position: relative;
            padding-top: 10px;
          }
          .timeline-line {
            position: absolute;
            width: 1px;
            background-color: #5a5e62;
            margin-left: 149px;
            margin-top: 23px;
          }
          .date-text {
            color: $background-color;
            padding: 4px 8px;
            margin-bottom: 20px;
            margin-left: 108px;
            display: inline-block;
            background-color: $regular-color;
            border-radius: 10px;
            font: 12px Helvetica Bold;
          }
          .hourly-history-wrapper > div, .hourly-history-wrapper > a{
            margin-bottom: 20px;
            margin-left: 108px;
            position: relative;
          }
          .hourly-history-wrapper {
            .history-hour {
              float: left;
              padding-left: 14px;
              font-size: 16px;
              line-height: 30px;
              margin-left: 25px;
              padding-top: 3px;
              font: 14px Helvetica bold;
            }
          }


          .icon-wrapper {
            width: 25px;
            height: 25px;
            position: relative;
          }
          .history-info {
            font-size: 14px;
            display: inline-block;
          }
          .activity-text {
            font-weight: bold;
            width: 122px;
            margin-left: 26px;
            vertical-align: middle;
          }
          .dash-space {
            margin-right: 5px;
            margin-left: 5px;
            width: 15px;
            border-top: solid 1px #5a5e62;
            vertical-align: middle;
          }
          .history-url {
            color: $click-color;
            max-width: calc(100% - 225px);
            vertical-align: text-bottom;
            padding-left: 25px;
            &:hover {
              color: white;
            }

          }
          .url {
            display: inline;
            .first {
              margin-right: -1px;
            }
          }

          .highlighted-url {
            color: $url-higlighted-color;
            &.selected-moment {

              .url {
                border: 1px solid;
                margin-left: -33px;
                padding-left: 32px;
                padding-bottom: 9px;
                padding-top: 7px;
                padding-right: 8px;
                border-radius: 16px;
                background: #32373b;
              }
                            
            }
          }

          .fav-icon-wrapper {
            vertical-align: middle;
            position: absolute;
            top: 3px; 
            display: inline-block;

            fav-icon-displayer {
              margin-right: 5px;
            }
          }
        }
        .timeline-wrapper {
            float: left;
            overflow: hidden;
            height: 100%;
            padding-top: 10px;
            width: $date-bar-width;
            border-right: 2px solid $date-history-seperator;
            background-color: $widget-dark;
            &:hover {
              overflow-y: auto;
            }
            .history-date {
                height: 35px;
                margin-bottom: 15px;
                padding-left: 20px;
                line-height: 35px;
                width: $date-bar-width;
                cursor: pointer;
                font-family: Helvetica Bold;
                &:hover {
                  color: white;
                }
            }

            .history-date.no-data {
              color: $color-disabled;
              cursor: auto;
            }

        }
        .highlight-color{
          color: #fff;
          background-color: #151719
        }
    }
  }
}

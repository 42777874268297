@import "./alert-dialog/alert-dialog";
@import "./alerts-settings/alerts-settings";
@import "./alert-consumption-graph/alert-consumption-graph";

$non-active-color: #d05c5a;
$active-color: #55d089;
$title-color: #ffffff;
$text-color: #81878c;
$warning: #f6f765;
$hover-text-color: #cacaca;
$table-bg-color: rgba(32, 36, 39, 0.7);
$table-bg-second-color: rgba(27, 31, 33, 0.7);
$disabled-color: #444;

.alerts-container {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;

  .title-header {
    margin: 0 15px 20px;
    height: 30px;

    .alerts-title {
      display: inline-block;
      font: 16px Helvetica bold;
      line-height: 30px;
      color: $title-color;
    }

    .alerts-buttons {
      margin: 7px 0;
      float: right;

      .general-settings {
        color: $text-color;
        margin-right: 15px;
        cursor: pointer;

        &:hover {
          color: $hover-text-color;
        }
      }

      .create-alert-btn {
        font-size: 14px;
        padding: 7px 11px;
      }
    }
  }

  #alerts-separator {
    background: url(/images/pages/alerts/alerts-seprator.png);
    height: 4px;
    z-index: 10;
    width: 100%;
    position: absolute;
  }

  .content-drivers-pane {
    margin-left: 1px;
  }

  #alerts-content {
    height: calc(100% - 50px);
    overflow-y: scroll;
    margin-left: 15px;
    padding-bottom: 15px;
    padding-right: 2px;

    .alert-wrapper {
      border: 1px solid black;
      color: $text-color;
      background-color: $table-bg-color;
      margin-top: 20px;

      // on/off btn
      .Switch { margin-right: 0;}

      &.disabled-alert {
        color: $disabled-color;
        .icon-bell {
          color: $disabled-color !important;
          animation: none !important;
          -webkit-animation: none !important;
        }
        .alert-title {
          cursor: default ;
          color: $disabled-color;

          .title-text { color: $disabled-color;}

          .alert-actions {
            i.icon-edit_icon {
              &:hover {
                color: $disabled-color;
              }
            }

            i.icon-Trash, i.icon-Export{
              cursor: pointer;
              color: $text-color;
              &:hover { color: $title-color; }
            }
          }
        }
      }

      &.first-alert {
        margin-top: 0;
      }

      .icon-bell {
        font-size: 20px;
        margin: 6px 15px 0 5px;
        color: $non-active-color;

        &.alert-active {
          -webkit-animation: colorchange 800ms infinite;
          animation: colorchange 800ms infinite;
          color: $active-color;
        }
      }

      .warning-icon {
        color: $warning;
        margin: 0 15px 0 5px;
      }


      i.icon-edit_icon.empty-alert {
        margin-right: 5px !important;
      }
      .empty-alert.select-seed-text {
        margin-right: 20px;
      }

      .alert-title {
        cursor: pointer;
        padding: 8px 15px;
        min-height: 44px;
        
        .title-text {
          display: inline-block;
          width: 200px;
          font: 16px Helvetica bold;
          color: $title-color;
          text-overflow: ellipsis;
          padding-right: 18px;
          top: 1px;
          position: relative;
        }

        .title-hidden{
          overflow: hidden;
          top: 5px;
          position: relative;
        }

        .empty-alert, .title-text {
          color: $text-color;
        }

        .alert-type-text {
          display: inline-block;
          width: 200px;
        }

        .alert-actions {
          float: right;
          margin-top: 2px;

          a {
            i.icon-Export {
              margin-right: 0px;
              padding: 4px;
            }
          }

          .table-collapse {
            display: inline-block;
            padding: 0 5px;

            i {
              margin-right: 0;
              font-size: 10px;
            }
          }

          i {
            margin-right: 20px;
            font-size: 16px;

            &:hover {
              color: $title-color;
            }
          }
        }
      }

      .interests-table {
        table {
          width: 100%;
          text-align: left;
          color: $hover-text-color;

          thead {
            border-bottom: 2px solid #000;
            border-top: 1px solid #000;

            tr {
              height: 40px;
              background: $table-bg-second-color;

              th {
                font: 14px Helvetica;

                .mid-dot {
                  font-size: 40px;
                  font-weight: bold;
                  display: inline-block;
                  vertical-align: middle;
                  margin-top: -7px;
                }
              }
            }
          }

          tbody {
            tr {
              height: 80px;
              cursor: default;
              -webkit-user-select: none;

              &:hover {
                td.interest-name-col {
                  font-weight: bold;
                  color: $title-color;

                  .graph-buttons {
                    display: inline;
                  }

                  .graph-buttons-loader {
                    margin-left: 15px;
                    display: inline-block;
                    vertical-align: sub;
                    width: 60px;
                    height: 15px;
                    cursor: default;
                  }
                }
              }

              &:nth-child(odd) {
                background: $table-bg-second-color;

                &:hover {
                  background: rgba(27, 31, 33, 1);
                }
              }

              &:nth-child(even) {
                background: $table-bg-color;

                &:hover {
                  background: rgba(32, 36, 39, 1);
                }
              }

              td {
                &.bell-col {
                  width: 55px;
                }

                &.interest-name-col {
                  width: 280px;
                  color: $text-color;

                  span {
                    line-height: 20px;
                  }

                  @media (max-width: 1500px) {
                    width: 250px;
                  }

                  @media (max-width: 1400px) {
                    width: 220px;
                  }

                  .graph-buttons {
                    display: none;
                    margin-right: 15px;
                    font-size: 13px;
                    line-height: 20px;
                    color: $text-color;

                    .icon-insights, .icon-Content_drivers {
                      cursor: pointer;

                      &:hover {
                        color: $title-color;
                      }
                    }

                    .icon-Content_drivers {
                      vertical-align: bottom;
                    }

                    .icon-insights {
                      vertical-align: inherit;
                      font-size: 16px;
                      margin-left: 15px;
                      margin-right: 10px;
                    }
                  }
                }

                &.change-value-col {
                  width: 350px;

                  @media (max-width: 1700px) {
                    width: 300px;
                  }

                  @media (max-width: 1600px) {
                    width: 280px;
                  }

                  @media (max-width: 1500px) {
                    width: 260px;
                  }

                  @media (max-width: 1400px) {
                    width: 240px;
                  }

                  .fallback-value {
                    display: inline-block;
                    width: 80px;
                  }

                  .percentage-value {
                    display: inline-block;
                    width: 100%;
                    color: $non-active-color;

                    &.alert-active {
                      color: $active-color;
                    }
                  }
                }

                &.sparkline-col {
                  position: relative;

                  .alert-interest-spinner {
                    display: inline-block;
                    width: 180px;
                  }

                  .avg-line {
                    top: 40px;
                    border-top: 1px dashed #404142;
                    width: 200px;
                    position: absolute;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

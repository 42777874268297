@import "./drilldown-component/drilldown";

@mixin overlay() {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.65;
  z-index: 1;
}

.loader-overlay {
  @include overlay();
}

.loader {
  background: url("/images/widgets/Spinner.gif") center center no-repeat;
  background-size: 100%;
  position: absolute;
  width: 100px;
  height: 0;
  padding-bottom: 10%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.widgets-container .title {
  @extend .base_bold_font;
  padding: 15px 0 20px 15px;
  height: 10px;
  color: white;
  font-size: 16px;
  cursor: default;
}

#alert-consumption-overlay {
  #alert-consumption-modal {
    width: auto;
    margin-top: 8vh;
    background-color: #202427;

    .gray-line {
      background-color: gray;
      stroke: gray;
    }
    .dashed {
      stroke-dasharray: 10, 4;
    }
    .active {
      background-color: #55d089;
    }
    .term-A {
      background-color: #98e1ee;
      stroke: #98e1ee;
    }

    .legend-right {
      height: 25px;

      .legend-trends {
        display: flex;
        float: right;

        .legend-trend {
          display: flex;

          .legend-icon {
            margin: 7px 0 3px 10px;
            width: 10px;
            border-radius: 5px;
          }

          .legend-icon-hr {
            width: $control-trend-height;
            border-bottom: 2px solid;
            margin-top: 10px;
            margin-left: 10px;

            &.term-A {
              border-bottom-color: #98e1ee;
            }

            &.gray-line {
              border-bottom-color: gray;

              &.dashed {
                border-bottom-style: dashed;
                border-bottom-color: #202427;
                width: 35px;
              }
            }
          }

          .legend-title {
            margin: 5px 8px 0 5px;
            font-family: Helvetica, sans-serif;
            line-height: $control-trend-height;
          }
        }
      }
    }
    #alert-consumption-graph {
      height: 700px;
      width: 1200px;
      display: block;

      @media (max-width: 1500px) {
        width: 1100px;
      }

      @media (max-width: 1400px) {
        width: 1050px;
      }

      @media (max-width: 1300px) {
        width: 1000px;
      }

      @media (max-width: 1200px) {
        width: 900px;
      }

      @media (max-width: 1100px) {
        width: 800px;
      }

      @media (max-height: 950px) {
        height: 600px;
      }

      @media (max-height: 900px) {
        height: 550px;
      }

      @media (max-height: 850px) {
        height: 500px;
      }

      @media (max-height: 800px) {
        height: 450px;
      }

      @media (max-height: 750px) {
        height: 400px;
      }

      circle {
        r: 3;

        &.term-A {
          fill: #98e1ee;
        }

        &.gray-line {
          fill: gray;
        }
      }

      .trend-chart-circle {
        r: 5;
        cursor: default;

        &.term-A {
          stroke-width: 0;
          fill: #55d089;
        }
      }
    }
  }
}

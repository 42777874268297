.users-settings {
  .settings-container {

    table {
      td.actions {
        text-align: center;
        width: 58px;
        padding: 0;
      }

      td.checkbox, th.checkbox {
        text-align: center;
        width: 22px;
        padding: 0;

        .checked {
          font-size: 15px;
        }

        .unchecked {
          font-size: 13px;
        }
      }

      i.icon-edit_icon {
        font-size: 12px;
      }

      i.icon-Trash {
        font-size: 12px;
      }
    }
  }
}

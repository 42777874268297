@import "../../../infra/style/base";

$image-size: 70px;

.content-driver:not(.highlighted):not(.selected){
  .content-driver-circle {
    fill: #202427;
  }
}

.content-driver.sentiment:not(.highlighted):not(.selected){
  .content-driver-text {
    fill: #FFFFFF;
  }

  .content-driver-circle {
    stroke: #FFFFFF;
    fill: #202427;
  }
}

.content-driver.sentiment.highlighted, .content-driver.sentiment.selected {
  .content-driver-circle {
    stroke: #FFFFFF;
    fill: #FFFFFF;
    cursor: pointer;
  }
}

.content-driver.highlighted, .content-driver.selected {
  .content-driver-text {
    fill: #202427;
    cursor: pointer;
  }

  .content-driver-circle {
    cursor: pointer;
  }
}

.content-driver:not(.selected),
.content-drivers-pane:not(.open) .content-driver.selected,
.content-drivers-pane:not(.open) .content-driver.highlighted{
  border-left-color: #202427;
}

.content-driver.sentiment.selected {
  border-left-color: #FFFFFF;
}

.content-driver.hide {
  display: none;
}

.content-driver {
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left-width: 3px;
  border-left-style: solid;
  clear: both;
  margin-top: .7em;
  margin-bottom: .7em;
  padding-left: .5em;
  color: #cacaca;
  font-size: 12px;
  min-height: 85px;
  &:not(:last-child) {
    border-bottom: 1px solid #151719;
  }

  .text {
    overflow: hidden;
  }

  .rt {
    float: right;
    margin-right: 5px;
  }

  .content-driver-text {
    font-size: 12px;
    font-weight: bold;
    text-anchor: middle;
  }

  .content-driver-circle {
    r: 9;
    cx: 10;
    cy: 10;
    stroke-width: 2;
  }

  svg {
    height: 20px;
    width: 20px;
    position: relative;
    margin-bottom: -14px;
  }

  img {
    margin-left: 0.5em;
    margin-right: 0.7em;
    border-radius: 4px;
    height: $image-size;
    width: $image-size;
    float: left;
  }

  .time {
    color: #6b707d;
    font-size: 13px;
  }

  .provider {
    font-size: 12px;
    color: #81878c;
  }

  a:hover {
    .provider {
      color: #dddee1;
    }
  }

  .content-text-span {
    color: #cacaca;
    display: inline-block;
    max-height: 56px;
    overflow: hidden;
  }

  .content-text-span.sentiment {
    max-height: 28px;
  }

  .sentiment-content {
    margin-top: 0.5em;

    .trend-label {
      color: #cacaca;
      background-color: #525c64;
      border-radius: 2px;
      padding: 2px 5px;
      font-size: 12px;
      float: left;
    }

    .confidence-label {
      padding: 2px 5px;
      font-size: 12px;
      float: left;
    }

    .sentiment-icon {
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: 100% 100%;
      width: 15px;
      height: 14px;
      float: right;
    }

    .sentiment-icon.pos{
      -webkit-mask-image:  url(/images/icons/thumbup.svg);
      background-color: $positive-color;
    }

    .sentiment-icon.neg{
      -webkit-mask-image:  url(/images/icons/thumbdown.svg);
      background-color: $negative-color;
    }
  }
}

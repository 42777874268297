@import "/bower_components/normalize.css/normalize.css";
@import "infra/style/base";
@import "common/program-selector.drv/program-selector.drv";
@import "infra/topbar/topbar";
@import "infra/menu/menu";
@import "common/excelBuilder/excel.srv.scss";
@import "common/input-bar.drv/input-bar";

//icons
@import "fonts/icon-mmon";
@import "fonts/Helvetica";
//
//pages
@import "./pages/dashboard/dashboard";
@import "./pages/discovery/discovery";
@import "./pages/insights/insights";
@import "./pages/settings/settings";
@import "./pages/settings/settings-table";
@import "./pages/warroom/warroom";
@import "./pages/audience-builder/audience-builder";
@import "./pages/audience-explorer/audience-explorer";
@import "./pages/login/login";
@import "./pages/alerts/alerts";

//directive
@import "common/content-drivers-pane/content-drivers-pane.drv";

//bower component

//am-collections
@import "common/am-input-collection/am-input-collection";
@import "common/am-toggle-button/am-toggle-button";
@import "common/am-image-toggle-button/am-image-toggle-button";
@import "common/am-container-collection/am-container-collection";
@import "common/sly-scrollbar.drv/scroll-bar";
@import "common/datePicker.drv/datePicker.drv";
@import "common/empty-screen-message.drv/emptyScreenMessage";
@import "common/modals/contact_us/contact_us";
@import "common/am-responsive-bar-chart.drv/am-responsive-bar-chart.drv";

@import "infra/notifications_deck/notification_deck";
@import "infra/authentication/new-version";

//general elements
@import "common/style/transitions";
@import "common/am-table-grid/am-table";
@import "common/am-autocomplete.drv/am-autocomplete.drv";
@import "common/quick-table/quick-table";
@import "common/quick-tree/quick-tree";
@import "common/am-nav-bar/am-nav-bar";
@import "common/am-consumption-legend.drv/am-consumption-legend.drv";
@import "common/am-dropdown-select/am-dropdown-select";
@import "common/am-linked-dropdown/am-linked-dropdown";
@import "common/am-heatmap-chart/heatmap-chart";
@import "common/am-image-toggle-button/am-image-toggle-button";
@import "common/audience-filter/audience-filter";
@import "common/charts/scatter-chart";
@import "infra/style/filters-menu";
@import "pages/alerts/components/components";

html {
  height: 100%;
}

body {
  background-image: $application-background;

  color: $text-color;
  height: calc(100% - #{$topbar-height} - 5px);
  @include base_font();

  #content-view {
    margin-top: calc(3em + 5px);
    margin-left: $menu-closed-width;
    height: 100%;
    width: calc(100% - #{$menu-closed-width});
    display: block;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.75);
    border-right: 0;
    border-bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  menubar, topbar {
    transition: all $animation-time linear;
    font-size: 16px;
  }
  menubar.open + topbar {
    width: calc(100% - #{$menu-open-width});
    left: $menu-open-width;
  }
  menubar.open + topbar + #content-view {
    margin-left: $menu-open-width;
    width: calc(100% - #{$menu-open-width});
  }
  .icon-Triangle_Down, .icon-Triangle_Right, .icon-Triangle_Up {
    font-size: 9px;
  }
}

/* default custom scrollbar */
/* Let's get this party started */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-color: rgba(66, 66, 66, .90);
  background-clip: padding-box;
  border-radius: 18px;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(90, 90, 90, .90);
}

/*icons who need adjustments*/
.icon-Advanced {
  font-size: 0.6em !important;
  margin-top: 1px !important;
}

.icon-minimize {font-size: 12px;}

.icon-refresh_new {font-size: 11px;}

.icon-Retweet_new {
  font-size: 16px;
  line-height: 12px;
}

.icon-X_CLEAR {font-size: 9px;}

.icon-circle-o {margin-right: 0.91px;}
